import React, { useReducer, useEffect, useRef } from "react";
import ReactDom from 'react-dom'
import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Markdown from 'react-markdown'
import remarkGfm from "remark-gfm";

const CompHelpGuide = (props) => {



    const markdownContent = [
        `# Overview - Key Concepts
 
Understanding these basic concepts will allow you to get creating quickly.  At the heart of Brevit3, Stories are the basis for the experiences you can share with your stakeholders and just like your favorite film, Stories are made up of Scenes.  After reviewing this brief overview, browse each component to understand how it can help enable your storytelling.

![help-med](/util/app/b3-story-diagram-full.svg)

## Scenes
Scenes anchor each part of your story, helping to covey key moments of your solution's value being applied in specific situations.  An example may be someone browsing a website, seeing an advertisement and clicking through to start a purchase of a product.  It could also be a service technician arriving onsite to resolve an issue with the product that someone purchased.

## Scene Structure
Scenes also help to frame your story and we suggest using them to not only tell the story, but help to frame what you are about to share with your audience.

1. Introduction welcoming viewers
2. Problem statement
3. Overview of the value your solution will provide
4. Stakeholders involved in story (think of this as introducing your actors)
5. Scenes that tell story (repeat as needed, using a scene for each key moment)
6. Stakeholder Summary of Value
7. Account Team and Next Steps
`,
        `# Stories

The Recent Stories page will provide access to all of the Stories that you have created and provides the opportunity to update, copy of delete them.

Each Story is able to be secured only for your viewing or made public with a shareable link that only you 

![help-med](/util/help/b3-recent-stories.png)`,
        `# Scenes  

This is likely where you will spend most of your time in Brevit3.  The **Story Builder** interface allows you to add, edit and delete Scenes.

The left-hand panel is where you organize scenes to tell your story, drag and drop them to reorder them as needed.  Tapping on the **Play** button within each to active it and make it available for editing.

![help-med](/util/help/b3-story-builder.png)

The right-hand side allows you to add and edit Components for the Scene that you have selected.  Each element on the screen in the example below has a **Component** that represents it and can be edited - changing position, title and more depending on what type of component you have added.

![help-med](/util/help/b3-help-detail-panel.png)`,
        `# Super Cards

![help-icon](/media/icon/noun-membership-2397537-FFFFFF.svg)

Covered in video walkthrough - more content here soon`,
        `# Person

![help-icon](/media/icon/noun-person-5763652-FFFFFF.svg)

Covered in video walkthrough - more content here soon`
        ,
        `# Gallery

![help-icon](/media/icon/noun-devices-51939-FFFFFF.svg)

Covered in video walkthrough - more content here soon`
        ,
        `# 3D Item

![help-icon](/media/icon/noun-cube-1830912-FFFFFF.svg)

Covered in video walkthrough - more content here soon`
        ,
        `# Image

![help-icon](/media/icon/noun-image-2761478-FFFFFF.svg)

If you have existing visual assets created in Figma, Photoshop and other design tools, they can be uploaded and used within your scenes to add an extra level of detail and customization for your experience.

Covered in video walkthrough - more content here soon`
        ,
        `# Link

![help-icon](/media/icon/noun-link-162420-FFFFFF.svg)

Updates will include the ability to use custom icons and colors.

Covered in video walkthrough - more content here soon`
        ,
        `# Web

![help-icon](/media/icon/noun-web-5827943-FFFFFF.svg)

More options for this component are under development, but it provides a basic way to bring in some content from the web.  This could be a video (using the link within a YouTube embed for example) or web page.  Please note that this is limited based on potential security restrictions that a given web app or page may have.

Covered in video walkthrough - more content here soon`
        ,
        `# Text

![help-icon](/media/icon/noun-text-5443090-FFFFFF.svg)

Can be useful at the open and close of experiences.  It offers basic functionality at the moment, but more style options available in the near future.

Covered in video walkthrough - more content here soon`
        ,
        `# CTA

![help-icon](/media/icon/noun-notification-3835779-FFFFFF.svg)

Offers a tray that slides out for a given scene and can contain supporting information like links to case studies, webinars that viewers might want to sign up for and more.  Think of it as a way to provide additional information that is not part of the core story, but may be helpful to viewers.

Covered in video walkthrough - more content here soon`
        ,
        `# Icon

![help-icon](/media/icon/noun-up-arrow-1181859-FFFFFF.svg)

Covered in video walkthrough - more content here soon`,
    ]

    const [chapter, setChapter] = useState(0)

    const SelectChapter = (props) => {
        setChapter(props.id)
    }

    const [open, setOpen] = useState(false)
    //    const [vizPackName, setVizPackName] = useState('')
    //    const [vizPackDescription, setVizPackDescription] = useState('')
    const cancelButtonRef = useRef(null)

    const [activeID, setActiveID] = useState(1)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const userGuide = [
        //noun-book-534697-FFFFFF
        { id: 1, name: 'Concepts', href: '#', initial: 'H', current: false, svgicon: 'noun-book-534697-FFFFFF', },
        { id: 2, name: 'Stories', href: '#', initial: 'H', current: false, svgicon: 'noun-book-534697-FFFFFF', },
        { id: 3, name: 'Scenes', href: '#', style: '30px', initial: 'H', current: false, svgicon: 'b3-icon-scene', },
        { id: 4, name: 'Super Cards', href: '#', initial: 'H', current: false, svgicon: 'noun-membership-2397537-FFFFFF', },
        { id: 5, name: 'Person', href: '#', initial: 'T', current: false, svgicon: 'noun-person-5763652-FFFFFF', },
        { id: 6, name: 'Gallery', href: '#', initial: 'T', current: false, svgicon: 'noun-devices-51939-FFFFFF', },
        { id: 7, name: '3D Item', href: '#', initial: 'W', current: false, svgicon: 'noun-cube-1830912-FFFFFF', },
        { id: 8, name: 'Image', href: '#', initial: 'W', current: false, svgicon: 'noun-image-2761478-FFFFFF', },
        { id: 9, name: 'Link', href: '#', initial: 'W', current: false, svgicon: 'noun-link-162420-FFFFFF', },
        { id: 10, name: 'Web', href: '#', initial: 'W', current: false, svgicon: 'noun-web-5827943-FFFFFF', },
        { id: 11, name: 'Text', href: '#', initial: 'W', current: false, svgicon: 'noun-text-5443090-FFFFFF', },
        { id: 12, name: 'CTA', href: '#', initial: 'W', current: false, svgicon: 'noun-notification-3835779-FFFFFF', },
        { id: 13, name: 'Icon', href: '#', initial: 'W', current: false, svgicon: 'noun-up-arrow-1181859-FFFFFF', },
    ]

    return (
        <>
            {!props.linkText ?
                <div type="button" className="text-gray-200 admin-component opacity-60 hover:opacity-100 rounded text-sm text-left inline-flex items-center  " onClick={() => { setOpen(true) }}>
                    <img className="w-5 h-5 mr-1" src='/util/app/b3-help.svg'


                    />
                </div>
                :
                <div type="button" className="text-sky-500 hover:text-sky-400 inline-flex items-center  " onClick={() => { setOpen(true) }}>
                    {props.linkText}
                </div>
            }

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50  transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg   bg-zinc-900  px-4 pb-4   text-left shadow-xl transition-all sm:my-8 w-10/12 sm:p-6">
                                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                                        <button
                                            type="button"
                                            className="rounded-md   focus:outline-none  "
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                                        </button>
                                    </div>
                                    <div className="grid grid-cols-6 gap-4" >
                                        <div className="col-span-1">

                                            <div className="text-lg font-base leading-6 text-gray-300 mt-1 mb-4">Topics</div>
                                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                {userGuide.map((team, index) => (
                                                    <li key={team.name} style={{ marginBottom: team.style }}>
                                                        <div
                                                            onClick={() => setActiveID(team.id)}
                                                            //onClick={() => setChapter(team.id)}
                                                            // onClick=({() => SelectChapter(team)})
                                                            //href={team.href}
                                                            className={classNames(
                                                                activeID - 1 == index
                                                                    ? 'bg-white/5 text-white'
                                                                    : 'text-gray-400 hover:text-white hover:bg-white/5',
                                                                'group cursor-pointer flex gap-x-3 px-4 rounded-md p-2 text-sm leading-6 font-normal'
                                                            )}
                                                        >
                                                            <span className="flex h-6 w-6 shrink-0 items-center justify-center  text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                                                                <img src={'/media/icon/' + team.svgicon + '.svg'} className="w-6 h-6 mr-2" />
                                                            </span>
                                                            <span className="truncate">{team.name}</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="col-span-5">
                                            {/* <h1 className="text-2xl text-white">Help Guide</h1> */}
                                            <div className="h-[80vh] overflow-y-scroll admin-scroll mt-12">
                                                <div className="ml-10 text-gray-300 prose max-w-full pr-20">

                                                    { activeID -  1 == 0 ?
                                                        <iframe className="w-full aspect-video mb-20 rounded-md border-black/20"   src="https://www.youtube.com/embed/hcTaRMD2obc?si=Ssg3TDbu7qwceyy5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                                        : null
                                                    }

                                                    <Markdown className={''}>{markdownContent[activeID - 1]}</Markdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
};

export default CompHelpGuide