import React from 'react'
import { CompHSChat } from '../components/CompHSChat'
//import { UserAuth } from '../context/AuthContext'
import FormEAPSupport from './FormEAPSupport'
import CompEAPSupport from './CompEAPSupport'
import { UserAuth } from '../context/AuthContext'

import CompHelpGuide from '../components/CompHelpGuide'
import ModelsList from '../components/ModelList'

const Admin = () => {
 

  const { user, logOut } = UserAuth()

  return (
    <>

{/* <iframe width="100%" height="800px" src="https://www.brevit3.com/play/CIhpEYRobbswDM2HMJTs" title="FAST Innovation" frameborder="0" allow="web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

       
        <ModelsList />

    </>
  )
}

export default Admin