import React, { useEffect } from "react";

const FormContact = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '40148401',
                    region: "na1",
                    formId: '27143351-cf62-4bd3-bda4-b6d53eca57a0',
                    target: '#ContactForm'
                })
            }
        });
    }, []);

    return (
        <div>
            <div id="ContactForm"></div>
        </div>
    );

}

export default FormContact;