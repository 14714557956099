function degrees_to_radians(degrees)
{
  var pi = Math.PI;
  return degrees * (pi/180);
}

export function GetContentPosition(storyData, sceneData, sceneIndex, xAdjust = 0, yAdjust = 0, zAdjust = 0) {

    const horizontalSpace = 40
    const verticalSpace = 50

    if (storyData.layoutType) {
        const expr = storyData.layoutType


        // console.log('PLATFORM POSITION....')
        // console.log(sceneData[sceneIndex].platformPosition)


        if (sceneData[sceneIndex].platformPosition == 'manual') {
            //return [sceneData[sceneIndex].pos.x, sceneData[sceneIndex].pos.y, sceneData[sceneIndex].pos.z]
            //console.log('*** PLATFORM IS MANUAL ***')
            return [sceneData[sceneIndex].pos.x - xAdjust, sceneData[sceneIndex].pos.y + yAdjust, sceneData[sceneIndex].pos.z - zAdjust]
        }

        // if (sceneData[sceneIndex].layout == 'manual'  || sceneData[sceneIndex].layout == true) {
        //     //return [sceneData[sceneIndex].pos.x, sceneData[sceneIndex].pos.y, sceneData[sceneIndex].pos.z]
        //     console.log('SCENE IS MANUAL... Recalculating position...')
        //     return [sceneData[sceneIndex].pos.x, yAdjust, sceneData[sceneIndex].pos.z]
        // }

        // Check current scenes for manual layout
        let arrScenes = sceneData.filter(sceneData => sceneData.layout != 'manual')

        let scenesManualCount = 0

        switch (expr) {
            case 'linear':
                return [(sceneIndex * horizontalSpace) - 50 - xAdjust, 0 + yAdjust, 0 - zAdjust]

            case 'vertical':
                
                return [0 - xAdjust, (sceneIndex * verticalSpace) + yAdjust, 0 - zAdjust]

            case 'circle':

                // console.log('Scene Nodes!.....')
                // console.log(arrScenes)
                // console.log(Object.keys(arrScenes))

                // TODO
                // The number to subtract is the manual layout count * 2
                // so if 3 are manual, subtract 6

                let n = (Object.keys(sceneData).length * 2) - 8 //+ 2 // (number of hidden)   - scenesManualCount
                let maxRadius = storyData.layoutCircleDistance
                let myX = 0
                let myZ = zAdjust
                let myY = yAdjust //0
                let y = yAdjust
                let sceneNodes = []

                for (let i = 0; i < n; i++) {
                    let angle = i * (2 * Math.PI / n) + degrees_to_radians(-54);
                    //console.log('Angle: ' + angle)
                    let x = (maxRadius) * Math.cos(angle) * -1;
                    let z = (maxRadius) * Math.sin(angle);
                    if (sceneIndex == i) {
                        //myX = x + xAdjust
                        myX = x - xAdjust
                        myZ = z - zAdjust
                        myY = yAdjust //y
                        sceneNodes.push([myX, myY, myZ])
                    }
                    else {
                        sceneNodes.push([x, myY, myZ])
                    }
                }

                return [myX, myY, myZ]
        }
    }
}