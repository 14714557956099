import React, { useState, useContext, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { db } from '../firebase'
import { getFunctions, httpsCallable } from "firebase/functions";

import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
} from 'firebase/firestore'

import { v4 as uuidv4 } from 'uuid'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Slider from 'react-input-slider'
//import { backgroundImage } from 'html2canvas/dist/types/css/property-descriptors/background-image';

const MediaUploaderSolo = (props) => {

    const [files, setFiles] = useState([]);
    const [loadingItems, setLoadingItems] = useState([])
    const [isloading, setIsLoading] = useState(false)

    const handleFileDrop = (e) => {
        e.preventDefault();
        const uploadedFiles = Array.from(e.dataTransfer.files);
        setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        uploadedFiles.forEach(handleFileUpload);
    };

    const doUploadImage = async (dataUrl) => {
        // Get Cloud function named Upload
        const functions = getFunctions();

        var upload = httpsCallable(functions, 'upload'); //firebase.functions().httpsCallable('upload');
        const data = {
            dataUrl: dataUrl
        }
        // Upload Image to Cloudinary
        return upload(JSON.stringify(data))
            .then(response => {
                const data = JSON.parse(response.data)
                // Return the Cloudinary image link
                // return data.secure_url ? data.secure_url : ""
                return data ? data : ""
            })
            .catch((error) => {
                // Return any error message
                return error
            })
    }


    const options = {
        maxSizeMB: 500
    }

    // Hold loading visuals for any added items  
    let loadingItemsArray = []

    const handleFileUpload = async (file) => {
        setIsLoading(true)

        const data = new FormData()
        data.append("file", file)
        data.append(
            "upload_preset",
            'qblr702c'
        )
        data.append("cloud_name", 'djdw2s79u')
        data.append("folder", "Cloudinary-React")

        try {
            const response = await fetch(
                `https://api.cloudinary.com/v1_1/djdw2s79u/auto/upload`,
                {
                    method: "POST",
                    body: data,
                }
            );
            const res = await response.json();

            // Remove loading bar...
            setIsLoading(false)

            console.log('UPLOAD COMPLETE.... URL AS FOLLOWS...')
            console.log(props.itemName)
            console.log(props.dataAttribute)
            console.log(res.secure_url)

            //   let activeSceneId = sceneData[sessionData.activeIndex].id
            const storyRef = doc(db, 'stories', props.storyId)

            let cloneStory = []
            cloneStory = props.storyData

            // TypeError: props.storyData is not iterable

            cloneStory[props.dataAttribute] = res.secure_url

            // props.dataAttribute

            updateDoc(storyRef,
                cloneStory
            )

        } catch (error) {
            alert(error)
            console.log(error)
        }
    }

    const MediaUploadDialogue = () => {

        return (
            <div
                style={{
                    border: '1px dashed #2289a6', borderRadius: '.25rem',
                    padding: '.5rem', color: '#efefef', textAlign: 'center', background: 'rgb(22 24 29)', width: '98%'
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleFileDrop}
                className='mb-4 h-24 cursor-pointer'
                onClick={() => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.accept = 'image/*';
                    input.multiple = true;
                    input.addEventListener('change', (e) => {
                      const uploadedFiles = Array.from(e.target.files);
                      setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
                      uploadedFiles.forEach(handleFileUpload);
                    });
                    input.click();
                  }}                
            >
                {!isloading ? (
                    <div className="grid grid-cols-5 gap-4">
                        <div className='mt-2 mb-2 text-sm col-span-3 mt-4 text-gray-300'>
                            {props.itemName}<br />
                            select png, svg or jpg<br />

                        </div>
                        <div>

                            <div className='w-20 h-20 col-span-2'
                                style={{
                                    backgroundImage: 'url(' + props.storyData[props.dataAttribute] + ')',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                }}
                            >
                                </div>
                            {/* <img src={props.storyData[props.dataAttribute]} className='h-16 inline' /> */}

                        </div>
                    </div>
                ) : (

                    <div className='media-record animate__animated animate__fadeIn progress progress-striped active h12 mb-2' key={uuidv4()}>
                        <div role="progressbar progress-striped" className="progress-bar w-100p h-100p"></div>
                    </div>
                )}

            </div>
        )
    }



    return (
        <div>

            <MediaUploadDialogue />



            {/* {renderMediaList()} */}
            {/* <RenderMediaList key={sceneData.guid}></RenderMediaList> */}

        </div>
    );
};

export default MediaUploaderSolo;