import React, { useState, useEffect, useRef } from 'react';
import 'animate.css'

const PublicSiteSlider = () => {
    const [active, setActive] = useState(0);
    const [progress, setProgress] = useState(0);
    const requestRef = useRef();
    const firstFrameTimeRef = useRef();
    const itemsRef = useRef();

    const items = [
        // {
        //     img: 'ps-image-01.png',
        //     desc: 'Reuse Content',
        //     buttonIcon: '/images/b3-step-1.svg',
        // },
        {
            img: '/images/b3-content-2.svg',
            desc: '1. Reuse & Simplify',
            buttonIcon: '/images/b3-step-1.svg',
        },
        {
            img: 'ps-image-03.png',
            desc: '2. Present & Share',
            buttonIcon: '/images/b3-step-3.svg',
        },
        // {
        //   img: 'ps-image-04.png',
        //   desc: 'Enriched',
        //   buttonIcon: 'ps-icon-04.svg',
        // },
    ];

    const duration = 20000;

    useEffect(() => {
        startAnimation();
        return () => cancelAnimationFrame(requestRef.current);
    }, [active]);

    const startAnimation = () => {
        setProgress(0);
        firstFrameTimeRef.current = performance.now();
        requestRef.current = requestAnimationFrame(animate);
    };

    const animate = (now) => {
        const timeFraction = (now - firstFrameTimeRef.current) / duration;
        if (timeFraction < 1) {
            setProgress(timeFraction * 100);
            requestRef.current = requestAnimationFrame(animate);
        } else {
            setActive((current) => (current + 1) % items.length);
        }
    };

    const heightFix = () => {
        const parent = itemsRef.current.parentElement;
        const child = itemsRef.current.children[active + 1];
        if (parent && child) {
            parent.style.height = `${child.clientHeight}px`;
        }
    };

    useEffect(() => {
        // heightFix();
    }, [active]);

    return (
        <div className="w-full mx-auto text-center  
    
    ">
            <div className="  ">
                <div className="pt-8 rounded-t-lg shadow-lg  shadow-inner   bg-black/20" ref={itemsRef}>
                    {/* <img src="/images/b3-storyselling-platform-2.svg" className='w-full mx-auto z-1 ' /> */}
                    <div className="w-full animate__animated animate__fadeIn "
                        style={{
                            display: active === 0 ? 'block' : 'none',
                            //transition: 'opacity 2s ease-in-out',
                            //opacity: active === 0 ? 1 : 0,
                        }}
                    >
                        <div className='lg:text-3xl text-xl  text-white'>Content that You Already Use</div>
                        {/* <div className='lg:text-xl text-lg  mt-2 text-gray-400'>No Extra Work - just like you would for Slides or a Video</div> */}
                        <img src="/images/b3-how-A.svg" className='mx-auto  mt-8' />
                    </div>

                    <div className="w-full animate__animated animate__fadeIn"
                        style={{
                            display: active === 1 ? 'block' : 'none',
                            //transition: 'opacity 2s ease-in-out',
                            //opacity: active === 1 ? 1 : 0,
                        }}
                    >
                        <div className='lg:text-3xl text-xl text-white'>24 x 7 Omnichannel Engagement</div>
                        {/* <div className='lg:text-xl text-lg mt-2 text-gray-400'>Single platform delivers to multiple channels - no rework needed</div> */}
                        <img src="/images/b3-how-B.svg" className='mx-auto   mt-8' />
                    </div>

                    <div className="w-full transition-all duration-150 delay-300 ease-in-out opacity-0"
                        style={{
                            display: active === 2 ? 'block' : 'none',
                            //transition: 'opacity 2s ease-in-out',
                            opacity: active === 2 ? 1 : 0,
                        }}
                    >
                        Share Anytime, Anywhere
                    </div>
                </div>

                <div className="   mx-auto grid grid-cols-2 lg:gap-0 gap-0">
                    {items.map((item, index) => (
                        <button
                            key={index}
                            className={` 
                            ${index === 0 ? 'rounded-bl-lg' : ''}
                            ${index === 1 ? 'rounded-br-lg' : ''}
                            shadow-md bg-black/40 focus:outline-none   group`}
                            onClick={() => setActive(index)}
                        >
                            <span
                                className="text-center flex flex-col items-center"
                                style={{
                                    opacity: active === index ? 1 : 0.25,
                                    transition: 'opacity 0.3s',
                                }}
                            >
                                <span className="block relative w-full bg-black h-1 " role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                    <span className="absolute inset-0 bg-indigo-400 rounded-[inherit]" style={{ width: `${active === index ? progress : 0}%` }}></span>
                                </span>
                                {/* <span className="flex items-center justify-center relative h-40   bg-indigo-100 mb-2"> */}
                                <img src={item.buttonIcon} alt={item.desc} className='lg:h-10 h-6 my-6 mb-3' />
                                {/* </span> */}
                                <span className="block lg:text-xl mt-2 text-lg font-medium text-gray-50 mb-4">{item.desc}</span>

                            </span>
                        </button>
                    ))}
                </div>
            </div>
            {/* <div class="absolute inset-x-0 -mt-96 z-0  mx-auto h-40 max-w-lg bg-gradient-to-tr from-indigo-400 via-indigo-500 to-indigo-900 blur-[200px]"></div> */}
        </div>
    );
};

export default PublicSiteSlider;