import { React, useState } from 'react'
import '/Style.css'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import FormWaitlist from './FormWaitlist'
import FormContact from './FormContact'
import CompSiteNav from './CompSiteNav'


const PublicContact = () => {

  return (
    <>

      {/* <CompSiteNav /> */}

      <div className="mt-20    py-24 pt-80 sm:py-32">

        <div className="mx-auto max-w-7xl px-6 lg:px-8">

          <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
            <div>
              <p className="text-lg font-semibold leading-8 tracking-tight text-sky-400">
                Contact Us
              </p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl mb-14">
                Drop Us a Line
              </h1>

              <FormContact />
            </div>

            <div className="pl-0 md:pl-40 mt-14">
                <img src="/media/site/B3-Contact-Locations.png" className='mx-auto' />
            </div>
          </div>



        </div>
      </div>




    </>

  )
}

export default PublicContact