import React, { useRef, useState, useContext, forwardRef, useEffect } from "react";
import QRCode from "react-qr-code";
import ReactPlayer from 'react-player'
//import UserContext, { SessionContext, SceneContext } from "../AppStoryPlayer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'

// const [state, updateState] = React.useState();
// const forceUpdate = React.useCallback(() => updateState({}), []);

// function handleClick() {
//     forceUpdate();
// }
//import QRCode from "react-qr-code";

//updateSceneFunction

//const ExpHTMLEcoCard = (currentScene, thisIndex, updateSceneFunction) => {
const ExpHTMLEcoCard = (props) => {

    if (props.currentScene) {

        const [sessionData, setSessionData] = useContext(SessionContext)

        // console.log('FUNCTION...!')
        // console.log(props.updateSceneFunction)

        //if (thisIndex == sessionData.activeIndex) {
        let sceneOrder = props.currentScene.order
        let currentScene = props.currentScene
        let thisIndex = props.thisIndex

        // console.log('*************** OK - in the render for the flat HTML UI... ***************')
        // console.log('indexes...')
        // console.log(sessionData.activeIndex)
        // console.log(currentScene.thisIndex)
        // console.log(sceneOrder)
        // console.log('///////////////')


        const [lastSceneIndex, setLastSceneIndex] = useState(sceneOrder)

        // const [, updateState] = React.useState();
        // const forceUpdate = React.useCallback(() => updateState({}), []);

        // useEffect(() => {
        //     //setLastSceneIndex(currentScene.thisIndex)
        //     //    forceUpdate()

        //     //    const element = document.querySelector('.my-element');
        //     //     element.classList.remove('animate__animated', 'animate__bounceOutLeft');

        // }, [currentScene])

        // useEffect(() => {

        //     if (currentScene.thisIndex != lastSceneIndex) {
        //         console.log('->>> ->>>> NEW SCENE!')
        //         console.log('LSI: ' + lastSceneIndex)
        //         console.log('CSI: ' + currentScene.thisIndex)
        //     }
        // }, [sceneOrder])


        let ecoCardItems = []
        let sceneInfo = props.currentScene

        // console.log('SCENE INFO')
        // console.log(sceneInfo)

        if (sceneInfo != undefined) {
            if (sceneInfo.viznodes != undefined) {
                //console.log('Viznodes are avail')
                for (let i = 0; i < sceneInfo.viznodes.length; i++) {
                    //console.log(sceneInfo.viznodes[i])
                    if (sceneInfo.viznodes[i].id == 9 || sceneInfo.viznodes[i].id == 10) {
                        //console.log(sceneInfo.viznodes[i])
                        ecoCardItems.push(sceneInfo.viznodes[i])
                    }
                }



                let vizItem = ecoCardItems[0]

                if (vizItem) {
                    // console.log('VIZ ITEM')
                    // console.log(vizItem)

                    const imageStyle = {
                        width: vizItem.size.width,
                        height: vizItem.size.height,
                    }

                    const styleOffset = {
                        left: vizItem.size.width,
                    }

                    let animationStyle = ''
                    if (vizItem.animation) {
                        animationStyle = vizItem.animation
                    }


                    const BlockContentTile = (props) => {
                        return (
                            <div class="group block  mx-auto rounded-md px-2 py-1  ring-slate-900/5 transition-all shadow-md space-y-3  bg-gray-700 hover:bg-gray-900  border-sky-500   h-8 hover:h-32  hover:ring-sky-500 cursor-pointer  ">
                                <span class="text-slate-200 xl:text-sm md:text-sm  2xl:text-base group-hover:hidden">
                                    {props.heading}
                                </span>
                                <span class="text-slate-50 xl:text-sm 2xl:text-base  md:text-sm  invisible group-hover:visible">
                                    {props.body}
                                </span>
                            </div>
                        )
                    }



                    const DetailLinks = (props) => {

                        return (

                            <div style={{ height: "auto", margin: "0 auto", width: "100%", background: '#fff', padding: '.5rem', borderRadius: ".5rem" }}
                            // className="shadow"
                            >
                                {/* <h1 className="text-lg mb-4 text-center ">{props.linkName}</h1>
                            <QRCode
                                size={180}
                                style={{ height: "auto", maxWidth: "100%", width: "100%", marginBottom: '2rem' }}
                                value={props.linkURL}
                                viewBox={`0 0 180 180`}
                            /> */}
                                <a href={props.linkURL} target="_blank" className=" w-full  items-center px-8 py-4 bg-gray-700 hover:bg-gray-800 border-l-4 border-sky-500 inline-block shadow transition-all rounded-md text-gray-800 text-left">
                                    <span className="mt-2">Launch {props.linkName}</span> <img src="/media/icon/noun-link-1768396-FFFFFF.svg" className="h-6 fill-current w-6 float-right" />
                                </a>
                            </div>

                        )
                    }



                    const DetailTab = (contentItem, vizParent) => {
                        //console.log(vizItem.content[activeTab].items[activeSubTab].name)
                        const componentStyle = { left: 0 }
                        console.log('ITEM DETAIL')
                        //console.log(vizItem)
                        console.log(contentItem)

                        return (
                            <>
                                {/* <div className="flex h-28 xl:h-10 2xl:h-28 2xl:bg-gray-100">
                                <div className="flex-auto w-64 2xl:p-8 xl:py-4 xl:px-8">
                                    {vizItem.id == 9 ?
                                        <>
                                            <img src={contentItem.vizItem.logoUrl}
                                                className="float-left 2xl:h-14 xl:h-12 pb-2" />
                                        </>
                                        :
                                        <>
                                            <h2 className="2xl:text-4xl xl:text-2xl font-semibold text-gray-800">{contentItem.vizItem.name}</h2>
                                            <img src={contentItem.vizItem.logoUrl}
                                                className="h-64 xl:h-32 2xl:h-64 absolute bottom-0 right-0" />
                                        </>
                                    }
                                </div>
                                <div className="flex-initial w-14 h-14 mr-4">
                                </div>
                            </div> */}

                                <Tabs>
                                    <div class="grid grid-cols-5 gap-0">
                                        <div class="col-span-1 bg-gray-200 h-screen">

                                            <div className="px-6">

                                                {vizItem.id == 9 ?
                                                    <img src={contentItem.vizItem.logoUrl}
                                                        className=" pb-2 mx-auto mt-12 mb-12" />
                                                    :
                                                    <img src={contentItem.vizItem.logoUrl}
                                                        className="2xl:h-40 xl:h-36 mx-auto mt-8 mb-0" />
                                                }

                                            </div>

                                            {vizItem.id == 9 ?
                                                (null)
                                                :
                                                <div className="mb-4 rounded p-4 mx-2.5 text-center bg-white">
                                                    <p className="text-base text-gray-900">{contentItem.vizItem.name}</p>
                                                </div>

                                            }


                                            <TabList>
                                                {contentItem.vizItem.items.map((item, index) => (
                                                    <>
                                                        {item.type == 'link' ?
                                                            <a target="_blank" href={item.content} class="react-tabs__tab">{item.name.replace('Listing', '')} <img src="/media/icon/noun-link-1768396-FFFFFF.svg" className="h-5 float-right" /></a>
                                                            :
                                                            <Tab><span className="md:text-sm">{item.name}</span></Tab>
                                                        }
                                                    </>
                                                ))}
                                            </TabList>

                                        </div>
                                        <div class="col-span-4 bg-gray-50">
                                            {contentItem.vizItem.items.map((item, index) => (
                                                <div className="px-8 ">
                                                    <TabPanel>
                                                        {/* <SubTab item={item}></SubTab> */}
                                                        {/* <h1>{item.type}</h1>
                                            <h1>{item.name}</h1> */}


                                                        {item.type == 'video' ? (
                                                            <div className="master-video">
                                                                <ReactPlayer url={item.content}
                                                                    width='600'
                                                                    controls={true}
                                                                    height='80%'
                                                                />
                                                            </div>
                                                        ) :
                                                            null
                                                        }
                                                        {item.type == 'text' ? (
                                                            <>
                                                                <h1 className=" mt-12 text-2xl mb-4 font-semibold">{item.name}</h1>
                                                                <p className="text-base leading-12">{contentItem.vizItem.description}</p>
                                                            </>
                                                        ) :
                                                            null
                                                        }
                                                        {item.type == 'solutions' ? (
                                                            <div className="mt-12 pr-12">
                                                                {item.items ?
                                                                    <div className="grid grid-cols-2 xl:gap-2 2xl:gap-4 2xl:mt-8 xl:mt-4 md:gap-2 md:mt-4">
                                                                        {item.items.map((item, index) => (
                                                                            // <div className="flex text-gray-100  items-center px-2 py-2 bg-gray-700 hover:bg-gray-800   transition-all rounded-md">
                                                                            //     {item.name}
                                                                            // </div>
//navLocationIndex

<button type="button" class="text-gray-100  items-center px-6 py-4 bg-gray-700 hover:bg-gray-800   transition-all rounded-md text-left inline-flex items-center" onClick={() => props.updateSceneFunction(item.navLocationIndex)}>
  <img src={item.logoIconUrl} className="h-6 mr-4" />
  {item.name}<br/>
</button>

                                                                        ))}
                                                                    </div>
                                                                    :
                                                                    (null)
                                                                }
                                                            </div>
                                                        ) :
                                                            null
                                                        }
                                                        {item.type == 'text-full' ? (
                                                            <>
                                                                <h1 className=" mt-8 text-2xl mb-4 font-semibold">{item.name}</h1>
                                                                <div className="text-lg text-gray-800 leading-relaxed mt-2 2xl:text-lg xl:text-base leading-12 md:text-sm" dangerouslySetInnerHTML={{ __html: item.content }} />

                                                                {/* <div className="bg-black text-white p-5" 
                                                                onClick={() => props.updateSceneFunction(2)}
                                                                >Test Button</div> */}

                                                                {item.items ?
                                                                    <div className="grid grid-cols-3 xl:gap-2 2xl:gap-4 2xl:mt-8 xl:mt-4 md:gap-2 md:mt-4">
                                                                        {item.items.map((item, index) => (
                                                                            <BlockContentTile heading={item.name} body={item.body} />
                                                                        ))}
                                                                    </div>
                                                                    :
                                                                    (null)
                                                                }
                                                            </>
                                                        ) :
                                                            null
                                                        }
                                                    </TabPanel>

                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </Tabs>
                            </>
                        )
                    }

                    const Card = ({ title, text, index, vizItem, vizParent }) => {
                        const [expanded, setExpanded] = useState(false);

                        // console.log('GOT PARENT...')
                        // console.log(vizParent)

                        const toggleExpanded = () => {
                            console.log('toggleExpanded')
                            const cards = document.querySelectorAll('.card');
                            if (!expanded) {
                                // setActiveSubTab(0);
                                // setActiveTab(index);

                                cards.forEach(card => {
                                    if (card.id !== 'card' + index) {
                                        card.classList.add('hidden');
                                    }
                                });
                            }
                            else {
                                cards.forEach(card => {
                                    card.classList.remove('hidden');
                                });
                            }
                            setExpanded(!expanded)
                        };

                        return (
                            <>
                                {expanded ? (
                                    // Card - detail view
                                    <>
                                        <div id={'card' + index} className={`card 2xl:h-80 xl:h-24 
                                    ${expanded ? 'expanded animate__animated animate__fadeIn' : ''}     
                                    ${vizParent.id == 10 ? 'persona_card' : ''}     
                                    `} >
                                            <button className="button absolute 2xl:right-2 md:right-4 xl:right-4 top-0 2xl:w-14 2xl:h-14 xl:w-10 xl:h-10 md:w-8 md:top-4"
                                                onClick={toggleExpanded}
                                            > 
                                                <img src={'/media/icon/noun-close-1256611.svg'} className='2xl:w-10 xl:w-8 opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                                            </button>
                                            <DetailTab vizItem={vizItem} vizParent={vizParent}></DetailTab>
                                        </div>
                                    </>
                                ) : (
                                    // Card - grid style
                                    <div id={'card' + index} className={`card group ${expanded ? 'disabled-button' : ''}  ${index == 0 & vizParent.id == 10 ? 'grid-span-col-1-6' : ''}    `}
                                        onClick={(ev) => {
                                            ev.stopPropagation();
                                            toggleExpanded();
                                        }}>

                                        {vizParent.id == 9 ?
                                            // ISV Card
                                            <div className="bg-gray-800">
                                                <div className="flex h-24 text-left 2xl:h-24 xl:h-14 md:h-12 md:p-1">
                                                    <div className="flex-auto w-64 2xl:py-8 xl:py-4 2xl:pl-8 xl:pl-4 md:p-2 md:py-2">
                                                        <h2 className="2xl:text-xl xl:text-lg xl:text-sm font-semibold text-white">{vizItem.name}</h2>
                                                    </div>
                                                    <div className="flex-initial w-14 h-14 2xl:mr-4 xl:mr-0">
                                                        <img src={vizItem.logoIconUrl} className="2xl:h-12 xl:h-8 2xl:mt-6 xl:mt-3 2xl:mr-4 xl:mr-0 md:h-6 md:mt-2" />
                                                    </div>
                                                </div>
                                                <div className="col-span-5 row-start-2 leading-relaxed  bg-gray-700  2xl:h-80 xl:h-52 text-gray-100 text-sm 2xl:px-8 xl:px-4 2xl:pt-4 xl:pt-2 text-left   2xl:text-base md:text-sm md:p-4">
                                                    {vizItem.description}
                                                </div>
                                            </div>
                                            :
                                            // Org Chart Card
                                            <div className="bg-gray-100 group-hover:bg-white transition-all ">
                                                <div className="pt-2 overflow-hidden">
                                                    <img src={vizItem.logoIconUrl} className="w-32 xl:w-20 md:w-20 2xl:w-32 mx-auto " />
                                                </div>
                                                <div className="bg-gray-800 p-4 2xl:p-4 md:p-2 xl:p-3">
                                                    <div className=" xl:h-10 2xl:h-14 text-center m-auto md:h-14">
                                                        <h2 className="text-lg xl:text-sm 2xl:text-lg md:text-sm font-base text-white">{vizItem.name}</h2>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                )}
                            </>
                        );
                    };


                    //ecoCardItems.map((vizItem, index) => (
                    //  <>
                    return (
                        <>
                            <div style={{ animationDelay: vizItem.animationDuration + 's ', imageStyle }}
                                className={`vizoverlay 
                            ${vizItem.id == 9 ? 'master-detail-card eco-overlay' : ('master-detail-card-nobg eco-overlay')} 
                            ${sessionData.activeIndex == sceneInfo.order ? " animate__animated animate__fadeIn " + animationStyle : " animate__fadeOut"}`}
                                key={sceneInfo.guid}
                            >

                                <main className="min-h-full place-items-center " style={styleOffset}>
                                    <div className={"card-grid mt-2 xl:mt-4 2xl:mt-24 grid-type" + vizItem.id}  >
                                        {vizItem.content.map((item, index) => (
                                            <Card key={item.guid} title={item.name} text={item.description} index={index} vizItem={item} vizParent={vizItem} />
                                        ))}
                                    </div>
                                </main>
                            </div>
                        </>
                    )
                    //  </>
                    //))
                    // }
                }
            }
        }
    }
}
export default ExpHTMLEcoCard;