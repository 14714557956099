import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import FormContact from './FormContact'

const CompContact = (props) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <span className="mx-auto 
            w-full
                          text-left mt-8
                          mb-6 rounded-md bg-zinc-100/10 px-5 py-3 text-base text-xl
                          text-white shadow-sm hover:bg-zinc-700 focus-visible:outline focus-visible:outline-2 
                          block font-medium
                          focus-visible:outline-offset-2 focus-visible:outline-white
                          hover:scale-1025
                          transition-all " onClick={() => { setOpen(true) }}>
                {props.linkText}
            </span>
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 -mt-20 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  rounded-lg modal-theme px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
                                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                                        <button
                                            type="button"
                                            className="rounded-md   focus:outline-none  "
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">

                                        {/* <img className="h-14 w-auto" src="/media/icon/Brevit3-Logo-Home.png" alt="" /> */}
                                        <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            {/* <Dialog.Title as="h3" className="text-2xl font-semibold 
                                            leading-6 text-gray-100 mt-4 ml-4">
                                                Early Access Registration
                                            </Dialog.Title> */}
                                            <div className="md:m-12 m-0  h-[32rem]">

                                                <div class="grid grid-cols-4 sm:grid-cols-4 gap-4 mt-20">
                                                    <div className='text-gray-400 md:col-span-2 col-span-4 text-lg md:pr-12 pr-0'>

                                                        <div className='text-2xl md:mb-14 mb-2 text-gray-50 -mt-12 md:mt-0 font-semibold'>
                                                            {props.headingText}
                                                        </div>

                                                        {/* <p className='mt-8'>Customer success is the very heart of our efforts to propel you and your message to the </p> */}
                                                        <div className='hidden md:block'>
                                                        <p className='mt-8'>Please let us know how we can assist.  We will respond within 24 hours and look forward to connecting.</p>
                                                        <p className='mt-8'>Sincerely,</p>
                                                        <p>The Brevit3 Team</p>
                                                        </div>
                                                    </div>
                                                    <div className='md:col-span-2 col-span-4'>
                                                    <div id="WaitlistForm"></div>
                                                        <FormContact />
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default CompContact