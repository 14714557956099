import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";

import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'
//import { render } from "@headlessui/react/dist/utils/render";

const NavigationTimelineMainSub = (props) => {

    const [sceneData, setSceneData] = useContext(SceneContext)
    const [storyData, setStoryData] = useContext(StoryContext)
    const [sessionData, setSessionData] = useContext(SessionContext)

    //const renderNav = () => {

    //const renderNav = () => {
        return (
            <div className="navigation-segment-wrapper navstyle-bottom NavigationTimelineMainSub">
                <div className="flex mode-timeline">
                    <div className="grow h-14">
    
                        <nav aria-label="Progress">
                            <ol role="list" className="divide-y divide-gray-300 rounded-md md:flex md:divide-y-0 menu-items">
                                {sceneData.map((step, stepIdx) => (
                                    <li key={step.id} className="relative md:flex md:flex-1">
                                        {stepIdx < sessionData.activeIndex ? (
                                            <div onClick={() => {
                                                props.updateSceneFunction(stepIdx)
                                            }} className="group flex w-full items-center px-4 py-2 timeline-nav-bg timeline-prior">
    
                                                <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1">
                                                    <div className="col-span-2 nav-heading">
                                                        <span className="ml-2 text-gray-900 text-lg font-semibold">{step.name}</span>
                                                    </div>
                                                    {/* <div className="col-span-2 mt-1 nav-subheading">
                                                    <span className="ml-2">Subheading here</span>
                                                </div> */}
                                                </div>
                                            </div>
                                        ) : sessionData.activeIndex == stepIdx ? (
                                            <div onClick={() => {
                                                props.updateSceneFunction(stepIdx)
                                            }} className="flex items-center px-4 py-2 timeline-nav-bg timeline-current" aria-current="step">
                                                <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1">
                                                    <div className="col-span-2 nav-heading active">
                                                        <span className="ml-2 text-gray-900 text-lg font-semibold">{step.name}</span>
                                                    </div>
                                                    {/* <div className="col-span-2 mt-1 nav-subheading">
                                                    <span className="ml-2">Subheading here</span>
                                                </div> */}
                                                </div>
                                            </div>
                                        ) : (
                                            <div onClick={() => {
                                                props.updateSceneFunction(stepIdx)
                                            }} className="group flex items-center timeline-nav-bg timeline-next">
                                                <span className="flex items-center px-4 py-2">
                                                    <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1">
                                                        <div className="col-span-2 nav-heading">
                                                            <span className="ml-2 text-gray-500 text-lg font-semibold">{step.name}</span>
                                                        </div>
                                                        {/* <div className="col-span-2  mt-1 nav-subheading">
                                                        <span className="ml-2">Subheading here</span>
                                                    </div> */}
                                                    </div>
    
                                                </span>
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </nav>
    
                        <div className="navigation-progress-wrapperxx w-full h-2 mx-4 rounded-md -mt-8 bg-gray-500 overflow-hidden">
                            <div 
                                className="navigation-progress-meter h-2 bg-workout-ai"
                                id="navigation-progress-meter"
                                // style={{ width: `${sessionData.activeIndex / (sceneData.length - 1) * 100}%` }}
                             >
                             </div>
                        </div>
    
                    </div>
                    {/* <div className="flex-none w-12 h-12 bg-gray-100 rounded p-2 m-2 ml-4 mr-0 hover:bg-gray-200 -mt-4">
                    <button className="button-playmode pip" onClick={ToggleSideMode}>
                        <img src="/media/icon/noun-picture-in-picture-3643905-111111.svg" className="o5" />
                    </button>
                </div>*/}
                    {/* <div className="flex-none w-12 h-12 bg-gray-100 rounded p-2 m-2 hover:bg-gray-200 -mt-4">
                    <button className="button-playmode " onClick={props.TogglePlayMode}>
                        <img src="/media/icon/noun-down-arrow-1035684-111111.svg" className="o5" />
                    </button>
                </div>  */}
    
                    <div className="flex-none w-24 h-12   -mt-4">
                        {/* <button className="button-playmode " onClick={props.TogglePlayMode}>
                            <img src="/media/icon/noun-down-arrow-1035684-111111.svg" className="o5" />
                        </button> */}
                    </div>
                </div>
            </div>
        )
   // }



    // useEffect(() => {
    //     setTimeout(() => {
    //     renderNav()
    //     }, 100)
    // }, [])


    // useEffect(() => {   
    //     // Single render...

    // }, [])


    //renderNav()

}

export default NavigationTimelineMainSub