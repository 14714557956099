// import './style2.css'
import './StyleApp.css'
// import './Brevit3Style.css'
//import './Style-Scene-Card.css'
//import './Style.css'
import './OverlayEdit.css'
import './StyleAnimations.css'

// New Version Below with Auth
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);


// OLDER VERSION
// import ReactDOM from 'react-dom/client'
// import AppMainH from './AppMainH';

// const root = ReactDOM.createRoot(document.querySelector('#root'))
// root.render(<AppMainH />);