import { height } from "./ArcDiagram";
//import data from "./data.json";
import { scalePoint } from "d3";

const getLinks = (xScale, data) => {
  const links = data.links;

  console.log('ArcDiagram data LINKS:')
    console.log(data)
  console.log(links)

  const mappedLinks = links.map((d) => {
    const start = xScale(d.from);
    const end = xScale(d.to);
    const inflexion = 1;
    const inverse = start < end;
    return {
      ...d,
      inverse,
      d: [
        "M",
        start,
        height / 2,
        "A", // This means we're going to build an elliptical arc
        inflexion,
        ",", // Next 2 lines are the coordinates of the inflexion point. Height of this point is proportional with start - end distance
        inflexion,
        0,
        0,
        ",",
        1,
        end,
        ",",
        height / 2,
      ] // We always want the arc on top. So if end is before start, putting 0 here turn the arc upside down.
        .join(" "),
    };
  });
  return mappedLinks;
};
const minThickness = 1;
const maxThickness = 52;

export const Links = ({ xScale, colorScale, data }) => {
  const links = getLinks(xScale, data);
  const lineThickness = scalePoint()
    .range([minThickness, maxThickness])
    .domain(links.map((d) => d.count).sort((a, b) => a - b));
  return (
    <>
      {links.map(({ d, to, from, count, inverse }, i) => {
        return (
          <path
            key={i}
            d={d}
            stroke={`url('#${colorScale(inverse ? from : to)}-${colorScale(
              inverse ? to : from
            )}')`}
            fill="transparent"
            opacity={0.5}
            strokeWidth={lineThickness(count * 2)}
          />
        );
      })}
    </>
  );
};
