import React, { useEffect, useState } from 'react';
//import { UserAuth } from '../context/AuthContext';
//import { useNavigate, NavLink, Link } from 'react-router-dom';
//import { createUserWithEmailAndPassword } from 'firebase/auth';
//import { auth } from '../firebase';
import CompRegister from './CompRegister'
//import GoogleButton from 'react-google-button';
import CompContact from './CompContact';

const Login = () => {
  // redirect

  return (

    <div className="flex   flex-1 flex-col justify-left pt-40 pb-40  ">
      <div></div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-left">
        {/* <img src="./media/icon/Bevit3-Logo-Rainbow.png" id="login-logo" className="logo"></img> */}
        <h1 className='text-xl font-base py-2 text-gray-300 text-center mt-12'>Sign in to your account</h1>
        <div className='rounded-md bg-white/5 text-lg text-zinc-200 p-6 ring-1 ring-inset ring-white/10 mt-4'>


          <p>
            Please use the login with your assigned enterprise address.  If you do not have this information, please contact us below and we will be happy to assist you.
          </p>
          
          <CompContact linkText={'Contact Us'} headingText={'Enterprise Login Request'} />




        </div>

      </div>
      <div></div>
    </div>

  );
};

export default Login;
