import React, { useEffect, useState } from 'react';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import { useNavigate, NavLink, Link } from 'react-router-dom';
//import { NavLink } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import { auth } from '../firebase';

const Signup = () => {
    const { googleSignIn, user } = UserAuth();
    const navigate = useNavigate();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log('Signin Error')
            console.log(error)
        }
    };


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    //const {setTimeActive} = useAuthValue()

    const validatePassword = () => {
        let isValid = true
        if (password === '' || confirmPassword === '') {
            isValid = false
            setError('Please enter a password and confirmation')
        }
        if (password !== '' && confirmPassword !== '') {
            if (password !== confirmPassword) {
                isValid = false
                setError('Passwords do not match')
            }
        }
        return isValid
    }

    const register = e => {
        e.preventDefault()
        setError('')
        if (validatePassword()) {
            // Create a new user with email and password using firebase
            createUserWithEmailAndPassword(auth, email, password)
                .then(() => {
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            setEmailSent(true)
                            setEmail('')
                            setPassword('')
                            setConfirmPassword('')
                            //setTimeActive(true)
                            //navigate('/verify-email')
                        }).catch((err) => alert(err.message))
                })
                .catch(err => setError(err.message.replace('Firebase:', ''))
                    //err => console.log(err)
                )
        }
    }


    useEffect(() => {
        if (user != null) {
            if (user.accessToken) {
          navigate('/app/home');
            }
        }
      }, [user]);


    return (




        <div className="flex   flex-1 flex-col justify-left pt-40 pb-40  ">
            <div></div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-left">
                <img src="./media/icon/Bevit3-Logo-Rainbow.png" id="login-logo" className="logo"></img>
                <h1 className='text-xl font-base py-2 text-gray-300 text-center mt-12'>Account Registration</h1>





                <div className='rounded-md bg-white/5 p-6 ring-1 ring-inset ring-white/10 mt-4'>

                    {!emailSent ?
                        <form onSubmit={register}>
                            <div className='flex flex-col py-2'>
                                <div className='text-sky-500 w-full mx-auto text-center'>{error && <div className='auth__error pb-4'>{error}</div>}</div>
                                <label className='py-2 font-sm  text-gray-300'>Email Address</label>
                                <input onChange={(e) => setEmail(e.target.value)} className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6' type='email' />
                            </div>
                            <div className='flex flex-col py-2'>
                                <label className='py-2 font-sm text-gray-300'>Password</label>
                                <input onChange={(e) => setPassword(e.target.value)} className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6' type='password' />
                            </div>
                            <div className='flex flex-col py-2'>
                                <label className='py-2 font-sm text-gray-300'>Confirm Password</label>
                                <input onChange={(e) => setConfirmPassword(e.target.value)} className='block w-full rounded-md border-0 bg-white/5 py-3 px-3 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6' type='password' />
                            </div>
                            <button className='border border-blue-500 bg-sky-600 hover:bg-sky-500 w-full py-2 mt-6 text-white rounded'>
                                Create Account
                            </button>
                        </form>
                        :
                        <div className='flex flex-col py-2'>
                            <label className='py-2 font-sm text-gray-300'>An email has been sent to your address with a link to verify.  Please click the link in the email to verify your account to continue.</label>
                        </div>

                    }

                    {!emailSent ?
                        <>
                            <div className="relative mt-10 mb-10">
                                <div className="absolute inset-0 flex items-center " aria-hidden="true">
                                    <div className="w-full  " />
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className=" px-6 text-gray-200">Or continue with</span>
                                </div>
                            </div>

                            <div className='google-login-center mb-6'>
                                <GoogleButton
                                //type="dark"
                                onClick={handleGoogleSignIn}
                                
                                />
                            </div>
                        </>
                        : null}


                </div>
            </div>


            <div className='text-center mx-auto mt-12 text-gray-50'>
                Already have an account?{' '}
                <NavLink to="/signin" className='text-sky-500'>
                    Sign in
                </NavLink>
            </div>

        </div>








    );
};

export default Signup;
