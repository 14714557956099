import React, { useEffect, useRef, useState } from 'react'
import { useGLTF, useMatcapTexture, Float } from '@react-three/drei'
import * as THREE from 'three'
import { useTexture } from '@react-three/drei'
import { text } from 'd3'


export function ExpModelMatcap(props) {

    const groupRef = useRef()
    const { nodes, materials } = useGLTF('/components/' + props.model + '.glb')
    let materialNew = new THREE.MeshMatcapMaterial()
    const [material, setMaterial] = useState(materialNew)

    //const [matcapTexture] = useMatcapTexture("C9C7BE_55514B_888279_7B6E5F", 1024);

    // if (props.matcap) {
    //     const matcapTexture = useTexture('/util/matcap/' + props.matcap + '.png');
    //     material.matcap = matcapTexture
    // }
    let textureFile = 'MC-Sample6.png'
    if ( props.matcap != '') {
        // const matcapTexture = useTexture('/util/matcap/' + textureFile);
        // material.matcap = matcapTexture
        textureFile = props.matcap + '.png'
    }
    const matcapTexture = useTexture('/util/matcap/' + textureFile);

    return (
        <>
        {props.animation ?
        <Float
          speed={2} // Animation speed, defaults to 1
          rotationIntensity={0} // XYZ rotation intensity, defaults to 1
          floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
          floatingRange={[2, 4]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
        >
            <group ref={groupRef} dispose={null}>
                <mesh geometry={nodes[props.model + ''].geometry} position={props.position} scale={[1, 1, 1]}>
                    <meshMatcapMaterial matcap={matcapTexture} />
                </mesh>
            </group>
        </Float>
        : (null) }
        {!props.animation ?
            <group ref={groupRef} dispose={null}>
                <mesh geometry={nodes[props.model + ''].geometry} position={props.position} scale={props.scale || 1}>
                    <meshMatcapMaterial matcap={matcapTexture} />
                </mesh>
            </group>
        : (null) }        
        </>

    )
}