import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { Listbox, Transition } from '@headlessui/react'
import Slider from 'react-input-slider'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useParams } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'
import GridSelect from "./CompGridSelect";
import { AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";

import { CompactPicker, ChromePicker, SketchPicker } from "react-color";

const ConfigFreeText = ({ contentItem }) => {

  const [valueLongText, setValueLongText] = useState();
  const { storyId } = useParams()
  const animationStyles = [
    { id: 1, name: 'none', displayName: 'None' },
    { id: 2, name: 'bounceIn', displayName: 'Bounce In' },
    { id: 3, name: 'fadeIn', displayName: 'Fade In' },
    { id: 4, name: 'fadeInDown', displayName: 'Fade In Down' },
    { id: 5, name: 'fadeInLeft', displayName: 'Fade In Left' },
    { id: 6, name: 'fadeInRight', displayName: 'Fide In Right' },
    { id: 7, name: 'fadeInUp', displayName: 'Fade In Up' },
    { id: 8, name: 'zoomIn', displayName: 'Zoom In' },
  ]

  useEffect(() => {
    if (contentItem) {
      setValueLongText(contentItem.content)
      console.log('Content Item...')
      console.log(contentItem)
    }
  }, [])

  const [storyData, setStoryData] = useContext(StoryContext)
  const [sceneData, setSceneData] = useContext(SceneContext)
  const [sessionData, setSessionData] = useContext(SessionContext)



  // const [personaName, setPersonaName] = useState(item.mediaItem.personaName)
  // const [personaRole, setPersonaRole] = useState(item.mediaItem.personaRole)
  // const [personaPosX, setPersonaPosX] = useState(item.item.personaPosX)
  // const [personaDetail, setPersonaDetail] = useState(item.mediaItem.personaDetail)

  const [personaPositionXY, setPersonaPositionXY] = useState({ x: contentItem.pos.x, y: contentItem.pos.y })
  const [personaSize, setPersonaSize] = useState({ width: contentItem.size.width, height: contentItem.size.height })
  const [personaDelay, setPersonaDelay] = useState(contentItem.animationDuration)
  const [personaAnimation, setPersonaAnimation] = useState(contentItem.animation)


  const mediaitem = contentItem

  // const handleSliderXChange = (value) => {
  //   setPersonaPosX(value)
  // }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const updateContent = async (e) => {
    console.log('Update Content...')
    console.log(valueLongText)
    console.log(contentItem)

    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizActive.forEach(item => {
      console.log(item.guid)
      if ((contentItem.guid == item.guid)) {

        // position (this will lay out in a tl, tr, bl, br, center)

        //contentItem.position = (this will lay out in a tl, tr, bl, br, center)
        contentItem.pos = personaPositionXY
        contentItem.size = personaSize
        contentItem.animationDuration = personaDelay
        contentItem.animation = personaAnimation
        contentItem.content = valueLongText
      }
    })

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizActive
    })
  }


  const setNewPos = (newPos) => {

    setPersonaPositionXY({ x: newPos.x, y: newPos.y })

    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]
    cloneVizActive.forEach(item => {
      //console.log(item.guid)
      if ((contentItem.guid == item.guid)) {
        contentItem.pos = { x: newPos.x, y: newPos.y }
      }
    })

    let cloneSceneDataActive = []
    cloneSceneDataActive = [...sceneData]
    cloneSceneDataActive[sessionData.activeIndex].viznodes = cloneVizActive
    setSceneData(cloneSceneDataActive)

    const activeSceneId = sceneData[sessionData.activeIndex].id

    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId);
    //await 
    updateDoc(sceneRef, {
      viznodes: cloneVizActive
    })
  }

  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // ["bold"],
      // [{ list: "ordered" }, { list: "bullet" }],
      // ["link"],

      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      //[{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      //['link', 'image', 'video'],
      ['link'],
      [{ color: [] }, { background: [] }],

      ['clean'],

    ]
  }



  const CompColorPicker = (props) => {
    const [colorVariable, setColorVariable] = useState('#000000')
    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
      // setDataLoaded(true)
      // if (storyData != null) {
      //   setColorVariable(storyData.uiStyle[props.styleName])
      // }
    }, [])

    const storeColor = (color) => {
      // if (color != undefined) {
      //   const storyRef = doc(db, 'stories', storyId)
      //   let cloneStory = storyData

      //   cloneStory['uiStyle'] =
      //   {
      //     ...cloneStory['uiStyle'],
      //     [props.styleName]: color
      //   }
      //   updateDoc(storyRef,
      //     cloneStory
      //   )
      // }
    }


    const preExpandedDesignGuid = ['backgroundEditorColor']


    return (

      <SketchPicker />

      // <AccordionItem onClick={() => {
      //   preExpandedDesignGuid //!= props.styleName ? setPreExpandedDesignGuid([props.styleName]) : null
      // }}
      //   uuid={props.styleName}>
      //   <AccordionItemHeading>
      //     <AccordionItemButton>
      //       <div className="flex w-3/4 ml-6 -mt-6">
      //         {dataLoaded ?
      //           <div className="flex-none h-6 w-6 rounded-full mr-2" style={{ background: storyData.uiStyle[props.styleName] }}></div>
      //           :
      //           <div className="flex-none h-6 w-6 rounded-full mr-2" style={{ background: '#000000' }}></div>
      //         }
      //         <div className="inline flex-auto ml-2 mt-0.5 text-sm text-gray-300">{props.title}</div>
      //       </div>
      //     </AccordionItemButton>
      //   </AccordionItemHeading>
      //   <AccordionItemPanel data-itemguid={'none'}>



      //     <CompactPicker
      //       // width={'100%'}
      //       // height={200}
      //       style={{ width: '20px', background: '#000000' }}
      //       color={colorVariable}
      //       disableAlpha={true}
      //       className="color-picker-dark"
      //       onChange={(color) => {
      //         //setColorVariable(color.hex)
      //         //              storeColor(colorVariable)
      //         //console.log('on change...')
      //         storeColor(color.hex)
      //         // Update DB... maybe use the DB to push back to this UI...
      //       }
      //       }
      //       onChangeComplete={(color, event) => {
      //         //            setColorVariable(color.hex)
      //         // storeColor(colorVariable)
      //         // console.log('on change complete...')
      //       }
      //       }
      //     />

      //   </AccordionItemPanel>
      // </AccordionItem>



    )
  }




  return (
    // <form onSubmit={(e) => {
    //     e.preventDefault()
    // }}>
    <div className="">

      <div className='w-100p float-left slider-parent-size mt-1'>

        <div className=" h-200">
          <ReactQuill theme="snow"
            modules={modules}
            value={valueLongText} onChange={setValueLongText} />
        </div>


        {/* <div className="flex  mb-4 text-sm mt-4 ">
          <CompColorPicker title='Edit Background' styleName='backgroundEditorColor' />
        </div>
        <div className="flex mb-4 text-sm mt-4">
          <div className="flex-none w-20 h-4 text-gray-300 ml-1 mr-2">
            Border Padding
          </div>
          <div className="flex-auto">
            <Slider
              axis="x"
              xmin={0}
              xmax={50}
              xreverse={false}
              xstep={1}
              x={personaSize.borderPadding || 0}
              onChange={({ x }) => setPersonaSize(state => ({ ...state, borderPadding: x }))}
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21',
                  width: 50
                }
              }}
            />
          </div>
          <div className="flex-1 w-14 text-gray-300 ml-2">
            {personaSize.borderPadding}
          </div>
        </div>

        <div className="flex mb-4 text-sm mt-4">
          <div className="flex-none w-20 h-4 text-gray-300 ml-1 mr-2">
            Width
          </div>
          <div className="flex-auto">
            <Slider
              axis="x"
              xmin={0}
              xmax={100}
              xreverse={false}
              xstep={1}
              x={personaSize.borderRadius || 0}
              onChange={({ x }) => setPersonaSize(state => ({ ...state, borderRadius: x }))}
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21',
                  width: 100
                }
              }}
            />
          </div>
          <div className="flex-1 w-14 text-gray-300 ml-2">
            {personaSize.borderRadius}
          </div>
        </div>
*/}

        <div className="flex mb-4 text-sm mt-4">
          <div className="flex-none w-20 h-4 text-gray-300 ml-1 mr-2">
            Width
          </div>
          <div className="flex-auto">
            <Slider
              axis="x"
              xmin={100}
              xmax={1600}
              xreverse={false}
              xstep={10}
              x={personaSize.width}
              onChange={({ x }) => setPersonaSize(state => ({ ...state, width: x }))}
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21',
                  width: 180
                }
              }}
            />
          </div>
          <div className="flex-1 w-14 text-gray-300 ml-2">
            {personaSize.width}
          </div>
        </div> 

        <div className='flex w-full'>

          <div className='flex w-1/5 mr-4 text-gray-300 pt-3 text-sm ml-1 pr-3'>
            Animation
          </div>
          <div className='flex-auto'>
            <div className='grid grid-cols-5 gap-3 mt-1 mb-4  '>
              <div className='col-span-3'>
                <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                  {({ open }) => (
                    <>
                      <div className="relative  ">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white/5 py-2 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6
                            hover:bg-white/10
                            ">
                          <span className="block truncate">{personaAnimation.displayName || 'none'} &nbsp;</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>
                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {animationStyles.map((person) => (
                              <Listbox.Option
                                key={person.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={person}
                              >
                                {({ cardAnimation, active }) => (
                                  <>
                                    <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                      {person.displayName}
                                    </span>
                                    {cardAnimation ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-sky-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <div className='col-span-2'>
                <div className='grid grid-cols-10 gap-0'>
                  <div className='col-span-3  p-2  text-center font-bold cursor-pointer bg-white/5 hover:bg-white/10 rounded'
                    onClick={(e) =>
                      //setPersonaDelay(personaDelay - 1)
                      personaDelay > 0 ? setPersonaDelay(personaDelay - 1) : 0
                    }
                  >-</div>
                  <div className='col-span-4'>
                    <div className='className="block w-full rounded-md border-0  px-1.5 py-2 
text-white   sm:text-sm sm:leading-6 text-center'>
                      {!personaAnimation.displayName ? 0 : personaDelay}
                    </div>
                  </div>
                  <div className='col-span-3 p-2 font-bold cursor-pointer text-center bg-white/5 hover:bg-white/10 rounded'
                    onClick={(e) => setPersonaDelay(personaDelay + 1)}
                  >+</div>
                </div>
              </div>
            </div>
          </div>
        </div>




      </div>

      <div className='w-100p   mt-3'>

        {/* <Slider
          axis="xy"
          xmin={-30}
          xmax={30}
          ymin={-10}
          ymax={30}
          xreverse={true}
          yreverse={true}
          x={personaPositionXY.x}
          y={personaPositionXY.y}
          onChange={setPersonaPositionXY}
          styles={{
            active: {
              backgroundColor: '#333'
            },
            track: {
              backgroundColor: '#1b1e21'
            }
          }}
        /> */}


        <GridSelect
          //viznodes
          activeNode={
            contentItem.guid
            //'123'
          }
          viznodes={
            sceneData[sessionData.activeIndex].viznodes
            //sceneDataforGrid
          }
          setCardPositionXY={setNewPos}
        />

      </div>
      {/* <div className='text-white  mb-4'>
              Position {personaPositionXY.x}, {personaPositionXY.y}
            </div> */}



      <div className="sm:col-span-1 mt-6">
        <button className='w-full justify-center rounded-md bg-sky-500 px-3 py-1
                    text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={() => updateContent()}>Update</button>
      </div>
    </div>
    // </form>
  )
}

export default ConfigFreeText