import React from 'react'
import { CompHSChat } from '../components/CompHSChat'
//import { UserAuth } from '../context/AuthContext'
import FormEAPSupport from './FormEAPSupport'
import CompEAPSupport from './CompEAPSupport'
import { UserAuth } from '../context/AuthContext'

import CompHelpGuide from '../components/CompHelpGuide'

const Home = () => {



  const currentHour = new Date().getHours();

  const greetingMessage =
    currentHour >= 4 && currentHour < 12 ? // after 4:00AM and before 12:00PM
    'Good morning' :
    currentHour >= 12 && currentHour <= 17 ? // after 12:00PM and before 6:00pm
    'Good afternoon' :
    currentHour > 17 || currentHour < 4 ? // after 5:59pm or before 4:00AM (to accommodate night owls)
    'Good evening' : // if for some reason the calculation didn't work
    'Welcome'
  
  //console.log(greetingMessage)


  // const { user, logOut } = UserAuth() //{user?.photoURL}

  // console.log('user...')
  // console.log(user)

  const cards = [
    {
      name: 'Stories',
      description: 'Create engaging, dynamic stories to share with your audience - on any channel, place and time.',
      icon: 'noun-book-534697-FFFFFF',
    },
    {
      name: 'Insights',
      description: 'Understand your audience to optimize your messaging and content - helping to boost engagement and conversion.',
      icon: 'noun-analytics-1508615-FFFFFF',
    },
    {
      name: 'Messages',
      description: 'Easily communicate with your viewers, helping to quickly answer their key questions.',
      icon: 'noun-chat-1459497-FFFFFF',
    },
  ]

  const people = [
    { name: 'Interface', title: 'additional navigation styles', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: '3D Assets', title: 'move and resize 3D assets, additional 3D assets', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Themes', title: 'more out-of-the-box themes to get up and running quickly with great looking Stories, as well as the ability to save your own', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Templates', title: 'create and save your own scene templates to reuse across Stories', email: 'lindsay.walton@example.com', role: 'Member' },
    // More people...
  ]


  const posts = [
    {
      id: 1,
      title: 'Boost your conversion rate',
      href: '#',
      description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel iusto corrupti dicta laboris incididunt.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Marketing', href: '#' },
      author: {
        name: 'Michael Foster',
        role: 'Co-Founder / CTO',
        href: '#',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    // More posts...
  ]


  const { user, logOut } = UserAuth()

  return (
    <>
      <>

        {/* <div className="md:flex md:items-center md:justify-between mb-8">
          <div className="min-w-0 flex-1 ml-8">
            <h2 className="text-xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
              Early Access Program Members - Welcome
            </h2>
          </div>
        </div> */}
        <div className="h-screen  overflow-y-auto admin-scroll pr-12 ">


          {/* Upper right button here */}

          <div className="relative isolate  bg-zinc-9xx00   rounded-md">

            

              <div className="w-full  ml-8 pr-12">

              {/* <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
                {greetingMessage} {user?.displayName.split(' ')[0]}
              </h2> */}
              
                <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">Welcome &amp; Getting Started</h2>
                <p className="mt-4 text-base leading-8 text-gray-400">
                  We are excited to help support your storytelling journey.  The following resources will help you to get a jump start on your first story.  Please reach out with any questions or feedback and we appreciate your participation in our Early Access Program.
                </p>
              </div>

              <div className='flex mt-8 pr-8'>
                <div className="flex-auto">

                  <div className='rounded-t-lg bg-zinc-900 p-4'>
                    <p className='text-gray-50 text-xl  text-center'>1. Start Here</p>
                  </div>
                  <div className='rounded-b-lg bg-zinc-900/50 p-8'>

                    <p className='relative inline'>
                      <img src="/util/app/b3-story-diagram.svg" className='w-3/4 mx-auto' />
                    </p>

                    <p className="mt-4 text-base leading-8 text-gray-400">
                      Check out this quick overview of the key concepts and capabilities of Brevit3 to help you get started creating stories.
                    </p>
                    <p className='mt-8'>
                      <CompHelpGuide linkText="Open Concepts Overview" />
                    </p>
                  </div>

                </div>
                <div className='flex-none w-16 mx-4'>
                  <img src="/util/app/b3-steps-right.svg" className='w-full mx-auto mt-40 opacity-70' />
                </div>
                <div className="flex-auto">
                  <div className='rounded-t-lg bg-zinc-900 p-4'>
                    <p className='text-gray-50 text-xl  text-center'>2. Create Tutorial Story</p>
                  </div>
                  <div className='rounded-b-lg bg-zinc-900/50 p-8'>
                    <p className='relative inline'>
                      <img src="/util/app/b3-story-tutorial.svg" className='w-3/4 mx-auto' />
                    </p>

                    {/* <p className='text-gray-50 text-xl  mt-12'>2. Load Training Sample</p> */}
                    <p className="mt-4 text-base leading-8 text-gray-400">
                      Head over to the <a href='/app/stories' className='text-sky-500 hover:text-sky-400'>Recent Stories</a> page and load the training sample to get started.
                    </p>
                    <div className='mt-16'>
                      <a className="text-sky-500 hover:text-sky-400" href="/app/stories">
                        Open Recent Stories and Load Sample
                        {/* <span className='ml-2' aria-hidden="true">→</span> */}
                      </a>
                    </div>
                  </div>
                </div>
                <div className='flex-none w-16 mx-4'>
                  <img src="/util/app/b3-steps-right.svg" className='w-full mx-auto mt-40 opacity-70' />
                </div>
                <div className="flex-auto">
                  <div className='rounded-t-lg bg-zinc-900 p-4'>
                    <p className='text-gray-50 text-xl  text-center'>3. Here to Help</p>
                  </div>
                  <div className='rounded-b-lg bg-zinc-900/50 p-8'>
                    <div className='block w-full'>
                      <img src="/util/app/b3-zoom.svg" className='w-3/4 mx-auto' />
                    </div>
                    {/* <div className='text-gray-50 text-xl   mt-12'>3. Here to Help</div> */}
                    <div className="mt-4 text leading-8 text-gray-400 mb-8">
                      If you encounter any issues or have feedback, please do not hesitate to reach out to us.
                    </div>

                    <CompEAPSupport />

                    {/* <div className='grid grid-cols-8 gap-2 mt-8 text-center'>
                    <div className='col-span-4'>
                      <CompEAPSupport />
                    </div>
                    <div className='col-auto text-gray-50'>or</div>
                    <div className='col-span-3 pt-1.5 text-left'>
                      <a className=" font-semibold
            transition ease-in-out 
            bg-sky-600
            hover:bg-sky-500
            py-2
            px-4
            rounded-md
            w-full
            click:scale-100
            text-white
            text-sm
            cursor-pointer" href="https://calendly.com/brevit3/45min" target='_blank'>
                        Schedule Call
                      </a>
                    </div>
                  </div> */}

                  </div>
                </div>
              </div>

            


          </div>



          <h2 className="text-xl font-normal leading-7 text-gray-200 sm:truncate ml-8 sm:tracking-tight mt-12">
            Announcements and Updates
          </h2>
                {/* <p className="mt-4 text-base leading-8 text-gray-400">
                  We are excited to help support your storytelling journey.  The following resources will help you to get a jump start on your first story.  Please reach out with any questions or feedback and we appreciate your participation in our Early Access Program.
                </p> */}

          <div className=" mb-12 pr-2 pb-96 -ml-10 mt-6">
            <div className="mx-auto  ">

              <div className="max-w-full px-6 grid grid-cols-2 gap-8 bg-zinc-900/50  rounded-md">

                {/* <div className='text-gray-200  bg-zinc-900/50  px-12 py-6 rounded-md mt-12'>
                  <h2 className="text-xl font-base tracking-tight text-white"><img src="/media/icon/noun-2296991-FFFFFF.svg" className='inline h-10 mr-4'/> Messages</h2> */}


                <div className='text-gray-200   p-8 pl-12 rounded-md'>
                  <div className='pr-8'>
                    <h2 className="text-xl font-base tracking-tight text-white">
                      <img src="/util/app/b3-messages.svg" className='inline h-10 mr-4' /> Messages</h2>
                  </div>
                  <p className="mt-8 text-xl leading-8  font-semibold text-gray-50">
                    Welcome Early Access Members
                  </p>
                  {/* <p className='text-gray-100 text-sm mb-4 mt-1'>10.20.23</p> */}
                  <p className="text-base leading-8 text-gray-300 mb-8">
                    We are thrilled that you could join us in our efforts to resphape how we share the value of enterprise solutions.
                  </p>
                  <p className="text-base leading-8 text-gray-300 mb-8">
                    We are excited to help support your storytelling journey. Use these materials to get a jump start on your first story.
                  </p>
                  {/* <p className="text-base leading-8 text-gray-300 mb-8">
                    Upcoming Product Capabilities
                  </p> */}

                  <p className="text-base leading-8 text-gray-300 mb-8">
                    Happy Storytelling!
                  </p>
                  <p className="text-base text-gray-300 -mt-8">
                    Brevit3 Team
                  </p>
                </div>


                {/* <div className='text-gray-200   p-8 rounded-md'>
                  <div className=' '>
                    <h2 className="text-xl font-base tracking-tight text-white"><img src="/util/app/b3-dev.png" className='inline h-10 mr-4' /> Upcoming Product Enhancements</h2>
                    <p className="text-base leading-8 text-gray-300 mb-8 mt-8">
                      We will be updating the platform regulary with new capabilities and releasing weekly.  Updates are seamless and won't disrupt your work.
                    </p>


                    <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-zinc-700">
                    <thead>
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0">
                          Area
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                          Details
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-zinc-700">
                      {people.map((person, index) => (
                        <tr key={person.email + index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-0">
                            {person.name}
                          </td>
                          <td className="whitespace-nowraxxp px-3 py-4 text-sm text-gray-300">{person.title}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>




                    {/* <p className="text-base leading-8 text-gray-300 mb-2">
                      &#8226; Interface - additional navigation styles
                    </p>
                    <p className="text-base leading-8 text-gray-300 mb-2">
                      &#8226; 3D Models - move and resize 3D assets, additional 3D assets
                    </p>
                    <p className="text-base leading-8 text-gray-300 mb-2 border-t border-zinc-700">
                      &#8226; Themes - more out-of-the-box themes to get up and running quickly with great looking Stories, as well as the ability to save your own.
                    </p>
                    <p className="text-base leading-8 text-gray-300 mb-2">
                      &#8226; Templates - create and save your own scene templates to reuse across Stories
                    </p> */}
                   
              

              </div>

            </div>
          </div>
        </div>
        {/* <AdminWelcomeStats /> */}
      </>


      {/* <CompHSChat></CompHSChat> */}



    </>
  )
}

export default Home