import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const Protected = ({ children }) => {
  const { user } = UserAuth();
  // if (!user) {
  //   return <Navigate to='/signin' />;
  // }

    if (user != null) {
      //console.log('User...')
      //console.log(user)
      if (user.accessToken) {
        //console.log('User is logged in')
        //navigate('/app/home')
        //return <Navigate to='/app/home' />;
      }
      else {
        Navigate('/signin')
        //return <Navigate to='/signin' />;
      }
    }
    else {
      //Navigate('/signin')
      return <Navigate to='/signin' />;
    }

  return children;
};

export default Protected;
