import React, { useEffect, useState } from 'react';
//import { firestore } from '../firebase'; // Adjust the path as necessary
//import { Firestore } from 'firebase/firestore';
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    setDoc
} from 'firebase/firestore'
import { db } from '../firebase'
import ModelAddForm from './ModelAddForm';

const handleDelete = (modelId) => {
    if (window.confirm("Are you sure you want to delete this model?")) {
        collection(db, "models").doc(modelId).delete();
    }
};

const ModelsList = () => {
    const [models, setModels] = useState([]);

    useEffect(() => {
        //const unsubscribe = collection("models").onSnapshot(snapshot => {
        const q = query(collection(db, 'models'));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newModels = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setModels(newModels);
        });

        return () => unsubscribe();
    }, []);

    return (
        <div className="-mt-12 h-screen overflow-y-auto admin-scroll pr-8  ">
            <ModelAddForm />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4  mb-24 mt-12">
                {models.map(model => (
                    <ModelCard key={model.id} model={model} />
                ))}
            </div>
        </div>
    );
};

const ModelCard = ({ model }) => {
    // Function to handle delete operation
    const handleDelete = (modelId) => {
        if (window.confirm("Are you sure you want to delete this model?")) {
            //collection("models").doc(modelId).delete();
            deleteDoc(doc(db, "models", modelId))
        }
    };

    return (
        <div className="bg-zinc-900 text-white p-6 rounded-lg shadow-lg">
            <div className='grid grid-cols-5 gap-2'>
                <div className="col-span-2">
                    <img src={"/media/thumbnail/" + model.guid.replace('.glb', '') + ".png"} alt={model.name} />
                </div>
                <div className="col-span-3">
                    <div className="my-2">
                        <h3 className="text-lg font-semibold">{model.name}</h3>
                        <p className="text-gray-300">{model.category}</p>
                        <div className='grid grid-cols-2 gap-4 mt-4'>
                            <div>
                                {/* <p>GUID: {model.guid}</p> */}
                                <p>{model.model}</p>
                                {/* <p>Access: {model.org.join(', ')}</p> */}
                            </div>
                            <div>
                                <p>Pos: {model.pos.x}, {model.pos.y}, {model.pos.z}</p>
                                <p>Scale: {model.scale}</p>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => handleDelete(model.id)} className="bg-red-900 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );

};

//

export default ModelsList;