import React from "react";
import { Canvas, extend, useThree, useLoader, useFrame } from "@react-three/fiber";
import { Cloud, Sky } from "@react-three/drei";
import { Suspense, useState, useRef, createContext, useContext, useEffect, useMemo } from "react";
import { CameraControls } from "./Camera";
//import { Html, useProgress, Loader, Grid } from '@react-three/drei'
import { useParams, useLocation } from "react-router-dom";
import ArrowKeysReact from 'arrow-keys-react';
import StoryEditOverlay from './components/StoryEditOverlay'
import AppStoryExperience from "./AppStoryExperience";
import { useNavigate } from 'react-router-dom';
//import anime, { set } from "animejs";
import { GetContentPosition } from "./components/UtilFunctions";
import * as THREE from 'three'
import { Water } from 'three-stdlib'
import ReactPlayer from "react-player";

//import QRCode from "react-qr-code";
import { CompHSChat } from "./components/CompHSChat";
//import CompModal from "./components/CompModal";
//import CompContact from './CompContact'

// import ExpTOC from "./experience/ExpTOC";
// import { Doughnut } from 'react-chartjs-2';

//import { useVideoPlayer } from "" // from "../events/bridge";
import { useVidListener, emitVid } from "./events/bridge";
//import { useOnChange, emitOnChange } from "./events/bridge";
import { useOnChangeListener, emitOnChange } from "./events/bridge";

import ExpHTMLEcoCard from "./experience/ExpHTMLEcoCard";

import { Offline, Online } from "react-detect-offline";

// /** @jsx jsx */  import { jsx } from '@emotion/core'
// import css from "@emotion/css";
//import styled from 'styled-components';

//import { css } from '@emotion/css'
//import { Perf } from 'r3f-perf'
import useScreenOrientation from 'react-hook-screen-orientation'

// Grab data for story
import { db } from '/firebase'
import {
  query,
  collection,
  onSnapshot,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  where,
  addDoc,
  getDocs,
  deleteDoc,
  serverTimestamp,
  increment,
} from 'firebase/firestore'

import NavigationTimelineSolo from "./components/NavigationTimelineSolo";
import NavigationTimelineMainSub from "./components/NavigationTimelineMainSub";
import NavigationProcessChevron2 from "./components/NavigationProcessChevron2";
//import { G } from "@react-pdf/renderer";

import BarLoader from "react-spinners/BarLoader";

import NavigationProcessChevron from "./components/NavigationProcessChevron";
// import { Group } from "three";
// import { on } from "events";

//import CircularProgress from 'react-native-circular-progress-indicator';
//import SessionContextProvider, {SessionContext} from "./context/SessionDataContext";
//import { CircularProgressBar } from "@tomickigrzegorz/react-circular-progress-bar";

export const SessionContext = createContext([])
export const SceneContext = createContext([])
export const StoryContext = createContext([])


const AppStoryPlayer = () => {

  //console.log('AppStoryPlayer...!!!')

  const { storyId } = useParams()
  const { shareCode } = useParams()

  const horizontalSpace = 40
  const cameraRef = useRef()
  const vidRef = useRef()
  const [videoPlaying, setVideoPlaying] = useState(true)
  const [muted, setMuted] = useState(false);


  const handleProgressClick = (event) => {
    const progressWrapper = document.getElementById('videoProgressWrapper');
    const clickPosition = event.clientX - progressWrapper.getBoundingClientRect().left;
    const clickRatio = clickPosition / progressWrapper.offsetWidth;
    const duration = vidRef.current.getDuration();
    const newTime = duration * clickRatio;
    vidRef.current.seekTo(newTime);
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  // Starting default positions
  //const [position, setPosition] = useState({ x: 0, y: 200, z: 600 })
  const [position, setPosition] = useState({ x: 0, y: 50, z: 600 })
  const [target, setTarget] = useState({ x: 0, y: 0, z: -10 })
  //const [notificationCount, setNotificationCount] = useState(0)

  // Keep for PC Story that uses a reposition on start of experience
  useEffect(() => {
    // Check for PC Story 
    if (storyId == 'nG5Zvkp1bTNP00eLZRI0') {
      //console.log('PC Story')
      setTimeout(() => {
        onChange(0)
      }, 1000)
    }
  }, [])

  // Try Useeffect on the following content
  const [sessionData, setSessionData] =
    useState(
      {
        activeIndex: 0,
        videoMode: 1,
        deliveryMode: 2,
        themeSkyConfig: '',
        modeVideo: 'fullscreen',
        themeGroundConfig: '/components/live-base-a.glb',
        playModeActive: 0,
        screenshotModeActive: false,
        quickScreenshotModeActive: false,
      }
    )


  // useEffect(() => {
  //   setSessionData({activeIndex: 0,
  //     videoMode: 1,
  //     deliveryMode: 2,
  //     themeSkyConfig: '',
  //     modeVideo: 'fullscreen',
  //     themeGroundConfig: '/components/live-base-a.glb',
  //     playModeActive: 0,
  //     screenshotModeActive: false,
  //     quickScreenshotModeActive: false,}
  //   ), []})    

  const [sceneData, setSceneData] = useState([])
  const [storyData, setStoryData] = useState([])
  const navigate = useNavigate()

  // Marker position
  //const [sectionActiveMarkerPosition, setSectionActiveMarkerPosition] = useState([0, -1, 0]);

  // Handle data load and timing with components 
  const [dataLoading, setDataLoading] = useState(true)
  const [storyLoaded, setStoryLoaded] = useState(false)
  const [sceneLoaded, setSceneLoaded] = useState(false)

  //const [playMode]


  window.addEventListener('error', console.log)
  //console.error('error') // has the same kind of attribute as the firebase message


  //const [vidProgress, setVidProgress] = useState(0)
  var vidProgress = 0
  var vidTotal = 0
  var videoElapsedTime = 0
  var progressMeter = 0;

  //useEffect(() => {
  const [showMediaPlayer, setShowMediaPlayer] = useState(true)

  //const ListenerComponent = () => {
  useVidListener((action) => {
    //    console.log(action.progress)

    if (action.length != undefined) {
      vidTotal = action.length
      //console.log('SET TOTAL LENGTH')
      //console.log(vidTotal)
      var videoDuration = new Date(vidTotal * 1000).toISOString().substring(14, 19)
      if (document.getElementById('vidDuration')) {
        document.getElementById('vidDuration').innerHTML = videoDuration;
        // document.getElementsByClassName('only-media').classList.add('animate__animated  animated__fadeIn animated__delay-1s')
        // document.getElementsByClassName('only-media').classList.remove('noshow')

        var els = document.getElementsByClassName('only-media')

        Array.from(els).forEach((el) => {
          // Do stuff here
          //console.log(el.tagName);

          //el.classList.add('noshow')
          el.classList.remove('noshow')
          el.classList.add('animate__animated')
        });
      }
      // noshow
      //setShowMediaPlayer(false)
    }
    else {
      // document.getElementsByClassName('only-media').classList.remove('animate__animated  animated__fadeIn animated__delay-1s')
      // document.getElementsByClassName('only-media').classList.add('noshow')
      var els = document.getElementsByClassName('only-media')

      Array.from(els).forEach((el) => {
        // Do stuff here
        //console.log(el.tagName);
        el.classList.remove('animate__animated')
        el.classList.remove('noshow')
      });
    }

    if (vidTotal > 0) {
      if (action.progress) {
        if (document.getElementById('vidElapsed')) {
          videoElapsedTime = new Date(action.progress * 1000).toISOString().substring(14, 19)
          document.getElementById('vidElapsed').innerHTML = videoElapsedTime || '';
          progressMeter = action.progress / vidTotal * 100;
        }
      }
    }

    const vidProgressElement = document.getElementById('vidProgress');
    if (vidProgressElement) {
      vidProgressElement.style.width = `${progressMeter}%`;
    }

  })


  useOnChangeListener((id) => {
    //console.log('GOT ID...!!!')
    // console.log('GOT ID...!!!')
    // console.log(id.id)
    //onChange(1)

    //setVideoPlaying(false)
    onChange(id.id + 0)

  })

  // Get data
  useEffect(() => {

    try {
      const unsub = onSnapshot(doc(db, 'stories', storyId), (doc) => {
        setStoryData(doc.data())
        // console.log('GOT DATA...!')
        // console.log(doc.data());
        setStoryLoaded(true)
        if (doc != undefined) {
          document.title = doc.data().name
          //unsub()
        }
      },
        (error) => {
          navigate('/404')
        })

      const q = query(collection(db, 'stories', storyId, 'scenes'), orderBy("order"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let sceneArr = []
        querySnapshot.forEach((doc) => {
          sceneArr.push({ ...doc.data(), id: doc.id })
        })
        //console.log('SCENE DATA')
        setSceneData(sceneArr)
        setSceneLoaded(true)
        //console.log(sceneArr)
      },
        (error) => {
        })
      return () => {
        unsubscribe()
      }

    } catch (error) {
      // console.error('###################### Error loading data... ######################');
      // console.error(error);
      // console.error('////////////////////// Error loading data... //////////////////////');
    }

  }, [])


  useEffect(() => {
    if (storyLoaded & sceneLoaded) {

      // Check for sharing mode
      if (location.pathname.includes('/share/')) {

        //const shareCode = location.pathname.split('/share/')[1]
        //const { shareCode } = useParams()

        if (shareCode) {
          //console.log('SHARE CODE: ' + shareCode)
          // storyId, userEmail, storyName, shareCode

          const storyName = storyData.name
          const userEmail = 'john@brevit3.com'
          const storyId = storyData.id
          const storyDescription = storyData.description
          const storyImage = storyData.previewThumb

          //storyId, userEmail, storyName, shareCode, storyDescription, storyImage

          try {
            const response = fetch('https://us-central1-brevit3-57b00.cloudfunctions.net/shareOpenNotification', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ storyId, userEmail, storyName, shareCode, storyDescription, storyImage }),
            });
            if (response.ok) {
            } else {
            }
          } catch (error) {
          }

        }
      }

      // Add one second to load...
      setTimeout(() => {

        if (storyId == 'nG5Zvkp1bTNP00eLZRI0') {
          //console.log('PC Story')
          setTimeout(() => {
            //console.log("Moved to new spot...!")
            onChange(0)
            setDataLoading(false)
          }, 1000)
        }
        else {
          setDataLoading(false)
        }
      }, 500)
    }
  }, [storyLoaded, sceneLoaded])



  // Will refresh each time scene is refreshed
  var start = Date.now();
  const [analyticsPackPrior, setAnalyticsPackPrior] = useState({})

  function onChange(idx = 0) {
    if (storyLoaded & sceneLoaded) {

      // Only run during sharing mode
      if (location.pathname.includes('/share/')) {

        var delta = Date.now() - start;
        // console.log('Prior scene info')
        // console.log(analyticsPackPrior)

        const analyticsPack = {
          storyId: storyData.id,
          storyName: storyData.name,
          sceneName: sceneData[idx].name,
          sceneId: sceneData[idx].id,
          sceneIndex: idx,
          sceneIdPrior: analyticsPackPrior.sceneIndex || 0,
          name: sceneData[idx].name,
          id: idx || 0,
          to: idx,
          from: analyticsPackPrior.sceneIndex || 0,
          engagementTime: Math.floor(delta / 1000), // Need new strategy to track time
          engagementTags: sceneData[idx].tags || [],
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
          userAgent: navigator.userAgent,
          createdAt: serverTimestamp(),
        }

        // console.log('SCENE CHANGE: ' + idx)
        // console.log(analyticsPack)

        // const addAnalytics = async () => {
        //   const analyticsRef = await addDoc(collection(db, 'analytics'),
        //     analyticsPack
        //   )
        // }
        // addAnalytics()

        // //analyticsPackPrior = analyticsPack
        // setAnalyticsPackPrior(analyticsPack)


      }

      var els = document.getElementsByClassName('only-media')

      Array.from(els).forEach((el) => {
        el.classList.add('noshow')
      });

      // Clean up buttons - select new active based on index
      // const allWithClass = Array.from(
      //   document.querySelectorAll('.cta-button-nav')
      // )
      var buttonIdx = 0

      // Starting values here
      //let position = { x: 10, y: 10, z: 10 };
      let position = { x: 10, y: 10, z: 10 };
      let target = { x: 0, y: 0, z: 0 };

      setSessionData(prevState => ({
        ...prevState,    // keep all other key-value pairs
        //name: 'something'       // update the value of specific key
        activeIndex: idx
      }))

      let xPos = 0
      let yPos = 60
      let zPos = 240
      let xTar = xPos
      let yTar = 15 // Vert camera position - higher / lower...
      let zTar = 20

      if (storyData) {


        // console.log('SCENE DETAILS TO LOAD....')
        // console.log(sceneData[idx])
        // console.log('/////////////////////////')

        if (idx < 0) {
          idx = 0;
        }

        // TODO - needs to account for hidden / background scenes....!!!!
        if (idx >= sceneData.length) {
          idx = sceneData.length - 1;
        }

        if (sceneData[idx]) {
          if (sceneData[idx].layout == 'manual' || sceneData[idx].layout == true) {
            //console.log('MANUAL LAYOUT')

            // Camera
            xPos = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0] + sceneData[idx].camOffset.position.x
            yPos = GetContentPosition(storyData, sceneData, idx, 0, yPos, 0)[1] + sceneData[idx].camOffset.position.y
            zPos = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2] + sceneData[idx].camOffset.position.z

            // Target
            xTar = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0] + sceneData[idx].camOffset.target.x
            yTar = GetContentPosition(storyData, sceneData, idx, 0, yTar, 0)[1] + sceneData[idx].camOffset.target.y
            zTar = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2] + sceneData[idx].camOffset.target.z
          }
          else {
            //console.log('layout... else...!!')
            // Horizontal
            xPos = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0]
            xTar = GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[0]

            // Vertical
            yPos = GetContentPosition(storyData, sceneData, idx, 0, yPos, 0)[1]
            yTar = GetContentPosition(storyData, sceneData, idx, 0, yTar, 0)[1]

            // Front / Back
            zPos += GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2]
            zTar += GetContentPosition(storyData, sceneData, idx, 0, 0, 0)[2]
          }
        }

      }

      // TODO - update to account for sidebar with video embed
      if (sessionData.modeVideo == 'pip') {
        xPos -= 8
        xTar -= 8
      }

      if (storyData.navigation == 'playlist-sidebar2') {
        xPos -= 6
        xTar -= 6
        zPos += 15
      }


      position = { x: xPos, y: yPos, z: zPos }
      target = { x: xTar, y: yTar, z: zTar }

      setPosition(position);
      setTarget(target);

      // Always run with video playing when moving to a new scene, just like YouTube
      if (storyData.navigation == 'playlist-sidebar2') {
        setVideoPlaying(true)

        if (document.querySelector("#scrollPlaylist")) {
          const scroller = document.querySelector("#scrollPlaylist");
          scroller.scrollTop = 44 * idx;
        }
      }

    }
  }

  const UploadStoryMap = async (file) => {
    const data = new FormData()
    data.append("file", file)
    data.append(
      "upload_preset",
      'qblr702c'
    )
    data.append("cloud_name", 'djdw2s79u')
    data.append("folder", "Cloudinary-React")

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/djdw2s79u/auto/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const res = await response.json();
      const storyRef = doc(db, 'stories', storyId)

      updateDoc(storyRef, {
        ...storyData,
        previewThumb: res.secure_url
      })
    } catch (error) {
      alert(error)
      //console.log(error)
    }
  }

  const exportRef = useRef();

  const Screenshot = () => {
    if (sessionData.screenshotModeActive) {
      const { gl, scene, camera } = useThree()
      gl.render(scene, camera)
      const file = gl.domElement.toDataURL('image/png');

      // UNCOMMENT FOR PRODUCTION
      UploadStoryMap(file)

      setSessionData(prevState => ({
        ...prevState,
        screenshotModeActive: false
      }))
    }
  }

  const QuickScreenshot = () => {
    if (sessionData.quickScreenshotModeActive) {
      const { gl, scene, camera } = useThree()
      gl.render(scene, camera)
      const file = gl.domElement.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = sceneData[sessionData.activeIndex].name + '.png';
      link.href = file;
      link.click();
      link.delete;
      setSessionData(prevState => ({
        ...prevState,
        quickScreenshotModeActive: false
      }))
    }
  }


  // ************************************************************
  // TODO - SENSE CHANGE IN INDEX AND RESPOND....
  // ************************************************************

  useEffect(() => {
    //setTimeout(() => {
    onChange(sessionData.activeIndex)
    //}, "5");
    //onChange(sessionData.activeIndex)
  }, [sessionData.activeIndex]);

  // ************************************************************
  // END TODO - SENSE CHANGE IN INDEX AND RESPOND....
  // ************************************************************


  // function Loader() {
  //   const { active, progress, errors, item, loaded, total } = useProgress()
  //   return <Html center>{progress} % loaded</Html>
  // }

  // const customStyles = {
  //   content: {
  //     top: '50%',
  //     left: '50%',
  //     right: 'auto',
  //     bottom: 'auto',
  //     marginRight: '-50%',
  //     transform: 'translate(-50%, -50%)',
  //     width: '30%',
  //     borderRadius: '12px',
  //     textAlign: 'center',
  //     border: '0px',
  //     overflow: 'hidden'
  //   },
  // };


  let movieOpen = 0

  const hideMovie = () => {
    if (movieOpen) {
      anime({
        targets: '.video-float',
        translateX: 0,
        easing: 'easeInOutExpo'
      });
      movieOpen = 0
    }
    else {
      anime({
        targets: '.video-float',
        translateX: -580,
        easing: 'easeInOutExpo'
      });
      movieOpen = 1
    }
  }


  useEffect(() => {
    if (sessionData.playModeActive == 1) {
      ArrowKeysReact.config({
        left: () => {
          sessionData.activeIndex > 0 ? onChange(sessionData.activeIndex - 1) : null
        },
        right: () => {
          //onChange(sessionData.activeIndex + 1)
          sessionData.activeIndex + 1 < sceneData.length ? onChange(sessionData.activeIndex + 1) : null
        },
        up: () => {
          //onChange(sessionData.activeIndex - 1)
          sessionData.activeIndex > 0 ? onChange(sessionData.activeIndex - 1) : null
        },
        down: () => {
          sessionData.activeIndex + 1 < sceneData.length ? onChange(sessionData.activeIndex + 1) : null
        }
      })
    }
    else {
      ArrowKeysReact.config({
        left: () => { },
        right: () => { },
        up: () => { },
        down: () => { }
      })
    }
  }, [sessionData])

  // else 
  // {
  //   ArrowKeysReact.config({
  //   })    
  // }

  const activateSidebar = (id) => {
    const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
    el1.classList.add('active');
    var accordionPanels = document.querySelectorAll('.accordion__panel');
    for (var i = 0; i < accordionPanels.length; i++) {
      var panel = accordionPanels[i];
      if (panel.getAttribute("data-itemguid") === id) {
        panel.removeAttribute("hidden");
      }
      else {
        panel.setAttribute("hidden", "");
      }
    }
    const buttons = document.querySelectorAll('.accordion__button');
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];
      if (button.getAttribute("data-itemguid") === id) {
        button.setAttribute('aria-expanded', 'true');
        button.classList.add('last-accordion-button');
      }
      else {
        button.setAttribute('aria-expanded', 'false');
        button.classList.remove('last-accordion-button');
      }
    }
  }



  const RenderNotifications = (currentScene) => {

    let notificationItem = []
    let sceneInfo = currentScene.currentScene

    if (sceneInfo != undefined) {
      if (sceneInfo.viznodes != undefined) {
        //console.log('Viznodes are avail')
        for (let i = 0; i < sceneInfo.viznodes.length; i++) {
          //console.log(sceneInfo.viznodes[i])
          if (sceneInfo.viznodes[i].id == 8) {
            //console.log(sceneInfo.viznodes[i])
            notificationItem.push(sceneInfo.viznodes[i])
          }
        }
      }
    }

    // // div.notification {
    // //   position: absolute;
    // //   bottom: 120px;
    // //   width: 30%;
    // //   right: 1rem;
    // //   color: #fff;
    // //   padding: 1.5rem 3rem;
    // //   border-radius: 0.5rem;
    // // }

    // const getPosition = (position) => {
    //   // switch (position) {
    //   //   case "Upper Right": return
    //   //     {
    //   //       backgroundColor: storyData.uiStyle['nav-region-bg'],
    //   //       left: '',
    //   //       right: '',
    //   //       top: '',
    //   //       bottom: ''
    //   //     }
    //   //   case "Upper Left": return entry.type;
    //   //   case "Lower Left": return entry.type;
    //   //   case "Lower Right": return entry.type;
    //   //   case "Center": return entry.type;
    //   //   default: ''; //return entry.name;
    //   // }
    // }


    return (
      notificationItem.map((vizItem, index) =>
        <div
          className={
            "notification rounded animate__animated animate__fadeIn animate__delay-2s " + vizItem.position.replace(' ', '-').toLowerCase()
          }
          key={vizItem.id + '-notification'}
          onClick={() => activateSidebar(vizItem.guid)}
          style={{
            backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : ''}`,
          }}
        >
          {/* <div className="text-gray-300 text-xl">{vizItem.position}</div> */}
          <div dangerouslySetInnerHTML={{ __html: vizItem.content }}></div>
        </div>
      )
    )
  }


  useEffect(() => {
    // console.log('SESSION DATA')
    // console.log(sessionData.playModeActive)

    if (sessionData.playModeActive == 1) {

    }

  }, [sessionData.playModeActive])



  const TogglePlayMode = () => {
    //console.log('GOT TOGGLE.... playing now')
    if (sessionData.playModeActive) {
      anime({
        targets: '.scene-edit-overlay.left',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 1000
      });
      anime({
        targets: '.scene-edit-overlay.right',
        translateX: 0,
        easing: 'easeInOutExpo',
        delay: 1000
      });

      anime({
        targets: '#playModeNavigation',
        translateY: 0,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '#panel-story-control',
        translateY: 0,
        easing: 'easeInOutExpo',
        duration: 1000
      });

      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 0
      }))
    }
    else {
      //console.log('GOT TOGGLE.... CLOSE!')
      anime({
        targets: '.scene-edit-overlay.left',
        translateX: -340,
        easing: 'easeInOutExpo'
      });
      anime({
        targets: '.scene-edit-overlay.right',
        translateX: 340,
        easing: 'easeInOutExpo'
      });

      let navDelay = 1000

      if (!editMode) {
        navDelay = 3000
      }

      anime({
        targets: '#playModeNavigation',
        translateY: -170,
        easing: 'easeInOutExpo',
        delay: navDelay
      });

      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 1
      }))
    }
  }






  const RenderNavigation = (sceneData) => {



    return (
      <>
        {storyData.navigation == 'nav-mfg' ?
          <NavigationProcessChevron updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} ></NavigationProcessChevron>
          : (null)
        }

        {/* {storyData.navigation == 'nav1' ?
          <NavigationProcessChevron2 updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} />
          : (null)
        } */}
        {storyData.navigation == 'nav2' ?
          <>
            <NavigationTimelineMainSub updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} ></NavigationTimelineMainSub>
            {/* <div className="navigation-progress-wrapper">
              <div className="navigation-progress-meter" style={
                'width: ' + sessionData.activeIndex / sceneData.length * 100 + '%'
              } id="navigation-progress-meter"></div>
            </div> */}
          </>
          : (null)
        }
        {storyData.navigation == 'nav3' ?
          <NavigationTimelineSolo updateSceneFunction={onChange} TogglePlayMode={TogglePlayMode} ></NavigationTimelineSolo>
          : (null)
        }
        {storyData.navigation == 'nav4' ?
          <div className="navigation-segment scene-details">
            {sceneData[sessionData.activeIndex] ?
              <div className="nav-single-major">
                <div className="nav-heading">{sceneData[sessionData.activeIndex].name}</div>
                <div className="nav-subheading">The best Lorem Ipsum Generator in all the sea! Heave this scurvy copyfiller fer yar next adventure and cajol yar clients into walking the plank with ev'ry layout.</div>
                <div className="navigation-progress-wrapper">
                  <div className="navigation-progress-meter" style={
                    'width: ' + sessionData.activeIndex / sceneData.length * 100 + '%'
                  } id="navigation-progress-meter"></div>
                </div>
              </div>
              : (null)
            }
          </div>
          : (null)
        }
        {storyData.navigation == 'nav5' ?
          <div className="flex" id="interaction-wrapper">
            {sceneData.map((scene, index) => (
              <div
                key={scene.id}
                onClick={() => {
                  props.updateSceneFunction(index)
                }}
                className={`grow h-12 timeline-nav ${sessionData.activeIndex == index ? 'live-nav-on' : ''}`} >
                {scene.name}
              </div>
            ))}
          </div>
          : (null)
        }
      </>)
  }

  let editMode = 0
  let location = useLocation()

  if (!location.pathname.includes('/play/') && !location.pathname.includes('/share/')) {
    editMode = 1
  }

  useEffect(() => {

    // FRIENDLY URL --- HERE FOR PATH TO UPDATE!!!
    if (!location.pathname.includes('/play/') && !location.pathname.includes('/share/')) {
      editMode = 1
    } else {
      // Activate arrow keys
      setSessionData(prevState => ({
        ...prevState,
        playModeActive: 1
      }))
    }

    if (editMode != 1) {
      // Update analytics...
      //console.log('UPDATE ANALYTICS')

      // const updateAnalytics = async () => {
      //   const storyRef = doc(db, 'stories', storyId)
      //   //console.log('NEW VIEW COUNT')
      //   //console.log(storyData.analyticsViewTotal)

      //   const res = await updateDoc(storyRef, {
      //     ...storyData,
      //     analyticsViewTotal: increment(1),
      //     analyticsLastViewed: serverTimestamp(),
      //   })

      // }
      // updateAnalytics()

      // const addAnalytics = async () => {
      //   const analyticsRef = await addDoc(collection(db, 'stories', storyId, 'analytics'), {
      //     storyId: storyId,
      //     createdAt: serverTimestamp(),
      //   })
      // }
      // addAnalytics()
    }

  }, [])

  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState("#0ca5e9")

  const override = {
    margin: "auto",
    position: "relative",
  }





  extend({ Water })

  //  function Ocean() {
  const Ocean = (props) => {
    const ref = useRef()
    const gl = useThree((state) => state.gl)
    const waterNormals = useLoader(THREE.TextureLoader, '/waternormals.jpeg')
    waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping
    const geom = useMemo(() => new THREE.PlaneGeometry(10000, 10000), [])
    const config = useMemo(
      () => ({
        textureWidth: 512,
        textureHeight: 512,
        waterNormals,
        sunDirection: new THREE.Vector3(),
        sunColor: 0xffffff,
        waterColor: 0x064273,
        distortionScale: 3.7,
        fog: false,
        format: gl.encoding
      }),
      [waterNormals]
    )
    useFrame((state, delta) => (ref.current.material.uniforms.time.value += delta / 2))
    return <water ref={ref} args={[geom, config]} position={props.position} rotation-x={-Math.PI / 2} />
  }



  const NavMobile = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}
        < div className={`fixed inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0   shadow-lg rounded-md lg:shadow-none overflow-hidden`
        }
          style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : null}` }
          }
        >
          <div className="flex flex-col lg:flex-row w-full">
            {/* Logo */}
            <div className="flex justify-between items-center p-4   lg:w-64">
              {storyData.imageStoryLogo != undefined && (
                <img src={storyData.imageStoryLogo} className="min-h-12  max-h-14" />
              )}
              <button onClick={toggleMobileMenu} className="lg:hidden">
                <svg className="w-6 h-6 text-gray-800" /* SVG for Close Icon */ />
              </button>
            </div>

            {/* Navigation Items */}

            <div className={`p-4  ${isMobileMenuOpen ? 'flex-grow' : 'w-full flex'}`}>

              {sceneData.filter(item => !item.navOmitMain).map((step, stepIdx) => (
                <div
                  role="button"
                  className={`bg-gray-100 hover:bg-gray-200 rounded p-2 
                  transition duration-300 ${isMobileMenuOpen ? 'w-auto text-md mb-1' : 'flex text-lg mr-4'} 
                  ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ' '}
                  `}
                  key={stepIdx + '-nav'}
                  onClick={() => onChange(stepIdx)}
                  style={{
                    backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                    color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                    borderBottom: sessionData.activeIndex == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                  }}
                >
                  {step.name}
                </div>
              ))}
            </div>

            {/* Optional Close Button */}
            {(!location.pathname.includes('/play/')) && (
              <div className="p-4">
                {!isMobileMenuOpen ?
                  <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                    setSessionData(prevState => ({
                      ...prevState,
                      playModeActive: 0
                    }))
                  }}>
                    Close
                  </button>
                  : <div className="h-20"></div>
                }
              </div>
            )}
          </div>
        </div >
      </>
    );
  };





  const NavDots = ({ storyData, sceneData, sessionData, onChange, TogglePlayMode }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };

    //ListenerComponent()

    return (
      <>
        {/* Mobile Menu Icon */}
        <div className="fixed bottom-4 right-4 z-50 lg:hidden rounded-md ">
          <button onClick={toggleMobileMenu}
            //className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 transition duration-300"
            className="flex flex-col justify-center items-center w-20 h-20 rounded-md"
            style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-bg-on'] : null}` }}
          >
            <>
              <span className={` block transition-all duration-300 ease-out 
                    h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  'rotate-45 translate-y-1.5' : '-translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out h-1 w-12 rounded-sm my-0.5 ${isMobileMenuOpen ?
                'opacity-0' : 'opacity-100'}`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
              <span className={` block transition-all duration-300 ease-out
              h-1 w-12 rounded-sm ${isMobileMenuOpen ?
                  '-rotate-45 -translate-y-1.5' : 'translate-y-1'
                }`}
                style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-button-text-on'] : null}` }}
              >
              </span>
            </>
          </button>
        </div >

        {/* Main Navigation */}
        < div className={`fixed inset-x-0 bottom-0 transform transition-all duration-500 ${isMobileMenuOpen ? 'translate-y-0' : 'translate-y-full'} lg:static lg:translate-y-0   shadow-lg rounded-md lg:shadow-none overflow-hidden`
        }
          style={{ backgroundColor: `${storyData.uiStyle ? storyData.uiStyle['nav-region-bg'] : null}` }
          }
        >
          <div className="flex flex-col lg:flex-row w-full">
            {/* Logo */}
            <div className="flex justify-between items-center p-4   lg:w-64">
              {storyData.imageStoryLogo != undefined && (
                <img src={storyData.imageStoryLogo} className="min-h-12" />
              )}
              <button onClick={toggleMobileMenu} className="lg:hidden">
                <svg className="w-6 h-6 text-gray-800" /* SVG for Close Icon */ />
              </button>
            </div>

            {/* Navigation Items */}

            <div className={`p-4  ${isMobileMenuOpen ? 'flex-grow' : 'w-full flex text-center'}`}>

              {sceneData.filter(item => !item.navOmitMain).map((step, stepIdx) => (
                <div
                  role="button"
                  className={`bg-gray-100 hover:bg-gray-200 rounded p-2 
                  transition duration-300 ${isMobileMenuOpen ? 'w-auto text-md mb-1' : 'w-12 mx-auto text-lg mr-4'} 
                  ${storyId == 'nG5Zvkp1bTNP00eLZRI0' ? 'text-sm' : ' '}
                  `}
                  key={stepIdx + '-nav'}
                  onClick={() => onChange(stepIdx)}
                  style={{
                    backgroundColor: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-bg-on'] : storyData.uiStyle['nav-button-bg-off'],
                    color: sessionData.activeIndex == stepIdx ? storyData.uiStyle['nav-button-text-on'] : storyData.uiStyle['nav-button-text-off'],
                    borderBottom: sessionData.activeIndex == stepIdx ? '4px solid ' + storyData.uiStyle['nav-progress-meter-bg'] : '4px solid ' + storyData.uiStyle['nav-progress-meter']
                  }}
                >
                  {/* {step.name} */}
                </div>
              ))}
            </div>

            {/* Optional Close Button */}
            {(!location.pathname.includes('/play/')) && (
              <div className="p-4">
                {!isMobileMenuOpen ?
                  <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                    setSessionData(prevState => ({
                      ...prevState,
                      playModeActive: 0
                    }))
                  }}>
                    Close
                  </button>
                  : <div className="h-20"></div>
                }
              </div>
            )}
          </div>
        </div >
      </>
    );
  };



  //export default YourComponent;

  const ShowChapters = () => {
    const [open, setOpen] = useState(true)

    return (
      <Transition show={open}>
        <Dialog className="relative z-10" onClose={setOpen}>
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <TransitionChild
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Payment successful
                      </DialogTitle>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => setOpen(false)}
                    >
                      Go back to dashboard
                    </button>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  }



  //   const getFormattedTime = (given_seconds) => {
  //     // function convertSecondstoTime() {
  //     //   given_seconds = 3685;

  //       const dateObj = new Date(given_seconds * 1000);
  //       hours = dateObj.getUTCHours();
  //       minutes = dateObj.getUTCMinutes();
  //       seconds = dateObj.getSeconds();

  //       timeString = hours.toString().padStart(2, '0')
  //           + ':' + minutes.toString().padStart(2, '0')
  //           + ':' + seconds.toString().padStart(2, '0');

  //       return timeString;
  // }

  function getFormattedTime(time) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + String(mins).padStart(2, '0') + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }


  const [isModalTOCOpen, setIsModalTOCOpen] = useState(false);
  const openTOCModal = () => setIsModalTOCOpen(true);
  const closeTOCModal = () => setIsModalTOCOpen(false);


  const [mediaIsPlaying, setMediaIsPlaying] = useState(true)

  const showTOC = () => {
    var els = document.getElementsByClassName('only-toc')
    Array.from(els).forEach((el) => {
      //el.classList.add('animate__animated')
      //el.classList.add('animate__fadeInUp')
      el.classList.remove('noshow')
    });
  }

  const closeTOC = () => {
    var els = document.getElementsByClassName('only-toc')
    Array.from(els).forEach((el) => {
      //el.classList.remove('animate__fadeInUp')
      //el.classList.add('animate__fadeOutDown')
      el.classList.add('noshow')
    });
  }

  const startExperience = () => {
    // Used to ensure that the audio / video starts when the user begins the experience
    onChange(1)
    setTimeout(() => {
      onChange(0)
    }, 1)
  }


  // const DurationChart = (sessionTime) => {

  //   const totalTime = 60 - sessionTime

  //   const data = {
  //     //labels: ['Value 1', 'Value 2'],
  //     datasets: [
  //       {
  //         data: [sessionTime, totalTime], // You can adjust these values as needed
  //         //data: [20, 50],
  //         // backgroundColor: ['#FF6384', '#36A2EB'],
  //         // hoverBackgroundColor: ['#FF6384', '#36A2EB'],
  //         backgroundColor: [
  //           'rgb(0, 197, 0)',
  //           '#000'
  //         ],
  //         borderWidth: 0,
  //         //radius: '40%'   
  //       }]
  //   };

  //   return (
  //     <Doughnut data={data} />

  //   );
  // };

  // const AnalogueClock = ({ hours, color = 'black', size = 200 }) => {
  //   const handRef = useRef(null);

  //   useEffect(() => {
  //     if (handRef.current) {
  //       const angle = (hours % 12) * 30; // Convert hours to degrees (0-360)
  //       handRef.current.animate([
  //         { transform: 'rotate(0deg)', offset: 0 },
  //         { transform: `rotate(${angle}deg)`, offset: 1 }
  //       ], {
  //         duration: 2000, // 2 seconds
  //         easing: 'ease-in-out',
  //         fill: 'forwards'
  //       });
  //     }
  //   }, [hours]);

  //   return (
  //     <svg width={size} height={size} viewBox="0 0 100 100">
  //       <circle cx="50" cy="50" r="48" stroke={color} strokeWidth="2" fill="none" />
  //       <line ref={handRef} x1="50" y1="50" x2="50" y2="15" stroke={color} strokeWidth="2" strokeLinecap="round" />
  //     </svg>
  //   );
  // };


  const [showWelcomeModal, setShowWelcomeModal] = useState(true)

  const ModalWelcome = () => {

    // available control variables
    const props = {
      percent: 13, // is require
      //colorSlice: "#00a1ff",
      colorCircle: "#111",
      fontColor: "#365b74",
      fontSize: "1.6rem",
      fontWeight: 400,
      size: 150,
      stroke: 10,
      //strokeBottom: 5,
      speed: 10,
      cut: 0,
      animationSmooth: '500ms ease-out',
      rotation: -90,
      //fill: "#000",
      unit: "%",
      textPosition: "0.35em",
      animationOff: false,
      //strokeDasharray: "10,1",
      inverse: false,
      round: true,
      number: false,
      linearGradient: ["#ffff00", "brown"],
      styles: {
        borderRadius: "50%",
        //boxShadow: "inset 0 0 25px 10px #a2caff"
      }
    }

    return (
      <>
        {/* <button
          className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => setShowModal(true)}
        >
          Open regular modal
        </button> */}
        {showWelcomeModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 xs:my-0 sm:my-0 md:my-0 mx-auto max-w-5xl  animate__animated animate__fadeIn">
                {/*content*/}
                <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-black/20 outline-none focus:outline-none">
                  {/*header*/}

                  {screenOrientation === 'landscape-primary' ?
                    <div className="bg-zinc-800 w-full p-9 
                  xs:p-4 sm:p-4 md:p-4 lg:p-4 xl:p-9
                  xs:pl-9 sm:pl-9 md:pl-9 lg:pl-9
                  rounded-t-md">
                      <div className="text-3xl 
                    xs:text-xl sm:text-2xl md:text-2xl lg:text-2xl
                    xss:text-lg
                    xss:mt-10 xs:mt-10 md:mt-0 lg:mt-0 xl:mt-0 mt-0
                    font-base text-gray-50 flex">
                        <div className="flex-auto">
                          {storyData.name}
                        </div>
                        <div className="flex-none">
                          <img src="/media/icon/b3-created-with.png" className="inline-block h-8
                        xs:h-6 sm:h-6 md:h-6 lg:h-8
                        self-end" />
                        </div>
                      </div>
                    </div>
                    :
                    <div className="bg-zinc-800 w-full p-9 
                  xs:p-4 sm:p-4 md:p-4 lg:p-4 xl:p-9
                  xs:pl-9 sm:pl-9 md:pl-9 lg:pl-9
                  mt-16
                  rounded-t-md">
                      <div className="text-3xl 
                    xs:text-xl sm:text-2xl md:text-2xl lg:text-2xl
                    xss:text-lg
                    font-base text-gray-50 flex">
                        <div className="flex-auto">
                          {storyData.name}
                          <img src="/media/icon/b3-created-with.png" className="inline-block h-8
                        xs:h-6 sm:h-6 md:h-6 lg:h-8
                        self-end" />
                        </div>
                      </div>
                    </div>
                  }


                  {screenOrientation === 'landscape-primary' ?
                  <>
                    <div className="flex items-start justify-between p-5  ">
                      <div className="relative p-4 flex-auto mr-6
                    
                    xs:mr-0 sm:mr-0 md:mr-0 lg:mr-6
                    ">
                        <div className="text-zinc-400 text-lg xs:text-sm sm:text-base leading-8">
                          Welcome!  <br /><br />This presentation runs on our no-code Brevit3 visual storytelling platform - designed to deliver stories that illustrate people, process and technology for sales, marketing  enablement.
                          <br /><br />
                          {/* <img src="https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fb3-screen-group.png?alt=media&token=f302ce6a-3cb4-4af1-a17e-215df610dad9"
                          className="w-full animate__animated animate__fadeIn h-56 animate__delay-1s"
                        />
                        <br /> */}
                          {/* We hope you enjoy the experience and look forward to hearing from you.<br /><br /> */}
                          Sincerely,<br />
                          Brevit3 Team



                          <img src="/util/app/b3-screen-rotate.svg" className="w-24
                        xxs:block xs:hidden sm:hidden 
                        md:hidden lg:hidden xl:hidden 
                        " />

                        </div>
                      </div>
                      <div className="relative p-8 ml-4 flex-none bg-black/50 text-center h-fit 
                    w-56 
                    xs:w-36 sm:w-36 md:w-36 lg:w-56
                    mt-4 rounded-md">
                        <div className="text-zinc-400 text-xl">
                          Viewing Time
                        </div>
                        {/* <DurationChart sessionTime={7} /> */}

                        {/* <CircularProgressBar {...props} /> */}
                        {/* https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fin-screen-demo-1080.mov?alt=media&token=ec84646e-5c10-4a16-ac4b-c17f95f58c14
                      https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fin-screen-demo-1080.mov?alt=media */}
                        <div className="text-zinc-200 text-4xl font-bold mt-4">
                          6:57
                        </div>
                      </div>

                    </div>
                    <div className="m-8 mb-8 
                  xs:mt-0 sm:mt-0 md:mt-0  
                  xs:mb-4 sm:mb-4 md:mb-4 lg:mb-8
                  relative mt-6 p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                    onClick={() => {
                      startExperience()
                      setShowWelcomeModal(false)
                    }}>
                    <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                    <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                      <span className="relative text-white text-lg">Start Experience</span>
                    </span>
                  </div>
                    </>
                    :
                    <div className="flex items-start justify-between p-5  ">
                      <div className="relative p-4 flex-auto mr-6
                    xs:mr-0 sm:mr-0 md:mr-0 lg:mr-6">
                        <div className="text-zinc-400 text-lg xs:text-sm sm:text-base leading-8">
                          <img src="/util/app/b3-screen-rotate.svg" className="w-24 mx-auto" />
                          <br /><br />
                          Please rotate your device to the side to view this experience.  Thank you!
                        </div>
                      </div>
                    </div>
                  }





                  
                </div>
              </div>
            </div>
            <div className=" fixed inset-0 z-40 bg-zinc-900"></div>
          </>
        ) : null}
      </>
    )
  }




  //  const [showOfflineModal, setShowOfflineModal] = useState(false)

  const ModalOffline = () => {

    return (
      <>
        {showWelcomeModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0  outline-none focus:outline-none"
              style={{ zIndex: 1000 }}
            >
              <div className="relative w-auto my-6 mx-auto max-w-3xl  animate__animated animate__fadeInUp animate__delay-2s">
                <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-zinc-900 outline-none focus:outline-none">
                  <div className="bg-zinc-800 w-full p-9 rounded-t-md">
                    <div className="text-3xl font-base text-gray-50 flex">
                      <div className="flex-auto">
                        You are Offline
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-between p-5  ">
                    <div className="relative p-4 flex-auto">
                      <div className="text-gray-200 text-lg leading-8">
                        You are currently offline.  Try connecting to the internet and refreshing your browser.
                      </div>
                    </div>
                  </div>

                  <div className="m-8 relative mt-12 p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                    onClick={() => {
                      window.location.reload()
                    }}>
                    <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                    <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                      <span className="relative text-white text-lg">Refresh</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black"
              style={{ zIndex: 999 }}
            ></div>
          </>
        ) : null}
      </>
    )
  }


  const screenOrientation = useScreenOrientation()

  const [showDocumentModal, setShowDocumentModal] = useState(false)

  const ModalDocuments = () => {


    const documentData = [
      {
        name: 'Brevit3 PDF Pitch Deck',
        link: 'https://drive.google.com/file/d/1uukCbaQsiKLqLbq7ZTE3j2WcJSCluQAS/view?usp=drive_link',
        icon: 'https://www.google.com',
        summary: 'Traditional pitch deck in PDF format.  Includes additional GTM details beyond what is shared in the interactive version that is running on our platform.',
        time: '10 min',
        previewImage: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2Fb3-pitch-deck-cover.png?alt=media',
      },
      {
        name: 'eBook - Remarkable Demo Blueprint',
        link: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2FRemarkable_Demo_Blueprint_Brunswick.pdf?alt=media&token=24aba82c-a9a3-4094-9216-62e9c256fb7d',
        icon: 'https://www.google.com',
        summary: 'This eBook is filled with high value content for our ICP.  It is a comprehensive guide to creating a remarkable demo - covering strategies to boost relevancy and engagement.  It includes a step-by-step guide to creating a demo that will leave a lasting impression.',
        time: '2 hours',
        previewImage: 'https://firebasestorage.googleapis.com/v0/b/brevit3-57b00.appspot.com/o/public%2FBook-Cover-RDB-Remarkable-Demo-Blueprint-2.png?alt=media',
      },
    ]


    return (
      <>
        {showDocumentModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-auto my-6 mx-auto max-w-5xl  animate__animated animate__fadeInUp ">
                {/*content*/}
                <div className="border-0 rounded-md shadow-lg relative flex flex-col w-full bg-zinc-900 outline-none focus:outline-none">
                  {/*header*/}

                  <div className=" w-full p-9 rounded-t-md">
                    <div className="text-3xl font-base text-gray-50 flex">
                      <div className="flex-auto">
                        Resources - eBook & Pitch Deck
                      </div>
                      <div className="flex-none">
                        <img src="/apple-touch-icon.png" className="inline-block h-8 w-8 self-end" />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-start justify-between p-5  ">

                    <div className="text-gray-200 text-lg leading-8">
                      {documentData.map((doc, index) => (
                        <div className="w-full  bg-black/25 p-6 rounded-md mb-6" key={doc.name + '' + index}>

                          <div className="flex  ">
                            <div className="flex-none w-44 h-44 bg-cover bg-center bg-no-repeat rounded-md" style={{ backgroundImage: `url(${doc.previewImage})` }}></div>
                            <div className="flex-auto ml-6 mr-6">
                              <div className="text-2xl text-gray-50">
                                {doc.name}
                              </div>
                              <div className="text-gray-400 text-base mt-2">
                                {doc.summary}
                              </div>
                            </div>
                            <div className="">
                              {/* <div className="w-64 bg-black/25 rounded-md border-1 border-zinc-300 p-2 cursor-pointer">
                                <img src="/util/app/b3-admin-download.svg" className="inline w-8 " /> download
                              </div> */}
                              <a class="bg-black/50 hover:bg-zinc-800 text-gray-100 py-2 px-4 rounded inline-flex items-center"
                                target="_blank"
                                href={doc.link}
                              >
                                <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
                                <span>Download</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>

                  </div>

                  <div className="mx-8 relative   p-0.5 inline-flex items-center justify-center font-bold overflow-hidden group rounded-md"
                    onClick={() => {
                      setShowDocumentModal(false)
                    }}>
                    <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                    <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                      <span className="relative text-white text-lg">Close</span>
                    </span>
                  </div>


                </div>
              </div>
            </div>
            <div className="opacity-75 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    )
  }


  if (dataLoading) {
    return (
      <div id="full-loader">
        <div className="flex h-screen">
          <div className="m-auto">
            <img src="/media/icon/Brevit3-Logo-Home.png" className="-mt-20" />
            <BarLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={250}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </div>
      </div>
    )
  }
  else {

    return (
      <>
        <SceneContext.Provider value={[sceneData, setSceneData]}>
          <StoryContext.Provider value={[storyData, setStoryData]}>

            <SessionContext.Provider value={[sessionData, setSessionData]}>
              {/* <SessionContextProvider> */}
              <div {...ArrowKeysReact.events} tabIndex="1">

                <div id="main-stage-wrapper">
                  <div id="main-stage" ref={exportRef} className={storyData ? storyData.backgroundSky : ''}>
                    <Canvas style={{ height: "100vh", outline: "none" }}
                      id="canvas"
                      antialias={'false'}
                      colormanagement='true'
                      flat
                      camera={{
                        fov: 10,
                        near: 1,
                        far: 1400,
                        position: [0, 120, 600],
                        zoom: sessionData.modeVideo === 'newscaster' ? 0.5 : 1
                      }}>
                      <CameraControls position={position} target={target}
                        cameraOrbitOn={storyData.cameraOrbitOn}
                      />
                      {sceneLoaded & storyLoaded ?
                        <AppStoryExperience storyData={storyData}></AppStoryExperience>
                        : (null)}
                      {storyData.backgroundModel == 'background_ocean' ?
                        <group>
                          <Ocean position={[0, storyData.backgroundHeight, 0]} />
                          <Sky scale={5000} turbidity={0.1} />
                        </group>
                        : null
                      }

                      {storyData.backgroundSkyCloud == 'lightCloud' ?
                        <group>
                          <Cloud position={[-80, 22, -85]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[-40, 18, -80]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[0, 16, -75]} speed={0.2} opacity={.5} />
                          <Cloud position={[42, 17, -65]} speed={0.2} opacity={0.5} />
                          <Cloud position={[92, 22, -60]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                        </group>
                        : null
                      }

                      {storyData.backgroundSkyCloud == 'heavyCloud' ?
                        <group>
                          <Cloud position={[-80, 22, -85]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[-40, 18, -80]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[-45, 21, -72]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[0, 16, -75]} speed={0.2} opacity={.5} />
                          <Cloud position={[42, 17, -65]} speed={0.2} opacity={0.5} />
                          <Cloud position={[37, 23, -65]} speed={0.2} opacity={0.5} />
                          <Cloud position={[68, 25, -85]} speed={0.2} opacity={0.5} />
                          <Cloud position={[92, 22, -60]} speed={0.2} opacity={.5} scale={[2, 2, 2]} />
                          <Cloud position={[112, 18, -65]} speed={0.2} opacity={0.5} />
                        </group>
                        : null
                      }
                      {storyData.backgroundSkyCloud == 'heavyCloudBackUp' ?
                        <group>
                          <Cloud position={[-110, 32, -125]} speed={0.2} opacity={.5} scale={[3, 3, 3]} />
                          {/* <Cloud position={[-90, 28, -140]} speed={0.2} opacity={.5} scale={[3, 3, 3]} /> */}
                          <Cloud position={[-55, 31, -92]} speed={0.2} opacity={.5} scale={[4, 4, 4]} />
                          <Cloud position={[0, 26, -105]} speed={0.2} opacity={.5} />
                          <Cloud position={[42, 27, -115]} speed={0.2} opacity={0.5} />
                          <Cloud position={[37, 33, -105]} speed={0.2} opacity={0.5} scale={[2, 2, 2]} />
                          <Cloud position={[68, 35, -95]} speed={0.2} opacity={0.5} />
                          <Cloud position={[92, 32, -100]} speed={0.2} opacity={.5} scale={[3, 3, 3]} />
                          <Cloud position={[112, 38, -105]} speed={0.2} opacity={0.5} />
                        </group>
                        : null
                      }

                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[-80, 38, -85]} speed={0.2} opacity={.5} scale={[2, 2, 2]} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[-40, 36, -80]} speed={0.2} opacity={.5} scale={[2, 2, 2]} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[0, 30, -75]} speed={0.2} opacity={.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[42, 32, -65]} speed={0.2} opacity={0.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[68, 44, -85]} speed={0.2} opacity={0.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[92, 38, -60]} speed={0.2} opacity={.5} scale={[2, 2, 2]} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[112, 32, -65]} speed={0.2} opacity={0.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <Cloud position={[-126, 40, -65]} speed={0.2} opacity={.5} /> :
                        null}
                      {storyData.id == 'NHI5HkRTYmIfKJWjAk6B' || storyData.id == 'ShVvsrQlDQducMHTQE7m' ?
                        <>
                          <Cloud position={[5, -80, 65]} speed={0.2} opacity={.5} />
                          <Cloud position={[-46, -80, 55]} speed={0.2} opacity={.5} />
                        </> :
                        null}

                      <perspectiveCamera
                        ref={cameraRef}
                        radius={(1200 + 600) / 4}
                        fov={35}
                        position={[-20, 40, 120]}
                        lookAt={[0, -30, 0]}
                        zoom={2}
                      />

                      {sessionData.screenshotModeActive ?
                        <Screenshot></Screenshot>
                        : (null)
                      }
                      {sessionData.quickScreenshotModeActive ?
                        <QuickScreenshot></QuickScreenshot>
                        : (null)}

                    </Canvas>


                    <RenderNotifications currentScene={sceneData[sessionData.activeIndex]}></RenderNotifications>

                    <ExpHTMLEcoCard
                      currentScene={sceneData[sessionData.activeIndex]}
                      thisIndex={sessionData.activeIndex}
                      updateSceneFunction={onChange}
                    ></ExpHTMLEcoCard>

                    <Offline>
                      <ModalOffline />
                    </Offline>

                  </div>
                </div>

                {!editMode && storyData.navigation == 'nav-mfg' ?
                  <div id="playModeNavigation" className='navigation-bar navigation-bottom mode-edit'
                    style={{ bottom: '-20px' }}
                  >
                    <RenderNavigation></RenderNavigation>
                  </div>
                  : (null)}

                {!editMode && storyData.navigation != 'nav-mfg' && storyData.navigation != 'playlist' && storyData.navigation != 'playlist-sidebar2' && storyData.navigation != 'dots' ?
                  <div
                    id="playModeNavigation"
                    className=" navigation-bar navigation-bottom mode-public animate__animated animate__fadeInUp animate__delay-3s"
                  >
                    <NavMobile
                      storyData={storyData}
                      sceneData={sceneData}
                      sessionData={sessionData}
                      location={location}
                      onChange={onChange}
                      TogglePlayMode={TogglePlayMode}
                    />
                  </div>
                  : (null)}

                {editMode && storyData.navigation == 'dots' ?
                  <div
                    id="playModeNavigation"
                    className=" navigation-bar navigation-bottom mode-public animate__animated animate__fadeInUp animate__delay-3s"
                  >
                    <NavDots
                      storyData={storyData}
                      sceneData={sceneData}
                      sessionData={sessionData}
                      location={location}
                      onChange={onChange}
                      TogglePlayMode={TogglePlayMode}
                    />
                  </div>
                  : (null)}


                {editMode ?
                  <>
                    <StoryEditOverlay
                      updateSceneFunction={onChange}
                      TogglePlayMode={TogglePlayMode}
                    />
                    {/* <div id="playModeNavigation" className='navigation-bar navigation-bottom mode-edit'>
                      <RenderNavigation sceneData={sceneData}></RenderNavigation>
                    </div> */}
                  </>
                  :
                  (null)
                }





                {/* {showTOC ?
                      <div className="absolute bottom-96 text-center  bg-gray-800 rounded m-auto w-7/8 left-0 right-0 text-white text-2xl p-4  "
                        id="playModeNavigation"
                      >
                        <div className="w-full grid grid-cols-3 gap-4 animate__animated animate__fadeIn">
                          {sceneData.map((scene, index) => (
                            <div className="bg-gray-800 w-64 hover:bg-gray-700 text-white rounded p-3 transition duration-300 cursor-pointer text-left"
                              onClick={() => {
                                onChange(index)
                              }}>
                              <div className="text-xl text-gray-100">
                                {scene.name}
                              </div>
                              <div className="text-lg text-gray-300">
                                {scene.description}
                              </div>

                            </div>
                          ))}
                        </div>
                      </div>
                      : null} */}

                {/* const [isModalOpen, setIsModalOpen] = useState(false);

  const openTOCModal = () => setIsModalTOCOpen(true);
  const closeTOCModal = () => setIsModalTOCOpen(false); */}






                {storyData.navigation == 'playlist' ?
                  <>

                    <div className="relative h-full w-full z-10 ">
                      <div className="absolute bottom-56 left-6 right-6 h-fit only-toc noshow animate__animated animate__fadeInUp ">
                        {/* <div className="absolute bottom-40 text-center  bg-gray-800 rounded m-auto w-7/8 left-0 right-0 text-white text-2xl p-4  "
                  id="playModeNavigation"
                > */}
                        <div className="w-full grid grid-cols-1 gap-1  p-6 rounded bg-zinc-900  ">
                          {sceneData.map((scene, index) => (
                            <div className={index == sessionData.activeIndex ? 'bg-white/5 flex w-full hover:bg-white/20 text-white rounded p-4 transition duration-300 cursor-pointer text-left'
                              :
                              'flex w-full hover:bg-white/10 text-white rounded p-4 transition duration-300 cursor-pointer text-left'
                            }
                              key={scene.name + 'scenetocindex'}
                              onClick={() => {
                                onChange(index)
                                //console.log('you ouyououuououou')
                                closeTOC()
                              }}>
                              {/* <img src="https://res.cloudinary.com/djdw2s79u/image/upload/ar_16:9,c_fit,w_900//v1717028086/Cloudinary-React/qy8qh7a3nswqjr8feq7r.png" className="inline mr-4 rounded h-12 "/> */}
                              <div className="w-3/12 text-xl   text-gray-100">
                                {index + 1} - {scene.name}
                              </div>
                              <div className="w-7/12 text-base text-gray-300">
                                {scene.description}
                              </div>
                              <div className="w-2/12 text-right text-base text-gray-300">
                                {scene.duration || '0:00'}
                              </div>
                            </div>
                          ))}
                          <div className="mx-auto rounded-md p-3 w-fit text-white bg-white/5  hover:bg-white/10 hover:cursor-pointer mt-2.5"
                            onClick={() => {
                              closeTOC()
                            }}>
                            <img src="/media/icon/noun-close-fff.svg" className="inline mr-2 h-6" />
                            close
                          </div>
                        </div>

                      </div>
                    </div>

                    <img src="/util/app/pc_mark.svg" className="absolute top-10 left-10 w-24" />

                    <div className="absolute bottom-4 text-center   bg-zinc-900 rounded-lg m-auto left-6 right-6 text-white text-2xl p-2  ">

                      <div className="w-full grid grid-cols-12 gap-6 animate__animated animate__fadeIn text-sm">

                        <div className="col-span-3 pr-5  bg-white/5  rounded-lg hover:bg-white/10 hover:cursor-pointer">
                          {sessionData.activeIndex - 1 >= 0 ?
                            <div className=" ">
                              <button className="flex w-full text-white rounded  p-4  transition duration-300
                               animate__animated animate__fadeIn     h-max
                                
                              "
                                onClick={() => {
                                  onChange(sessionData.activeIndex - 1)
                                }}>
                                <div className="flex-none p-4">
                                  <img src="/util/app/b3-button-previous.svg" className="w-6" />
                                </div>
                                <div className="flex-auto text-left mt-1">
                                  <span className="text-xl font-semibold">Previous</span>
                                  <br />
                                  <div className="text-lg w-full mt-2">
                                    {sceneData[sessionData.activeIndex - 1].name}
                                  </div>
                                  <div className="text-sm w-full text-zinc-300">
                                    {sceneData[sessionData.activeIndex - 1].description}
                                  </div>
                                </div>
                              </button>
                            </div>
                            : null
                          }
                        </div>




                        <div className="col-span-6  flex-col   p-3 rounded">
                          <div className="  text-center text-base flex">
                            <div className="flex-none w-14 pr-6 text-right text-zinc-300 mt-1">

                              <div id="vidElapsed"
                                className="only-media animate__animated animate__fadeIn"
                              ></div>

                            </div>
                            <div className="flex-auto text-2xl font-semibold mt-2 mb-2">
                              {sceneData[sessionData.activeIndex].name || ''}
                            </div>
                            <div className="flex-none w-14 text-right text-base text-zinc-300 mt-1">

                              <div id="vidDuration"
                                className="only-media animate__animated animate__fadeIn"
                              ></div>

                            </div>
                          </div>



                          <div className="flex w-full mt-2 h-6">

                            <div className="overflow-hidden h-3 mt-1  flex-auto rounded bg-black/50 only-media noshow">
                              <div
                                id="vidProgress"
                                className="shadow-none bg-white  h-4 transition-all ease-linear duration-1000 "></div>
                            </div>

                          </div>
                          <div className="flex w-full mt-2 text-lg h-16">

                            <div className="flex-auto">

                              <div className="mx-auto  rounded-md p-3 w-fit bg-white/5  hover:bg-white/10 hover:cursor-pointer mt-2.5"
                                onClick={() => {
                                  //openTOCModal()
                                  showTOC()
                                }}>
                                <img src="/util/app/b3-admin-chapters.svg" className="h-6 inline mr-2" />
                                Contents
                              </div>
                              {/* <ExpTOC isOpen={isModalTOCOpen} onClose={closeTOCModal} /> */}

                              <div className="mx-auto rounded-md p-3 w-fit text-white bg-white/5  hover:bg-white/10 hover:cursor-pointer mt-2.5"
                                onClick={() => {
                                  closeTOC()
                                }}>
                                <img src="/media/icon/noun-close-fff.svg" className="inline mr-2 h-6" />
                                close
                              </div>


                            </div>




                            <div className=" bg-white/5  mx-auto rounded-full p-4 w-fit mt-1 mb-1  hover:bg-white/10 hover:cursor-pointer only-media noshow animate__animated animate__fadeIn"
                              onClick={() => {
                                emitVid({ stop: '1' })
                                //setMediaIsPlaying(false)
                              }}>

                              <img src="/util/app/b3-admin-pause.svg" className="w-6" />
                            </div>
                            {/* :
                              <div className=" bg-white/5  mx-auto rounded-full p-4 w-fit mt-1 mb-1  hover:bg-white/10 hover:cursor-pointer only-media noshow animate__animated animate__fadeIn"
                                onClick={() => {
                                  emitVid({ play: '0' })
                                  //setMediaIsPlaying(true)
                                }}>

                                <img src="/util/app/b3-admin-play.svg" className="w-6" />
                              </div>
                            } */}

                            <div className="flex-auto only-media noshow animate__animated animate__fadeIn">
                              <div className="mx-auto rounded-md p-3 w-fit  bg-white/5   hover:bg-white/10 hover:cursor-pointer mt-2.5"
                                onClick={() => {
                                  openTOCModal()
                                }}>
                                <img src="/util/app/b3-admin-volume.svg" className="h-6 inline mr-2" />
                                Volume
                              </div>
                              {/* <ExpTOC isOpen={isModalTOCOpen} onClose={closeTOCModal} /> */}
                            </div>

                          </div>



                        </div>



                        {/* <div className="bg-black/50 mx-auto rounded-md p-3 w-12   hover:bg-white/10 hover:cursor-pointer"
                              onClick={() => {
                                emitVid({ stop: '1' })
                              }}>
                              <img src="/util/app/b3-admin-pause.svg" className="w-8" />
                            </div> */}

                        <div className="col-span-3 pl-5  bg-white/5  rounded-lg hover:bg-white/10 hover:cursor-pointer">
                          {sessionData.activeIndex < sceneData.length - 1 ?
                            <div
                              id="nextSceneButton"
                              className=" ">
                              <button className="flex   w-full  text-white rounded p-4 transition duration-300
                               animate__animated animate__fadeIn   h-28
                              "
                                onClick={() => {
                                  onChange(sessionData.activeIndex + 1)
                                }}>
                                <div className="flex-auto text-left mt-1">
                                  <span className="text-xl font-semibold">Next</span>
                                  <br />
                                  <div className="text-lg mt-2">
                                    {sceneData[sessionData.activeIndex + 1].name}
                                  </div>
                                  <div className="text-sm w-full text-zinc-300">
                                    {sceneData[sessionData.activeIndex + 1].description}
                                  </div>
                                </div>
                                <div className="flex-none p-4 mt-5">
                                  <img src="/util/app/b3-button-next.svg" className="w-6" />
                                </div>
                              </button>
                            </div>
                            : null}
                        </div>




                      </div>


                      {/* <div className="w-full text-lg  mt-1 mb-2">
                        {sceneData[sessionData.activeIndex].name}
                      </div>

                      <div className="pt-1 flex w-full text-sm h-14">
                        <div className="w-64">
                          {sessionData.activeIndex - 1 >= 0 ?
                            <div className="-mt-10">
                              <button className="flex w-52 mr-6 text-white rounded p-2 transition duration-300
                               animate__animated animate__fadeIn min-h-48
                               bg-black/50 hover:bg-white/10 hover:cursor-pointer
                              "
                                onClick={() => {
                                  onChange(sessionData.activeIndex - 1)
                                }}>
                                <div className="flex-none p-4">
                                  <img src="/util/app/b3-button-previous.svg" className="w-6" />
                                </div>
                                <div className="flex-auto text-left">
                                  <span className="text-sm">Previous</span>
                                  <br />
                                  <span className="text-lg">
                                    {sceneData[sessionData.activeIndex - 1].name}
                                  </span>
                                </div>
                              </button>
                            </div>
                            : null
                          }
                        </div>

                        <div className="px-2 mt-2">
                          <div className="mx-auto rounded-md p-3 w-fit  -mt-6 bg-black/50 hover:bg-white/10 hover:cursor-pointer"
                            onClick={() => {
                              openTOCModal()
                            }}>
                            <img src="/util/app/b3-admin-chapters.svg" className="h-6 inline mr-2" />
                            Table of Contents
                          </div>

                          <ExpTOC isOpen={isModalTOCOpen} onClose={closeTOCModal} />

                        </div>

                        <div className="flex-none w-14 pr-6 text-right text-zinc-300">
                          <div id="vidElapsed"></div>
                        </div>
                        <div className="overflow-hidden h-3 mt-1 text-xs flex-auto rounded bg-black/50">
                          <div
                            id="vidProgress"
                            className="shadow-none bg-white  h-4 transition-all ease-linear duration-1000 "></div>
                        </div>
                        <div className="flex-none w-14 pl-6 text-right text-zinc-300">
                          <div id="vidDuration"></div>
                        </div>
                        <div className="px-2 ml-2 mt-2">
                          <div className="bg-black/50 mx-auto rounded-md p-3 w-12  -mt-6 hover:bg-white/10 hover:cursor-pointer"
                            onClick={() => {
                              emitVid({ play: '1' })
                            }}>
                            <img src="/util/app/b3-admin-play.svg" className="w-8" />
                          </div>
                        </div>
                        <div className="px-2 mt-2">
                          <div className="bg-black/50 mx-auto rounded-md p-3 w-12   -mt-6 hover:bg-white/10 hover:cursor-pointer"
                            onClick={() => {
                              emitVid({ stop: '1' })
                            }}>
                            <img src="/util/app/b3-admin-pause.svg" className="w-8" />
                          </div>
                        </div>

                        <div className="w-64">
                          {sessionData.activeIndex < sceneData.length - 1 ?
                            <div
                              id="nextSceneButton"
                              className="  -mt-10 ">
                              <button className="flex  w-52 ml-6 min-h-48  text-white rounded p-2 transition duration-300
                               animate__animated animate__fadeIn bg-black/50 hover:bg-white/10 hover:cursor-pointer
                              "
                                onClick={() => {
                                  onChange(sessionData.activeIndex + 1)
                                }}>
                                <div className="flex-auto text-left">
                                  <span className="text-sm">Next</span>
                                  <br />
                                  <span className="text-lg">
                                    {sceneData[sessionData.activeIndex + 1].name}
                                  </span>
                                </div>
                                <div className="flex-none p-4">
                                  <img src="/util/app/b3-button-next.svg" className="w-6" />
                                </div>
                              </button>
                            </div>
                            : null}
                        </div>
                      </div> */}
                    </div>
                  </>
                  : null}


                {editMode && sessionData.modeVideo == 'fullscreen'
                  && storyData.navigation != 'playlist'
                  && storyData.navigation != 'playlist-sidebar2'
                  && storyData.navigation != 'dots'
                  ?
                  <>
                    {/* NavMobile = ({ storyData, sceneData, sessionData, location, onChange, TogglePlayMode }) */}
                    <div id="playModeNavigation"
                      className={`p-3 absolute transition ease-in-out duration-750 ${sessionData.playModeActive == 1 ? '-translate-y-full delay-1000' : 'translate-y-0 delay-100 duration-1000'}`}
                    >
                      <NavMobile
                        storyData={storyData}
                        sceneData={sceneData}
                        sessionData={sessionData}
                        location={location}
                        onChange={onChange}
                      />
                    </div>
                  </>
                  :
                  (null)
                }
              </div>




              {sessionData.playModeActive == 1 ?
                <>
                  {storyData.navigation == 'playlist-sidebar2' ?
                    <>


                      {/* <div className="bg-slate-200 w-[600px] h-[400px] relative"> */}
                      {/* <div className="absolute w-1/3  h-fit
                        text-center
                         m-auto left-0 right-0 top-0 bottom-0 bg-zinc-900 text-gray-100 p-12">
                          <div className="text-2xl">
                          Welcome Details Go Here
                          </div>
                          <div className="text-gray-300 mt-12">
                          Some info here to welcome people to the experience
                          </div>
                          <div className="w-content p-2 rounded bg-black rounded mt-12"
                          onClick={() => { startExperience() }}
                          >
                          Start Experience
                          </div>
                        </div> */}
                      {/* </div> */}
                      <ModalWelcome />


                      <div className="p-3 px-4 pr-5 justify-center   items-center  z-10 absolute   hover:scale-105 hover:text-white right-6 bottom-6  scale-100 transition-all ease-in-out duration-100
                rounded-md text-gray-50 hover:cursor-pointer
                
                "
                        style={{ backgroundColor: '#444353' }}
                        onClick={() => {
                          setShowDocumentModal(true)
                        }}>
                        <img src="/util/app/b3-admin-docs.svg" className="w-8 inline mr-3 text-sm" /> eBook &amp; Pitch Deck
                      </div>

                      <ModalDocuments />

                      {/* <CompHSChat /> */}


                      <div className="p-4 justify-center items-center z-10 absolute top-2 left-1.5 bottom-2 
                      animate__animated  animated__fadeIn  h-full">
                        <div className="bg-black overflow-hidden rounded-md 
                        w-[8rem]
                        sm:w-[10rem]
                        md:w-[12rem] 
                        lgmd:w-[16rem]
                        lg:w-[20rem]
                        xl:w-[24rem]
                        2xl:w-[28rem]
                        "
                        //style={{ width: '25%' }}
                        //style={{ width: '480px' }}
                        >

                          {storyData.imageStoryLogo != undefined && (
                            <img src={storyData.imageStoryLogo} className=" h-5 lg:h-6 xl:h-8 2xl:h-10 px-4 mt-4 mb-4" />
                          )}
                          {/* <div className="h  w-full mx-auto"> */}
                          {sceneData[sessionData.activeIndex].video != undefined ?
                            <div
                            //style={{marginTop: '-36px'}}
                            //className="overflow-hidden"
                            >
                              <div className="animate__animated  animated__fadeIn overflow-hidden
                                              h-[400px]
                                              sm:h-[140px]
                                              md:h-[180px]
                                              lgmd:h-[240px]
                                              lg:h-[300px]
                                              xl:h-[360px]
                                              2xl:h-[400px]
                              "
                                style={{
                                  maxHeight: '400%',
                                  maxWidth: '200%',
                                  width: '200%',
                                  //height: '200%', 
                                  //height: '200%',
                                  marginLeft: '-46%',
                                  //marginTop: '-36px',
                                  overflow: 'hidden'
                                }}
                              >
                                <ReactPlayer
                                  url={sceneData[sessionData.activeIndex].video}
                                  width='100%'
                                  height='auto'
                                  controls={false}
                                  className="animate__animated  animated__fadeIn"
                                  ref={vidRef}
                                  //muted={true}
                                  muted={muted}
                                  playsinline={true}
                                  progressInterval={500}
                                  playing={videoPlaying}
                                  onEnded={() => {
                                    sessionData.activeIndex + 1 < sceneData.length ? onChange(sessionData.activeIndex + 1) : null
                                  }}
                                  onReady={(current) => {
                                    //document.getElementById("videoDuration").innerHTML = getFormattedTime(current.getDuration())
                                    //console.log('TIME for ' + sceneData[sessionData.activeIndex].name + ' .... ' + getFormattedTime(current.getDuration()))
                                  }}
                                  onPlay={() => {
                                  }}
                                  onProgress={(progress) => {
                                    //document.getElementById("videoProgress").innerHTML = getFormattedTime(progress.playedSeconds)
                                    {/* <div className="flex justify-between px-4 mt-2 text-sm h-6 text-gray-600 ">
                                          <span id="videoProgress"></span>
                                          <span id="videoDuration"></span>
                                        </div> */}
                                    document.getElementById("videoProgressMeter").style.width = progress.played * 100 + '%'
                                  }}
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nofullscreen",
                                      },
                                    },
                                  }}
                                />
                              </div>
                              <div className="px-0 mt-0 animate__animated animate__fadeIn " key={'player-video'}>
                                <div className="bg-zinc-900 h-2 rounded-full hover:cursor-pointer" id="videoProgressWrapper" onClick={handleProgressClick}>
                                  <div className="bg-zinc-600 h-2 transition-all ease-linear duration-500" id="videoProgressMeter"></div>
                                </div>
                              </div>
                            </div>
                            :
                            <>
                              <div className="h-96 w-full rounded-md"
                                style={
                                  {
                                    backgroundImage: 'url(/media/investor/b3-office-bg.png)',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat'
                                  }
                                }>
                              </div>

                              <div className="px-0 mt-0" key={'player-no-video'}>
                                <div className="bg-black/50 h-2 rounded-full">
                                  <div className=" h-2   transition-all ease-linear duration-1000 "></div>
                                </div>
                                <div className="flex justify-between mt-2 text-sm h-6 text-gray-600 ">
                                  <span ></span>
                                  <span ></span>
                                </div>
                              </div>
                            </>
                          }



                          <div className="bg-black/50 py-2 rounded-md  ">
                            <div className="  grid grid-cols-5 gap-1 mx-4 py-2">
                              <div>
                                {sessionData.activeIndex > 0 ?
                                  <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn " onClick={() => {
                                    onChange(sessionData.activeIndex - 1)
                                  }
                                  }>
                                    <img src="/util/app/b3-button-previous.svg" className="w-8" />
                                  </div>
                                  :

                                  <div className="  mx-auto rounded-md p-3 w-12    animate__animated animate__fadeIn " onClick={() => {
                                    //onChange(sessionData.activeIndex - 1)
                                  }
                                  }>
                                    <img src="/util/app/b3-button-previous.svg" className="w-8 opacity-10" />
                                  </div>
                                }

                              </div>



                              {sceneData[sessionData.activeIndex].video != undefined ?
                                <>
                                  {!videoPlaying ?
                                    <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                      onClick={() => {
                                        setVideoPlaying(true)
                                      }}>
                                      <img src="/util/app/b3-admin-play.svg" className="w-8" />
                                    </div>
                                    :
                                    <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                      onClick={() => {
                                        setVideoPlaying(false)
                                      }}>
                                      <img src="/util/app/b3-admin-pause.svg" className="w-8" />
                                    </div>
                                  }
                                </>
                                :
                                <div></div>
                              }




                              <div>
                                {sessionData.activeIndex + 1 < sceneData.length ?
                                  <div className="bg-black/50 mx-auto rounded-md p-3 w-12 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                    onClick={() => {
                                      onChange(sessionData.activeIndex + 1)
                                    }
                                    }>
                                    <img src="/util/app/b3-button-next.svg" className="w-8" />
                                  </div>
                                  : null}
                              </div>

                              <div className="col-span-2">
                                <div className="bg-black/50 mx-auto rounded-md p-3 w-full text-center text-gray-100 hover:bg-white/10 hover:cursor-pointer  animate__animated animate__fadeIn "
                                  onClick={toggleMute}>
                                  {muted ? 'unmute' : 'mute'}
                                </div>
                              </div>


                              {/* <div>
                                <div className=" mx-auto rounded-md py-3   hover:cursor-pointer  animate__animated animate__fadeIn text-gray-100 text-sm"
                                  onClick={() => {
                                  }
                                  }>
                                </div>
                              </div> */}

                            </div>





                            <div className="w-full 
                       overflow-y-scroll gap-1  sidebar-playlist-height  scroll-smooth"
                              id="scrollPlaylist"
                            >
                              {sceneData.map((scene, index) => (
                                <div className="px-4"
                                  key={scene.name + 'scenetocindex'}
                                >
                                  <div className={index == sessionData.activeIndex ?
                                    'bg-white/10 flex w-full hover:bg-white/20 text-white rounded   text-sm   cursor-pointer text-left rounded-md px-6  py-3'
                                    :
                                    'flex w-full hover:bg-white/10 text-gray-200 rounded  text-sm   cursor-pointer text-left px-6 py-3'
                                  }
                                    key={scene.name + 'scenetocindex'}
                                    onClick={() => {
                                      onChange(index)
                                    }}>
                                    <div className={index == sessionData.activeIndex ?
                                      'w-6  text-white '
                                      :
                                      'w-6   text-gray-300'
                                    }
                                    >
                                      <div className=" text-gray-500 inline">{index + 1}.</div>
                                    </div>
                                    <div className="w-9/12 text-sm text-gray-300">
                                      {scene.name}
                                    </div>
                                    {/* <div className="w-7/12 text-base text-gray-300">
                          {scene.description}
                        </div> */}
                                    <div
                                      className={index == sessionData.activeIndex ?
                                        'w-2/12 text-right text-sm text-gray-50'
                                        :
                                        'w-2/12 text-right text-sm text-gray-600'
                                      }
                                    >
                                      {scene.duration || '--'}
                                    </div>
                                  </div>
                                </div>
                              ))}

                              {sessionData.playModeActive != 1 ?
                                <button className="w-full bg-gray-800 hover:bg-gray-700 text-white rounded  p-3 transition duration-300" onClick={() => {
                                  setSessionData(prevState => ({
                                    ...prevState,
                                    playModeActive: 0
                                  }))
                                }}>
                                  Close
                                </button>
                                : null}

                            </div>
                          </div>

                          <div className="w-full grid grid-cols-2 pl-8 pr-4 py-6 gap-1 bg-zinc-900  ">

                            <div className=" ">
                              <h2 className="text-xl sm:text-lg text-gray-50 font-semibol ">John Brunswick</h2>
                              <p className="text-gray-400 text-sm  ">Founder, Brevit3</p>
                            </div>

                            <div className="text-xs text-gray-50 text-left  flex  justify-end gap-1">

                              <div className="bg-black/20 rounded-md p-3 h-12 hover:bg-white/10 hover:cursor-pointer  ">
                                {/* <img src="/util/app/b3-admin-send.svg" className="h-6 mx-auto" /> */}
                                <a href="https://calendly.com/brevit3/45min" target="_blank">
                                  <img src="/util/app/b3-admin-calendly.png" className="h-6 mx-auto inline mr-2" />
                                  Meet
                                </a>
                              </div>


                              <div className="bg-black/20 rounded-md p-3 h-12 hover:bg-white/10 hover:cursor-pointer  ">
                                <a href="https://www.linkedin.com/in/johnbrunswick/" target="_blank">
                                  <img src="/util/app/b3-logo-linkedin.png" className="h-6 mx-auto mr-2 inline " />
                                  Connect
                                </a>
                              </div>


                            </div>
                          </div>




                        </div>

                      </div>
                    </>
                    // </div>
                    : null}
                </>
                : null}

            </SessionContext.Provider>
            {/* </SessionContextProvider> */}

          </StoryContext.Provider>
        </SceneContext.Provider>





      </>

    );



  }
};

export default AppStoryPlayer;
