import React, { useRef, useState, useEffect, useMemo, Suspense } from "react";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from "three";
import { useAnimations, useGLTF, OrbitControls, useVideoTexture, TransformControls } from '@react-three/drei';
import { v4 as uuidv4 } from 'uuid'

const ExpModel = ({ vizItem, modelPath, scale = 40, rotate = 0, position = [0, 0, 0], positionEnd = [0, 0, 0], duration = 2 }) => {
  const [hovered, setHovered] = useState(false)
  const [positionStart, setPositionStart] = useState(position)
  const { size, viewport } = useThree();
  const aspect = size.width / viewport.width;
  const transform = useRef()
  const orbit = useRef()

  // useEffect(() => {
  //   transform.current = uuidv4();
  // }, []);

  //const mode = useControl("mode", { type: "select", items: ["scale", "rotate", "translate"] })

  useEffect(() => {
    if (transform.current) {
      const controls = transform.current
      controls.setMode("translate")
      const callback = event => (orbit.current.enabled = !event.value)
      controls.addEventListener("dragging-changed", callback)
      return () => controls.removeEventListener("dragging-changed", callback)
    }
  })  

  const model = useLoader(
    GLTFLoader,
    modelPath
  )

  const { DEG2RAD } = THREE.MathUtils

  function Screen({ src, position, scale, mediaWidth = 16, mediaHeight = 9 }) {
    const [video, setVideo] = useState()
    const ratio = 16 / 9
    const r = useMemo(() => (video ? video.videoWidth / video.videoHeight : ratio), [video, ratio])

    return (
      <mesh position={position} scale={scale}>
        <planeGeometry
          args={[mediaWidth, mediaHeight]}
        />
        <Suspense fallback={<meshStandardMaterial side={THREE.DoubleSide} wireframe />}>
          <VideoMaterial src={src} 
          //setVideo={setVideo} 
          />
        </Suspense>
      </mesh>
    )
  }

  function VideoMaterial({ src, setVideo }) {
    const texture = useVideoTexture(src)
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.x = 1
    texture.offset.x = 1
    setVideo?.(texture.image)
    return <meshStandardMaterial map={texture} toneMapped={false} opacity={1} />
  }

  /* TODO - single animation mode, do once and stop */

  /* Animation Start */
  let mixer
  if (model.animations.length) {
    mixer = new THREE.AnimationMixer(model.scene);
    model.animations.forEach(clip => {
      const action = mixer.clipAction(clip)

      // Show in all scenes is default, but a list can be selected of scenes to appear within....
      // Combine with models that are static and some that animate - filtered by scene.... 
      // Testing single loop
      // action.clampWhenFinished = true;
      // action.setLoop(THREE.LoopOnce);

      action.play();
    });
  }

  useFrame((state, delta) => {
    mixer?.update(delta)
  })
  /* Animation End */

  useEffect(() => {
    document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  const activateSidebar = (id) => {
    const el1 = document.querySelector('div[data-itemguid="' + id + '"]');
    el1.classList.add('active');
    var accordionPanels = document.querySelectorAll('.accordion__panel');
    for (var i = 0; i < accordionPanels.length; i++) {
      var panel = accordionPanels[i];
      if (panel.getAttribute("data-itemguid") === id) {
        panel.removeAttribute("hidden");
      }
      else {
        panel.setAttribute("hidden", "");
      }
    }
    const buttons = document.querySelectorAll('.accordion__button');
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];
      if (button.getAttribute("data-itemguid") === id) {
        button.setAttribute('aria-expanded', 'true');
        button.classList.add('last-accordion-button');
      }
      else {
        button.setAttribute('aria-expanded', 'false');
        button.classList.remove('last-accordion-button');
      }
    }
  }

  useEffect(() => {
    // TODO - fire select off to the side panel to highlight and make active the related item
    //document.body.style.cursor = hovered ? 'pointer' : 'auto'
  }, [hovered])

  if (vizItem.mediaEmbed) {
    // console.log('EMBED DATA...')
    // console.log(vizItem.mediaEmbed.offset)
    // console.log(positionStart)
  }

  //const [bind, { positionStart }] = useDrag({ axis: 'x' }); // restricts dragging to the x-axis

  return (
    <>
    {/* <OrbitControls ref={orbit} />
    <TransformControls 
    ref={transform}
    object={model.animations.length > 0 ? model.scene : model.scene.clone()}
    > */}
      <group
        position={positionStart}
      >
        {vizItem.mediaEmbed ?
          <Screen
            src={vizItem.mediaEmbed.source}
            position={[vizItem.mediaEmbed.offset.x, vizItem.mediaEmbed.offset.y, vizItem.mediaEmbed.offset.z]}
            scale={vizItem.mediaEmbed.scale}
            mediaHeight={vizItem.mediaEmbed.mediaHeight || 9}
            mediaWidth={vizItem.mediaEmbed.mediaWidth || 16}
          />
          : null
        }
        <primitive
          object={model.animations.length > 0 ? model.scene : model.scene.clone()}
          scale={scale}
          onPointerOver={() => { model.animations.length > 0 ? null : setHovered(true) }}
          onPointerOut={() => { model.animations.length > 0 ? null : setHovered(false) }}
          onClick={(e) => activateSidebar(vizItem.guid)}
        />
      </group>
    {/* </TransformControls> */}
    </>
  );
};

export default React.memo(ExpModel);