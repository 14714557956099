import { shaderMaterial, Plane, OrbitControls } from "@react-three/drei";
import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { extend, useFrame } from "@react-three/fiber";
import stripesFragment from "../shaders/stripes.fragment.glsl";
import stripesVertex from "../shaders/stripes.vertex.glsl";

const StripesShaderMaterial = shaderMaterial(
  {
    uAlpha: 1,
    uMultiplier: 42,
    uColorA: new THREE.Color(0x000000),
    uColorB: new THREE.Color(0x000000),
    uTime: 0,
  },
  stripesVertex,
  stripesFragment
);

const degreesToRadians = (degrees) => {
  return degrees * (Math.PI / 180)
}

extend({ StripesShaderMaterial });

const ExpLine = (props) => {

  const ref = useRef();
  useFrame((state) => {
    if (ref.current) {
      ref.current.uTime = state.clock.elapsedTime;
    }
  });

  // Rotations...
  // Horiz.. F-B rotation={[-degreesToRadians(90), 0, degreesToRadians(180)]}
  // Horiz.. L-R rotation={[-degreesToRadians(90), 0, degreesToRadians(90)]}
  // Horiz.. R-L rotation={[-degreesToRadians(90), 0, degreesToRadians(270)]}
  // Horiz.. B-F rotation={[-degreesToRadians(90), 0, degreesToRadians(0)]}
  // Vert... Bot-Top rotation={[-degreesToRadians(0), 0, degreesToRadians(180)]}
  // Vert... Top-Bot rotation={[-degreesToRadians(0), 0, degreesToRadians(0)]}  

  // Set default
  let rotationData = [-degreesToRadians(90), 0, degreesToRadians(180)]

  switch (props.layoutStyle) {
    case 'fb':
      rotationData = [-degreesToRadians(90), 0, degreesToRadians(180)]
      break;
    case 'bf':
      rotationData = [-degreesToRadians(90), 0, degreesToRadians(0)]
      break;
    case 'lr':
      rotationData = [-degreesToRadians(90), 0, degreesToRadians(90)]
      break;
    case 'rl':
      rotationData = [-degreesToRadians(90), 0, degreesToRadians(270)]
      break;
    case 'bt':
      rotationData = [-degreesToRadians(0), 0, degreesToRadians(180)]
      break;
    case 'tb':
      rotationData = [-degreesToRadians(0), 0, degreesToRadians(0)]
      break;
    default:
    // code block
  }

  return (
    <>
      <Plane
        position={[props.posX, props.posY, props.posZ]}
        rotation={rotationData}
        args={[props.width || 1, props.length || 10]}
      >
        <stripesShaderMaterial
          ref={ref}
          transparent
          uAlpha={props.transparency || 1}
          uMultiplier={(props.length || 1) / 3}
          uColorA={props.lineColor}
          uColorB={props.lineColor}
        />
      </Plane>
    </>
  );
}

export default ExpLine