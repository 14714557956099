import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { Listbox, Transition } from '@headlessui/react'
import Slider from 'react-input-slider'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from "react-router-dom";

import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'

const ConfigLink = ({ contentItem, storyId }) => {

  const [valueURL, setValueURL] = useState();
  const [labelText, setLabelText] = useState();

//  const { storyId } = useParams()

  const animationStyles = [
    { id: 1, name: 'none', displayName: 'None' },
    { id: 2, name: 'bounceIn', displayName: 'Bounce In' },
    { id: 3, name: 'fadeIn', displayName: 'Fade In' },
    { id: 4, name: 'fadeInDown', displayName: 'Fade In Down' },
    { id: 5, name: 'fadeInLeft', displayName: 'Fade In Left' },
    { id: 6, name: 'fadeInRight', displayName: 'Fide In Right' },
    { id: 7, name: 'fadeInUp', displayName: 'Fade In Up' },
    { id: 8, name: 'zoomIn', displayName: 'Zoom In' },
  ]

  useEffect(() => {
    if (contentItem) {
      setValueURL(contentItem.content)
      setLabelText(contentItem.label)
    }
  }, [])

  const [storyData, setStoryData] = useContext(StoryContext)
  const [sceneData, setSceneData] = useContext(SceneContext)
  const [sessionData, setSessionData] = useContext(SessionContext)

  // const [personaName, setPersonaName] = useState(item.mediaItem.personaName)
  // const [personaRole, setPersonaRole] = useState(item.mediaItem.personaRole)
  // const [personaPosX, setPersonaPosX] = useState(item.item.personaPosX)
  // const [personaDetail, setPersonaDetail] = useState(item.mediaItem.personaDetail)

  const [personaPositionXY, setPersonaPositionXY] = useState({ x: contentItem.pos.x, y: contentItem.pos.y })
  const [personaSize, setPersonaSize] = useState({ width: contentItem.size.width, height: contentItem.size.height })
  const [personaDelay, setPersonaDelay] = useState(contentItem.animationDuration)
  const [personaAnimation, setPersonaAnimation] = useState(contentItem.animation)

  const mediaitem = contentItem

  // const handleSliderXChange = (value) => {
  //   setPersonaPosX(value)
  // }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const updateContent = async (e) => {
    console.log('Update Content...')
    console.log(valueURL)
    console.log(contentItem)

    let cloneVizActive = []
    cloneVizActive = [...sceneData[sessionData.activeIndex].viznodes]

    cloneVizActive.forEach(item => {
      console.log(item.guid)
      if ((contentItem.guid == item.guid)) {
        contentItem.pos = personaPositionXY
        contentItem.size = personaSize
        contentItem.animationDuration = personaDelay
        contentItem.animation = personaAnimation
        contentItem.content = valueURL
        contentItem.label = labelText
      }
    })

    let activeSceneId = sceneData[sessionData.activeIndex].id
    const sceneRef = doc(db, 'stories', storyId, 'scenes', activeSceneId)

    updateDoc(sceneRef, {
      viznodes: cloneVizActive
    })
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ]
  }

  return (
    // <form onSubmit={(e) => {
    //     e.preventDefault()
    // }}>
    <div className="">

      <div className='w-100p float-left slider-parent-size '>
        <div className="mr-2 mb-4">


        <div className="mt-2.5">
        <input
          autoComplete="off"
          value={valueURL}
          onChange={(e) => setValueURL(e.target.value)}
          type="text"
          name="url"
          id="url"
          placeholder='url'
          className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
      text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
      focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
        />
      </div>

      <div className="mt-2.5">
        <input
          autoComplete="off"
          value={labelText}
          onChange={(e) => setLabelText(e.target.value)}
          type="text"
          name="label"
          id="label"
          placeholder='link label'
          className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 
      text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 
      focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
        />
      </div>


          <div className="flex mb-4 text-sm mt-4">
            <div className="flex-none w-20 h-4  text-gray-300 ml-1 mr-2 mt-4">
              Animation
            </div>

            <div className="flex-auto">
              <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                {({ open }) => (
                  <>
                    {/* <Listbox.Label className="block leading-6 text-gray-300 text-sm">Animation</Listbox.Label> */}
                    <div className="relative mt-2">
                      <Listbox.Button className="relative w-full cursor-default rounded-md  bg-white/5 hover:bg-white/10  py-1.5 pl-3 pr-10 text-left text-gray-200 shadow-sm ring-1 ring-inset ring-gray-900 focus:outline-none   sm:text-sm sm:leading-6">
                        <span className="block truncate">{personaAnimation.displayName}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-zinc-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {animationStyles.map((person) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={person}
                            >
                              {({ personaAnimation, active }) => (
                                <>
                                  <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                    {person.displayName}
                                  </span>

                                  {personaAnimation ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-sky-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>

        </div>
        <div className="flex mb-4 text-sm">
          <div className="flex-none w-20 h-4 text-gray-300 ml-1 mr-2">
            Delay
          </div>
          <div className="flex-auto">
            <Slider
              axis="x"
              xmin={0}
              xmax={30}
              xreverse={false}
              x={personaDelay}
              onChange={({ x }) => setPersonaDelay(x)}
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21',
                  width: 180
                }
              }}
            />
          </div>
          <div className="flex-1 w-14 text-white ml-2">
            {personaDelay}
          </div>
        </div>

        {/* <div className="flex mb-4 text-sm">
          <div className="flex-none w-20 h-4 text-white mr-2">
            Size
          </div>
          <div className="flex-auto">
            <Slider
              axis="x"
              xmin={10}
              xmax={250}
              xreverse={false}
              x={personaSize.width}
              onChange={({ x }) => setPersonaSize(state => ({ ...state, width: x }))}
              styles={{
                active: {
                  backgroundColor: '#333'
                },
                track: {
                  backgroundColor: '#1b1e21',
                  width: 180
                }
              }}
            />
          </div>
          <div className="flex-1 w-14 text-white ml-2">
            {personaSize.width}
          </div>
        </div> */}

      </div>

      <div className='w-100p  slider-parent mt-3'>

        <Slider
          axis="xy"
          xmin={-30}
          xmax={30}
          ymin={-10}
          ymax={30}
          xreverse={true}
          yreverse={true}
          x={personaPositionXY.x}
          y={personaPositionXY.y}
          onChange={setPersonaPositionXY}
          styles={{
            active: {
              backgroundColor: '#333'
            },
            track: {
              backgroundColor: '#1b1e21'
            }
          }}
        />
      </div>
      {/* <div className='text-white  mb-4'>
              Position {personaPositionXY.x}, {personaPositionXY.y}
            </div> */}


      


      {/* <div className="mt-8 h-200">
                <ReactQuill theme="snow" modules={modules} value={valueURL} onChange={setValueURL} />
            </div> */}
      <div className="sm:col-span-1 mt-6">
        <button className='w-full justify-center rounded bg-sky-500 px-3 py-1
                    text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                    focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600' onClick={() => updateContent()}>Update</button>
      </div>
    </div>
    // </form>
  )
}

export default ConfigLink