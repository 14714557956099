import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

 
const Folder = () => {
    return (
        <>
            <div className="h-screen flex flex-col">

                <div className="md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
                            Library Folders
                        </h2>
                        {/* <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
                            Enterprise or team functionality
                        </h2> */}
                    </div>
                    <div className="mt-4 flex md:ml-4 md:mt-0">
                        {/* Upper right button here */}
                    </div>
                </div>
                <div className="overflow-y-auto admin-scroll mt-6">
                    <div className="mx-auto py-4">
                        <div className="">
                            <div className="mx-auto">
                                <div className="mx-auto text-gray-300">
                                    {/* <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-100">Frequently asked questions</h2> */}
                                    Available with enterprise or team / project functionality
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <AdminWelcomeStats /> */}
        </>
    )
}

export default Folder