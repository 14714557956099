import { height } from "./ArcDiagram";
//import data from "./data.json";
import { scalePoint, scaleOrdinal, schemeCategory10 } from "d3";

const minRadius = 4;
const maxRadius = 34;

export const Nodes = ({ xScale, colorScale, data }) => {
  const radiusScale = scalePoint()
    .range([minRadius, maxRadius])
    .domain(data.nodes.map((d) => d.count).sort((a, b) => a - b));
return (
    <>
        {data.nodes.map(({ id, count, name }) => (
            <g key={id +'-'+ Math.floor(Math.random() * 9999999999999999999999999)}>
                <circle
                    cx={xScale(id)}
                    cy={height / 2}
                    r={radiusScale(count)}
                    fill={colorScale(id)}
                    stroke="#1a1a21"
                    strokeWidth={4}
                />
                <text
                    x={xScale(id) - 12}
                    y={height / 2 + 68}
                    textAnchor="middle"
                    fill="white"
                    fontSize={12}
                    transform={`rotate(-45, ${xScale(id)}, ${height / 2 + 48})`}
                >
                    {name}
                </text>
                <text
                    x={xScale(id)}
                    y={height / 2 - 64}
                    textAnchor="middle"
                    fill="white"
                    fontSize={18}
                >
                    {count}
                </text>          
            </g>
        ))}
    </>
);
};
