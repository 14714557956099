import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore'
import { Dialog, Transition, Listbox, RadioGroup } from '@headlessui/react'
//import { StoryContext } from '/AppStoryEditor'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from "react-qr-code";

const StoryDetailEdit = ({ props, story, linkOnly = 0, htmlOpener, setStoryActiveEdit }) => {

  const cancelButtonRef = useRef(null)

  const [openStoryEdit, setOpenStoryEdit] = useState(false)
  const [storyName, setStoryName] = useState()
  const [storyDescription, setStoryDescription] = useState()
  const [storyId, setStoryId] = useState()

  const [copied, setCopied] = useState();

  // useEffect(() => {
  //   setCopied({
  //     value: 'https://localhost:5173/play/' + story.id,
  //     copied: false,
  //   })
  // }, []);

  const securitySettingOptions = [
    { id: 1, name: 'private', displayName: 'Internal - Private to You', description: 'You are the only one able to access this story' },
    { id: 3, name: 'public', displayName: 'External - Public Access', description: 'Your story is publically accessible and anyone with the link will be able to view' },
  ]
  const [securitySetting, setSecuritySetting] = useState()

  useEffect(() => {
    //console.log('GOT STORY....')
    if (story) {
      // console.log('details....')
      // console.log(story)
      setStoryName(story.name)
      setStoryDescription(story.description)
      setStoryId(story.id)
      setSecuritySetting(story.visibility)
      setCopied({
        //value: 'https://localhost:5173/play/' + story.id,
        value: 'https://www.brevit3.com/play/' + story.id,
        copied: false,
      })

      // Open modal?
      setOpenStoryEdit(true)
    }
  }, [story]);

  const updateStory = () => {
    const storyRef = doc(db, 'stories', storyId);
    updateDoc(storyRef,
      {
        ...story,
        name: storyName,
        description: storyDescription,
        visibility: securitySetting,
        lastModified: serverTimestamp()
      }
    )
    setOpenStoryEdit(false)
  }

  const deleteStory = async () => {
    const storyRef = doc(db, 'stories', storyId);
    await deleteDoc(storyRef)
    setOpenStoryEdit(false)
  }

  const plans = [
    // { id: 'private', name: 'Internal - Private to you', description: 'You are the only one able to access this story' },
    // { id: 'project', name: 'Internal - Private to Project Members', description: 'Only members you assign will be able to access this story' },
    // { id: 'code', name: 'External - Access Code Protected', description: 'Invite external parties to view your story using an access code' },
    // { id: 'public', name: 'External - Public access', description: 'Your story is publically accessible and anyone with the link will be able to view' },
    { id: 1, name: 'private', displayName: 'Internal - Private to You', description: 'You are the only one able to access this story' },
    { id: 2, name: 'team', displayName: 'Internal - Private to Project Members', description: 'Only members you assign will be able to access this story' },
    { id: 3, name: 'public', displayName: 'External - Public Access', description: 'Your story is publically accessible and anyone with the link will be able to view' },
  ]

  //  const [selected, setSelected] = useState(settings[0])

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const animationStyles = [
    { id: 1, name: 'private', displayName: 'Internal - Private to You', description: 'You are the only one able to access this story' },
    { id: 2, name: 'team', displayName: 'Internal - Private to Project Members', description: 'Only members you assign will be able to access this story' },
    { id: 3, name: 'public', displayName: 'External - Public Access', description: 'Your story is publically accessible and anyone with the link will be able to view' },
  ]



  const closeStoryEdit = () => {
    setStoryActiveEdit(null)
    setOpenStoryEdit(false)
  }

  //  const [personaAnimation, setPersonaAnimation] = useState(animationStyles[0].name) //useState(item.item.animation)

  return (
    <>

      {/* {openStoryEdit ? */}

      <Transition.Root show={openStoryEdit} as={Fragment} key={storyId + 'storyeditmodal'}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeStoryEdit}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center text-center sm:items-center p-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >

                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-3/4 sm:w-none m-40 sm:p-12">
                  <div>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-3xl leading-6 text-gray-100">
                        {storyName || ''}
                      </Dialog.Title>

                      <div className="mt-2 pt-4 pb-4">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                      focus-within:ring-inset focus-within:ring-sky-400 mt-12">
                          <input
                            type="text"
                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                            placeholder="Story Name"
                            value={storyName || ''}
                            onChange={(e) => setStoryName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-2">
                        <textarea
                          placeholder="Description"
                          rows={2}
                          className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                            ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                          value={storyDescription || ''}
                          onChange={(e) => setStoryDescription(e.target.value)}
                        />
                      </div>

                      {/* <div className="mt-2 ">
                          <Listbox value={personaAnimation} onChange={setPersonaAnimation}>
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {animationStyles.map((person) => (
                                <Listbox.Option
                                  key={person.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-sky-500 text-white' : 'text-gray-300',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={person}
                                >
                                  {({ personaAnimation, active }) => (
                                    <>
                                      <span className={classNames(personaAnimation ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {person.displayName}
                                      </span>

                                      {personaAnimation ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-sky-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Listbox>
                        </div> */}




                      <div className="mt-10 mb-10">
                        <RadioGroup value={securitySetting} onChange={setSecuritySetting}>
                          <RadioGroup.Label className="sr-only">Sharing Settings</RadioGroup.Label>
                          <div className="-space-y-px rounded-md ">
                            {securitySettingOptions.map((setting, settingIdx) => (
                              <RadioGroup.Option
                                key={setting.id}
                                value={setting.name}
                                className={({ checked }) =>
                                  classNames(
                                    // settingIdx === 0 ? 'rounded-md' : '',
                                    // settingIdx === securitySettingOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                    checked ? 'z-10 border-sky-200  rounded-md ' : 'border-gray-100',
                                    'relative flex cursor-pointer   p-4 focus:outline-none'
                                  )
                                }
                              >
                                {({ active, checked }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        checked ? 'bg-sky-400 border-transparent ring-2 ring-offset-1' : '  border-gray-100',
                                        active ? 'ring-2 ring-offset-1 bg-sky-300' : '',
                                        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                                      )}
                                      aria-hidden="true"
                                    >
                                      <span className="rounded-full bg-grey-500 w-1.5 h-1.5" />
                                    </span>
                                    <span className="ml-3 flex flex-col">
                                      <RadioGroup.Label
                                        as="span"
                                        className={classNames(checked ? 'text-gray-100' : 'text-gray-100', 'block font-base mb-2 font-medium')}
                                      >
                                        {setting.name}
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="span"
                                        className={classNames(checked ? 'text-gray-300' : 'text-gray-300', 'block text-sm')}
                                      >
                                        {setting.description}
                                      </RadioGroup.Description>
                                    </span>
                                  </>
                                )}
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                        <div className="mt-10 mb-10">

                          {/* <span className="text-white ml-4 mr-2">Public URL</span> <a href={'https://localhost:5173/play/' + storyId} target="_blank" className="text-sky-400">https://localhost:5173/play/{storyId}</a> */}

                          <div>




                            <div className="w-full mt-2 pt-4 pb-4 ">




                              {securitySetting == 'public' ?
                                <>
                                  <div className="flex mr-4 animate__animated animate__fadeIn ">
                                    <div className="grow h-14">
                                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                      focus-within:ring-inset focus-within:ring-sky-400">
                                        <input
                                          type="text" readOnly={true}
                                          className="block flex-1 border-0 bg-transparent  text-gray-100 placeholder:text-gray-400 focus:ring-0 sm:text-lg sm:leading-6 p-4 focus:outline-none"
                                          // placeholder={copied.value}
                                          value={copied.value}
                                        //onChange={({ target: { value } }) => setCopied({ value, copied: false })}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-none w-14 h-14">
                                      <div className="mt-2 ml-4">
                                        <a href={copied.value} target="_blank">
                                          <img src="/media/icon/noun-link-5006217-FFFFFF.svg" className="h-10 w-10 o5" /></a>
                                      </div>
                                    </div>
                                    <div className="flex-none w-14 h-14">
                                      <CopyToClipboard text={copied.value}
                                        onCopy={() => setCopied({ value: copied.value, copied: true })}>
                                        <button><img src="/media/icon/noun-copy-2757462-FFFFFF.svg" className="h-12 o5" /></button>
                                      </CopyToClipboard>
                                    </div>
                                    <div className="flex-none w-14 h-14">
                                      {copied.copied ? <div className="text-sky-400 animate__animated animate__fadeIn mt-3">copied</div> : null}
                                    </div>
                                  </div>
                                  <div className="w-full mt-8">
                                    {copied.value ?
                                      <div className="flex">
                                        <div className="w-1/3">
                                        <QRCode
                                          value={copied.value}
                                        />
                                        </div>
                                        <div className="bg-black/50 rounded p-4 w-2/3 text-lg text-gray-50">
                                        &lt;iframe width=&quot;100%&quot; height=&quot;800px&quot; src=&quot;{copied.value}&quot; title=&quot;{storyName}&quot; frameborder=&quot;0&quot; allow=&quot;web-share&quot; referrerpolicy=&quot;strict-origin-when-cross-origin&quot; allowfullscreen&gt;&lt;/iframe&gt;
                                        </div>
                                      </div>
                                      : null}
                                  </div>
                                </>
                                : null}



                            </div>


                            {/* <input value={copied.value}
                                onChange={({ target: { value } }) => setCopied({ value, copied: false })} /> */}





                          </div>

                        </div>
                      </div>


                      {/* <fieldset>
                          <legend className="sr-only">Plan</legend>
                          <div className="space-y-10 pt-10 pb-4 ">
                            {plans.map((plan) => (
                              <div key={plan.id} className="relative flex items-start">
                                <div className="flex h-6 items-center">
                                  <input
                                    id={plan.id}
                                    aria-describedby={`${plan.id}-description`}
                                    name="plan"
                                    type="radio"
                                    defaultChecked={plan.id === 'private'}
                                    className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-sky-600"
                                  />
                                </div>
                                <div className="ml-3 text-base leading-6">
                                  <div htmlFor={plan.id} className="font-xl text-gray-50">
                                    {plan.displayName}
                                  </div>
                                  <div id={`${plan.id}-description`} className="text-gray-400 mt-1">
                                    {plan.description}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset> */}

                      {/* <div className="mt-2 pt-4 pb-4">
                          <div className="mt-2">
                          </div>
                          <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                      focus-within:ring-inset focus-within:ring-sky-400 mt-12">
                            Remove Story
                          </div>
                        </div> */}

                    </div>
                  </div>
                  <div className="w-full mt-12 flex">

                    <div className=" hover:bg-red-700 rounded-md text-center cursor-pointer" onClick={() => { if (window.confirm('Delete this Story?')) { deleteStory() }; }}>
                      <div className="flex-none w-10 ">
                        <img src="/media/icon/noun-trash-5765342-FFFFFF.svg" className="o6 h-4 ml-3 mt-3" />
                      </div>
                    </div>

                    <div className="grow ml-2 mr-2">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center 
                          rounded bg-gray-500 px-3 py-2 bg-zinc-800
                          text-base font-base text-gray-100 shadow-sm 
                          hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                        onClick={() => closeStoryEdit()}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="grow">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-sky-500 px-3 py-2
                     text-l font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => {
                          updateStory();
                          //setOpenStoryEdit(false);
                          closeStoryEdit()
                        }}
                      >
                        Save
                      </button>
                    </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* : (null)
      } */}

    </>
  );
};


export default StoryDetailEdit