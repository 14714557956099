import { Nodes } from "./Nodes";
import { Links } from "./Links";
import data from "./data.json";
import { scalePoint, scaleOrdinal, schemeCategory10 } from "d3";
import { Gradients } from "./Gradients";

export const width = 600;
export const height = 600;
const padding = { x: 30 };

export const ArcDiagram = (data) => {

  console.log('ArcDiagram data ------------:')
  console.log(data.data)

  data = data.data;

  const nodesIds = data.nodes.map((d) => d.id);
  const xScale = scalePoint()
    .range([0 + padding.x, width - padding.x])
    .domain(nodesIds);
  const colorScale = scaleOrdinal(schemeCategory10).domain(nodesIds);
  return (
    <svg viewBox={`-10 0 ${width} ${height}`} height="100%" width="100%">
      <Gradients />
      <Links xScale={xScale} colorScale={colorScale} data={data} />
      <Nodes xScale={xScale} nodesIds={nodesIds} colorScale={colorScale} data={data} />
    </svg>
  );
};


