import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { Dialog, Transition } from '@headlessui/react'
import { StoryContext } from '/AppStoryPlayer'
import { useParams, useLocation } from "react-router-dom";
import Slider from 'react-input-slider'
import { TagsInput } from "react-tag-input-component";
import { UserAuth } from '../context/AuthContext'
//import { set } from "animejs";

const SceneDetailEdit = ({ scene, openSceneEdit, setOpenSceneEdit }) => {

  const { user, logOut } = UserAuth() //{user?.photoURL}

  const cancelButtonRef = useRef(null)
  const { storyId } = useParams()

  const [sceneName, setSceneName] = useState(scene.name)
  const [sceneDuration, setSceneDuration] = useState()
  const [sceneDescription, setSceneDescription] = useState(scene.description)
  const [sceneData, setSceneData] = useState(scene)
  const [storyData, setStoryData] = useContext(StoryContext)

  // For analytics
  const [sceneTags, setSceneTags] = useState()

  //const [sceneCamPosX, setSceneCamPosX] = useState(scene.camPosX)
  const [sceneCamoffsetPositionX, setSceneCamoffsetPositionX] = useState()
  const [sceneCamoffsetPositionY, setSceneCamoffsetPositionY] = useState()
  const [sceneCamoffsetPositionZ, setSceneCamoffsetPositionZ] = useState()

  const [sceneCamoffsetTargetX, setSceneCamoffsetTargetX] = useState()
  const [sceneCamoffsetTargetY, setSceneCamoffsetTargetY] = useState()
  const [sceneCamoffsetTargetZ, setSceneCamoffsetTargetZ] = useState()
  const [sceneLayout, setSceneLayout] = useState(scene.layout)
  const [sceneVideo, setSceneVideo] = useState()

  const [sceneNavButtonIconURL, setSceneNavButtonIconURL] = useState(scene.navButtonIconURL)
  const [sceneNavMainOmit, setSceneNavMainOmit] = useState(false)

  const [camPosXY, setCamPosXY] = useState() // useState({ x: contentItem.pos.x, y: contentItem.pos.y })
  const [camTarXY, setCamTarXY] = useState()

  const SelectCameraPreset = (pz, px, py, tx, ty) => {
    setSceneCamoffsetPositionZ(pz)
    setCamPosXY({ x: px, y: py })
    setCamTarXY({ x: tx, y: ty })
  }



  useEffect(() => {
    setSceneData(scene)

    setSceneName(scene.name)
    setSceneDescription(scene.description)

    setSceneDuration(scene.duration || '')
    setSceneTags(scene.tags || [])
    setSceneVideo(scene.video || '')

    if (scene.navOmitMain) {
      setSceneNavMainOmit(scene.navOmitMain)
    } else {
      setSceneNavMainOmit(false)
    }

    if (scene.navButtonIconURL) {
      setSceneNavButtonIconURL(scene.navButtonIconURL)
    } else {
      setSceneNavButtonIconURL('')
    }

    if (scene.camOffset) {
      setSceneCamoffsetPositionX(scene.camOffset.position.x)
      setSceneCamoffsetPositionY(scene.camOffset.position.y)
      setSceneCamoffsetPositionZ(scene.camOffset.position.z)

      setSceneCamoffsetTargetX(scene.camOffset.target.x)
      setSceneCamoffsetTargetY(scene.camOffset.target.y)
      setSceneCamoffsetTargetZ(scene.camOffset.target.z)

      // New to set camera offset deatils
      setCamPosXY({ x: scene.camOffset.position.x, y: scene.camOffset.position.y })
      setCamTarXY({ x: scene.camOffset.target.x, y: scene.camOffset.target.y })
    }

    if (scene.layout) {
      setSceneLayout(scene.layout)
    } else {
      setSceneLayout(false)
    }
    //setSceneName(scene.description)
  }, [scene]);

  const updateSceneName = () => {
    sceneData.name = sceneName
    sceneData.description = sceneDescription

    sceneData.duration = sceneDuration

    //if (sceneNavMainOmit) {
    sceneData.navOmitMain = sceneNavMainOmit
    //}

    // console.log('Camera target object')
    // console.log('x: ' + camTarXY.x)
    // console.log('y: ' + camTarXY.y)

    sceneData.camOffset.position.x = camPosXY.x //sceneCamoffsetPositionX
    sceneData.camOffset.position.y = camPosXY.y //sceneCamoffsetPositionY
    sceneData.camOffset.position.z = sceneCamoffsetPositionZ

    sceneData.camOffset.target = camTarXY

    // sceneData.camOffset.target.x = camTarXY.x //sceneCamoffsetTargetX
    // sceneData.camOffset.target.y = camTarXY.z //sceneCamoffsetTargetY
    sceneData.camOffset.target.z = sceneCamoffsetTargetZ

    sceneData.layout = sceneLayout

    if (sceneNavButtonIconURL) {
      sceneData.navButtonIconURL = sceneNavButtonIconURL
    }
    else {
      sceneData.navButtonIconURL = ''
    }

    sceneData.tags = sceneTags
    sceneData.video = sceneVideo

    // console.log(sceneData.tags)

    // //sceneData.description = sceneDescription
    // console.log(sceneData)
    const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
    updateDoc(sceneRef, sceneData)
  }

  const deleteScene = async () => {
    const sceneRef = doc(db, 'stories', storyId, 'scenes', scene.id);
    await deleteDoc(sceneRef)
    setOpenSceneEdit(false)
  }

  const checkHandler = () => {
    setSceneNavMainOmit(!sceneNavMainOmit)
  }

  const checkHandlerLayout = () => {
    setSceneLayout(!sceneLayout)
  }

  return (

    <Transition.Root show={openSceneEdit} as={Fragment} key={scene.id + 'editmodal'}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenSceneEdit}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center p-10">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >

              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-zinc-900 px-4 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-1/2 sm:w-none m-40 sm:p-12">
                <div>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    updateSceneName();
                    setOpenSceneEdit(false);
                  }}>
                    <div className="text-left">
                      <Dialog.Title as="h3" className="text-2xl leading-6 text-gray-100">
                        Update Scene
                      </Dialog.Title>

                      <div className="pb-4">
                        <div className="mt-12">
                          <input
                            type="text"
                            className="block w-full rounded-md border-0 px-4 py-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                            ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-base sm:leading-6v focus:outline-none"
                            placeholder="Scene Name"
                            value={sceneName || ''}
                            //onChange={(e) => setSceneNameUpdate(e.target.value)}
                            onChange={(e) => setSceneName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="pb-4">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                      focus-within:ring-inset focus-within:ring-sky-400 mt-2">
                          <textarea
                            placeholder="Description"
                            rows={2}
                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-base sm:leading-6v focus:outline-none"
                            value={sceneDescription || ''}
                            onChange={(e) => setSceneDescription(e.target.value)}
                          />
                        </div>
                      </div>




                      <div className="pb-4">
                        {/* <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                      focus-within:ring-inset focus-within:ring-sky-400 mt-2"> */}
                        {/* <div className="text-gray-400">Tags for Content Shown</div> */}

                        <div className="text-lg">
                          <p className="text-white">Tags</p>
                          <p className="text-gray-300 text-base mb-2">used for analytics</p>
                        </div>

                        <TagsInput
                          value={sceneTags}
                          onChange={setSceneTags}
                          // classNames={{
                          //   tagInput: `rounded-md border-0 px-4 py-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                          //   ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-base sm:leading-6v focus:outline-none`,
                          // }}
                          name=""
                          placeHolder="enter tags for scene"
                        />
                        {/* </div> */}
                      </div>



                      <div className="pb-4">
                        <div className="mt-12">
                          <div className="text-lg">
                            <p className="text-white">Duration</p>
                            <p className="text-gray-300 text-base mb-2">will be shown in table of contents</p>
                          </div>
                          <input
                            type="text"
                            className="block w-full rounded-md border-0 px-4 py-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                            ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-base sm:leading-6v focus:outline-none"
                            placeholder="0:00"
                            value={sceneDuration || ''}
                            //onChange={(e) => setSceneNameUpdate(e.target.value)}
                            onChange={(e) => setSceneDuration(e.target.value)}
                          />
                        </div>
                      </div>



                      {user?.email == 'john.brunswick@gmail.com' ?
                      <div className="pb-4">
                        <div className="mt-12">
                          <div className="text-lg">
                            <p className="text-white">Video</p>
                            <p className="text-gray-300 text-base mb-2">URL for narrated video</p>
                          </div>
                          <input
                            type="text"
                            className="block w-full rounded-md border-0 px-4 py-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                            ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 text-base sm:leading-6v focus:outline-none"
                            placeholder=""
                            value={sceneVideo || ''}
                            //onChange={(e) => setSceneNameUpdate(e.target.value)}
                            onChange={(e) => setSceneVideo(e.target.value)}
                          />
                        </div>
                      </div>
                      : null}




                      <div className="pb-4 ">
                        <div className="relative flex gap-x-3 mt-8">
                          <div className="flex h-6 items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-white/10 bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900"
                              checked={sceneNavMainOmit}
                              onChange={checkHandler}
                            />
                          </div>
                          <div className="text-lg leading-6">
                            <p className="text-white">Use as Background Scene</p>
                            <p className="text-gray-300 text-base mt-2">This scene will not show in the navigation and can be used to add models to your story without cluttering scenes with content</p>
                          </div>

                        </div>

                        {/* <div className="relative flex gap-x-3 mt-12">
                          <div className="flex h-6 items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-white/10 bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900"
                              checked={sceneNavMainOmit}
                              onChange={checkHandler}
                            />
                          </div>
                          <div className="text-lg leading-6">
                            <p className="text-white">Exclude from Main Navigation Links</p>
                          </div>

                        </div>
                        <p className="text-gray-400 mt-2">If active, this scene will use the manual settings below and not automatically be included in the automated layout (linear, circle or vertical)</p>


                        <div className="flex mt-8">
                          <p className="text-gray-400">Icon URL</p>
                          <input
                            type="text"
                            className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                                ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                            value={sceneNavButtonIconURL}
                            onChange={(e) => setSceneNavButtonIconURL(e.target.value)}
                          />
                        </div> */}


                        {/* <div className="relative flex gap-x-3 mt-12">
                          <div className="flex h-6 items-center">
                            <input
                              id="comments"
                              name="comments"
                              type="checkbox"
                              className="h-4 w-4 rounded border-white/10 bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900"
                            />
                          </div>
                          <div className="text-lg leading-6">
                            <p className="text-white">Override Scene Placement</p>
                          </div>
                        </div>

                        <p className="text-gray-400 mt-4">If active, this scene will use the manual settings below and not automatically be included in the automated layout (linear, circle or vertical)</p> */}

                        {/* <div className="grid grid-cols-2">
                          <div clsassName="col-span-1">

                            <div className="grid grid-cols-12">

                              <div className="sm:col-span-1 text-center mt-4">
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
                                  X
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                                ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                                    value={scenePosX}
                                    onChange={(e) => setScenePosX(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-1 text-center mt-4">
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
                                  Y
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                                ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                                    value={scenePosY}
                                    onChange={(e) => setScenePosY(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-1 text-center mt-4">
                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
                                  Z
                                </label>
                              </div>
                              <div className="sm:col-span-2">
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                                ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                                    value={scenePosZ}
                                    onChange={(e) => setScenePosZ(e.target.value)}
                                  />
                                </div>
                              </div>

                            </div>
                          </div>

                        </div> */}


                        {/* <div className="flex mt-2">
                          <p className="text-gray-400">If active, this scene will use the manual settings below and not automatically be included in the automated layout (linear, circle or vertical).</p>
                          <p>{sceneLayout}</p>
                        </div> */}

                        {/* <div className="flex mt-12">
                          <p className="text-white text-xl">Override Camera Position</p>
                        </div> */}


                        {/* <div className="relative flex gap-x-3 mt-8">
                          <div className="flex h-6 items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 rounded border-white/10 bg-white/5 text-sky-600 focus:ring-sky-600 focus:ring-offset-gray-900"
                              checked={sceneLayout}
                              onChange={checkHandlerLayout}
                            />
                          </div>
                          <div className="text-lg leading-6">
                            <p className="text-white">Manual Camera Offset <span className="text-amber-500 text-sm ml-2">beta</span></p>
                          </div>

                        </div> */}

                        {sceneLayout == '123' ?

                          <div>
                            <p className="text-gray-400 mt-4">Overrides the default camera location and angle for the scene.  After changing the settings below, tap the Play button for the Scene and you will be able to see the new camera position for your scene.  Tip - try creating a Home Scene and using a distant camera angle to begin your story.</p>


                            <div className="flex gap-4 mb-8 mt-8 text-sm">

                              <div className='mt-2 mr-12 text-gray-300 text-base'>
                                Camera Presets
                              </div>

                              <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 100, 0, 0)}>
                                Top Close
                              </div>

                              <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1, 0, 800, 0, 0)}>
                                Top Far
                              </div>

                              <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1000, 0, 200, 0, 0)}>
                                Side Far
                              </div>

                              <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(300, 0, 150, 0, 0)}>
                                Side Close
                              </div>

                              <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1000, -800, 800, 0, 0)}>
                                Left Diagnoal
                              </div>

                              <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center hover:bg-white/20 cursor-pointer" onClick={() => SelectCameraPreset(1000, 800, 800, 0, 0)}>
                                Right Diagonal
                              </div>

                              {/* <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Industrial</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Diagram &amp; Arrows</div>
                            <div className="flex-auto bg-white/10 text-gray-50 p-3 rounded text-center">Mini Backgrounds</div> */}
                            </div>




                            <div className="grid grid-cols-2 gap-4 mt-8 animate__animated animate__fadeIn">
                              <div clsassName="col-span-1">

                                <div className="flex mt-2 mb-2">
                                  <p className="text-gray-400 font-base">Position (where it is)</p>
                                </div>



                                <div className="w-100p  slider-parent ">

                                  <Slider
                                    axis="xy"
                                    xmin={-800}
                                    xmax={800}
                                    ymin={-80}
                                    ymax={80}
                                    xreverse={false}
                                    yreverse={true}
                                    xstep={10}
                                    ystep={5}
                                    x={camPosXY.x}
                                    y={camPosXY.y}
                                    onChange={setCamPosXY}
                                    styles={{
                                      active: {
                                        backgroundColor: '#333'
                                      },
                                      track: {
                                        backgroundColor: '#1b1e21'
                                      }
                                    }}
                                  />
                                </div>

                                <div className="text-gray-400 mt-2 text-sm">
                                  {camPosXY.x}, {camPosXY.y}
                                </div>

                              </div>
                              <div clsassName="col-span-1">

                                <div className="flex mt-2 mb-2">
                                  <p className="text-gray-400 font-base">Target (where it looks)</p>
                                </div>

                                <div className="w-100p  slider-parent "
                                  style={{ backgroundPositionX: 'center' }}
                                >

                                  <Slider
                                    axis="xy"
                                    xmin={-800}
                                    xmax={800}
                                    ymin={-80}
                                    ymax={80}
                                    xstep={10}
                                    ystep={5}
                                    xreverse={false}
                                    yreverse={true}
                                    x={camTarXY.x}
                                    y={camTarXY.y}
                                    onChange={setCamTarXY}
                                    styles={{
                                      active: {
                                        backgroundColor: '#333'
                                      },
                                      track: {
                                        backgroundColor: '#1b1e21'
                                      }
                                    }}
                                  />

                                </div>
                                <div className="text-gray-400 mt-2 text-sm">
                                  {camTarXY.x}, {camTarXY.y}
                                </div>

                                <div className=" ">





                                  {/* <div className="sm:col-span-1 text-center mt-4">
    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
      X
    </label>
  </div>
  <div className="sm:col-span-2">
    <div className="mt-2">
      <input
        type="text"
        className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
  ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
        value={sceneCamoffsetTargetX}
        onChange={(e) => setSceneCamoffsetTargetX(e.target.value)}
      />
    </div>
  </div>

  <div className="sm:col-span-1 text-center mt-4">
    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
      Y
    </label>
  </div>
  <div className="sm:col-span-2">
    <div className="mt-2">
      <input
        type="text"
        className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
  ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
        value={sceneCamoffsetTargetY}
        onChange={(e) => setSceneCamoffsetTargetY(e.target.value)}
      />
    </div>
  </div> */}

                                  <div className="sm:col-span-1 text-center mt-4 hidden">
                                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-300">
                                      Z
                                    </label>
                                  </div>
                                  <div className="sm:col-span-2 hidden">
                                    <div className="mt-2">


                                      <input
                                        type="text"
                                        className="block w-full rounded-md border-0 p-2 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
  ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-lg sm:leading-6v focus:outline-none"
                                        value={sceneCamoffsetTargetZ}
                                        onChange={(e) => setSceneCamoffsetTargetZ(e.target.value)}
                                      />
                                    </div>
                                  </div>

                                </div>


                              </div>



                              <div className="w-full col-span-2">





                                <div className="  mt-8 mb-2">
                                  <p className="text-gray-400 font-base">Distance Away from Lineup (not zoom)</p>
                                </div>

                                <div className="flex text-gray-300 font-semibold text-2xl">
                                  <div className="flex-none w-14 h-14 text-center">
                                    -
                                  </div>
                                  <div className="w-100p  pr-6">

                                    <Slider
                                      axis="x"
                                      xmin={100}
                                      xmax={1600}
                                      xreverse={false}
                                      x={sceneCamoffsetPositionZ}
                                      onChange={({ x }) => setSceneCamoffsetPositionZ(x)}
                                      styles={{
                                        active: {
                                          backgroundColor: '#333'
                                        },
                                        track: {
                                          backgroundColor: '#1b1e21',
                                          width: '100%'
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="flex-none w-14 h-14">
                                    +
                                  </div>
                                </div>

                                <div className="text-gray-400 mt-2 text-sm">
                                  {sceneCamoffsetPositionZ}
                                </div>
                                {/* </div> */}








                              </div>


                            </div>
                          </div>
                          : null}

                        {/* <div className="flex mt-8">
                          <p className="text-white">Target Pos</p>
                        </div> */}

                      </div>

                    </div>
                  </form>
                </div>


                <div className="w-full mt-12 flex">
                  <div className="flex-none w-12">
                    {/* <div className="admin-mini-buttom right" onClick={() => deleteScene()}> */}
                    <button
                      type="button"
                      className="w-full justify-center rounded-md p-3.5
                     text-lg font-base text-white shadow-sm hover:bg-gray-800 hover:text-red-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => {
                        deleteScene();
                        setOpenSceneEdit(false);
                      }}
                    >
                      <img src="/media/icon/noun-trash-5765342-FFFFFF.svg" className="h-4 " />
                    </button>
                    {/* </div> */}
                  </div>


                  <div className="flex-auto px-2">
                    <button
                      type="button"
                      className="mt-3  w-full justify-center rounded-md px-3 py-2 
                    text-lg text-gray-200 shadow-sm  
                    bg-zinc-800 hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpenSceneEdit(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="flex-auto px-2">
                    <button
                      type="button"
                      className="w-full justify-center rounded-md  px-3 py-2
                     text-lg font-base text-white shadow-sm bg-sky-500 hover:bg-sky-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => {
                        updateSceneName();
                        setOpenSceneEdit(false);
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div >
      </Dialog >
    </Transition.Root >



  );
};


export default SceneDetailEdit






