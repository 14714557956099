import { useEffect, useState, useLayoutEffect, useRef } from 'react'
//import Slider from './components/Slider';
import PublicSiteSlider from '../components/PublicSiteSlider';
import React from 'react';
import { useMediaQuery } from 'react-responsive'
import 'animate.css'
import gsap from 'gsap';
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon, CheckIcon } from '@heroicons/react/24/outline'
import { useScroll } from '@react-three/drei';
import CompRegister from './CompRegister'
import { CompHSChat } from '../components/CompHSChat'
import CompContact from './CompContact'


function PublicHomeV2() {

    //const [menuOpened, setMenuOpened] = useState(false);

    // Breakpoint defaults for devices
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    // Total pages
    const bpPageCount = isMobile ? 7.5 : 4.85

    // Breakpoints for each section
    const bpMainHeader = !isTabletOrMobile ? '100vh' : '130vh'
    const bpHowItWorks = !isTabletOrMobile ? '210vh' : '310vh'
    const bpFAQs = !isTabletOrMobile ? '300vh' : '386vh'
    const bpPlans = !isTabletOrMobile ? '328vh' : '436vh'
    const bpFooter = !isTabletOrMobile ? '390vh' : '570vh'

    const scroll = useScroll()
    const tl = useRef()

    useLayoutEffect(() => {
        tl.current = gsap.timeline({ defaults: { duration: 2, ease: 'power1.inOut' } })
        tl.current
            .to("#main-header", { opacity: 1, duration: .75, y: 1 }, .88)
            .to("#test", { opacity: 1, scale: 1, duration: 1, }, 2)
            .to("#test2", { opacity: 1, scale: 1, duration: 1 }, 3)
    }, [])


    const faqs = [
        {
            question: "Do I need to download an application to create Stories?",
            answer:
                "We are 100% browser-based, no application is needed to create or view any stories.",
        },
        {
            question: "Are 3D models or knowledge of them required?",
            answer:
                "No 3D knowledge is needed, and with our enterprise plan, we will work with you to ensure that you have everything needed to visually tell your story.  Additionally, we team up with members of our Innovator Program to create and deliver new visual assets to the community - giving every member access to a growing library of models for their story needs.",
        },
        {
            question: "Do I need to be technical to create a story?",
            answer:
                "No, just a sense of what you are trying to communicate with your audience.  Brevit3 is used by sales, product and marketing professionals.",
        },
        {
            question: "Are design skills required?",
            answer:
                "We supply a range of pre-designed templates and themes to help you look great without needing deep design expertise.  Having good design skills is a plus, but not required.",
        },
        {
            question: "Why the name Brevit3?",
            answer:
                "We wanted to let people storytell solutions related to people, process and technology with brevity.  If you are sharing product, sales and or marketing details related to digital transformation solutions, we built this for you.  Designed to let you deliver your expertise in a highly engaging format, helping to drive better sales, education and enablement for your organization.",
        },

    ]

    const tiers = [
        {
            name: 'Innovator Program',
            id: 'tier-freelancer',
            image: '/images/b3-innovator.svg',
            href: '#',
            price: { monthly: 'Free', annually: 'Free' },
            //description: 'The essentials to provide your best work for clients.',
            description: '',
            features: [
                //'90 Days Access',
                //'90 Days Access',
                '1 Free Story',
                'Access to Community Models',
                'AI Story Media Studio',
                'Default Branding',
                //'48-hour support response time'
            ],
            mostPopular: false,
            linkText: 'Request Innovator Access',
            headingText: 'Innovator Program Request',
        },
        {
            name: 'Team & Enterprise',
            id: 'tier-enterprise',
            image: '/images/b3-innovator.svg',
            href: '#',
            price: { monthly: 'Contact Customer Success', annually: 'Let\'s Talk' },
            description: 'Dedicated support and infrastructure for your company.',
            features: [
                'Unlimited stories',
                'Private 3D Models',
                'AI Story Builder & Media Studio',
                'Custom Branding',
                'AI Analytics',
                'Secure sharing',
                'Collaboration',
                'Company Themes',
                'Dedicated Customer Success Manager',
            ],
            mostPopular: false,
            linkText: 'Contact Enterprise Success',
            headingText: 'Enterprise Program Contact',
        },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    function getMousePos(e) {
        //return { x: e.clientX, y: e.clientY };
        //return e.clientX / Screen.width;
        return e.clientX / window.innerWidth;
    }




    return (
        <>


            <div className="video-wrapper 
       transition-all 
       
      top-0
      
      md:top-40
      xl:top-10
      2xl:top-20
      3xl:top-0

      mt-0
      
      
      sm:-mt-64
      md:mt-0
      lg:-mt-28
      xl:-mt-10
      2xl:-mt-32
      3xl:-mt-60
      
      ">

                {/* <div className='absolute w-full h-14 bg-white z-10
        bg-gradient-to-b 
        from-[#23232C]
        to-[#7B7990E8]
        '>yo!</div> */}

                {isMobile ?
                    <video autoPlay loop muted playsInline className="w-full scale-100 md:scale-100"
                        placeholder='/media/b3-hero-mobile.jpg'>
                        <source src="/media/b3-hero-mobile.mp4" type="video/mp4" />
                    </video>
                    :
                    <video autoPlay loop muted playsInline className="w-full scale-100 md:scale-100"
                        placeholder='/media/b3-hero-20.jpg'>
                        <source src="/media/b3-hero.mp4" type="video/mp4" />
                    </video>
                }


            </div>

            <div className="leading-none p-4 text-white text-center video-title w-full mx-auto md:p-8 
        absolute
        z-10
        top-0

        mt-20

        text-2xl        
        md:text-3xl
        lg:text-4xl  
        xl:text-5xl  
        
        sm:mt-12
        md:mt-12
        lg:mt-8
        xl:mt-32
        2xl:mt-20
        3xl:mt-20

        ">
                <div className='z-2 '>
                    <span className='mb-6
                  bg-gradient-to-b from-[#ffffff] to-[#ddd] bg-clip-text text-center font-medium  leading-[1] text-transparent
                  '>  <span className='hidden md:inline '>Create</span> Immersive Stories that Boost</span>
                </div>
                <div className='mt-6'>
                    <div className="inline bg-zinc-900/90 rounded py-2 ml-2 mr-2 ">
                        <span className=" inline-flex flex-col                       
                      
                      h-[calc(theme(fontSize.2xl)*theme(lineHeight.tight))] 
                      md:h-[calc(theme(fontSize.3xl)*theme(lineHeight.tight))] 
                      lg:h-[calc(theme(fontSize.4xl)*theme(lineHeight.tight))] 
                      xl:h-[calc(theme(fontSize.5xl)*theme(lineHeight.tight))] 
                      overflow-hidden px-2 text-center ">
                            <ul className="block animate-text-slide-3 text-left font-medium  text-white leading-tight [&_li]:block ml-2">
                                <li >Sales Cycle</li>
                                <li>Onboarding</li>
                                <li>Enablement</li>
                                <li aria-hidden="true">Sales Cycle</li>
                            </ul>
                        </span>
                    </div>
                    <span className='bg-gradient-to-b from-[#ffffff] font-medium  to-[#ddd] bg-clip-text text-center  leading-[1] text-transparent'>
                        Performance
                    </span>
                </div>
            </div>

            <div className=" w-full  mx-auto  " id="main-header">
                <div className=" font-base 
                    lg:w-2/4
                    md:w-3/4
                    w-10/12
                    mx-auto
                    leading-20 text-lg lg:text-2xl xl:text-2xl text-zinc-300 
                    text-center   ">

                    Boost engagement &amp; conversion with fresh and engaging sales and product demonstrations
                    {/* 
              <div  
                          className=" mx-auto
                          text-center mt-8
                          mb-6 rounded-md bg-zinc-100/10 px-5 py-3 text-base text-xl
                          text-white shadow-sm hover:bg-zinc-700 focus-visible:outline focus-visible:outline-2 
                          block font-medium
                          focus-visible:outline-offset-2 focus-visible:outline-white
                          hover:scale-1025
                          transition-all
                          "
                        > */}
                    <CompRegister />
                    {/* </div> */}


                </div>

                {/* <div className='xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-medium mx-auto  drop-shadow
                        bg-gradient-to-b from-[#ffffff] to-[#ccc] bg-clip-text text-center lg:mt-20 mt-12  leading-[1] text-transparent
                        '>
                    Delight Decision Makers,
                    Employees<span className='lg:text-4xl text-2xl  font-base  text-gray-300 lg:mx-4 mx-2 '>&amp;</span>Partners
                </div> */}


<div className='xl:text-5xl lg:text-4xl md:text-3xl text-2xl font-medium mx-auto  drop-shadow
                        bg-gradient-to-b from-[#ffffff] to-[#ccc] bg-clip-text text-center lg:mt-20 mt-8  leading-[1] text-transparent'>
                    {/* Delight Decision Makers,
                    Employees<span className='lg:text-4xl text-2xl  font-base  text-gray-300 lg:mx-4 mx-2 '>&amp;</span>Partners */}
                    Create and Deliver Engagement at Scale
                </div>


                <div className=" font-base 
                    lg:w-2/4
                    md:w-3/4
                    w-10/12
                    mt-6
                    mx-auto
                    leading-20 text-lg lg:text-2xl text-zinc-400 
                    text-center   ">
                    Designed for sales engineers and product marketers to create engaging stories, without the need for an agency
                </div>


                <div
                    className='w-full
          mt-8
          md:mt-12
          lg:mt-14
          px-3
          md:px-6
          lg:px-12
          xl:px-40 

          2xl:px-64
          3xl:px-80
          transition-all 
          '>
                    <div className="grid grid-cols-2 lg:grid-cols-3 lg:gap-3 md:gap-3 gap-2 w-full   mx-auto ">
                        <div className="p-2 pt-6 lg:p-12  rounded-lg shadow-lg  z-1 bg-black/30 shadow-inner"
                        //style={{ background: '#151515' }}
                        >
                            <div className="text-xl lg:text-3xl mb-2 font-medium text-center text-gray-50">
                                Stand Out
                            </div>
                            <div className="text-lg text-gray-400">
                                <img src="/images/b3-stand-out-bg.svg" className='max-h-44 lg:w-1/2 lg:scale-80 w-6/12  mx-auto mt-8 mb-6 opacity-0 ' id="test" />
                            </div>
                            <div className='text-center xl:text-xl   text-zinc-400  '>
                                Boost differentiation &amp; retention with <span className='text-zinc-50'>memorable</span> experiences
                            </div>
                        </div>
                        <div className="  bg-gradient-to-b to-black/50  p-2 py-6 lg:p-12 rounded-lg shadow-lg text-gray-50 bg-black/30 shadow-inner"
                        //style={{ background: '#151515' }}
                        >
                            <div className="text-xl lg:text-3xl mb-6 font-medium text-center">Save Time</div>
                            <img src="/images/b3-fast.svg" className='max-h-44  scale-80 w-9/12  lg:w-3/4 mt-8 mx-auto opacity-0'
                                id="test"
                            />
                            <div className="text-lg  xl:text-xl  sm:text-lg    mt-8 text-center">
                                <span className='text-white'>Days &amp; Hours</span><br /><span className='line-through text-zinc-600'>Weeks or Months</span>
                            </div>
                            {/* <div className="text-base lg:text-xl  sm:text-lg text-gray-400 mt-3 text-center">
                      Fast like Slides
                    </div> */}
                            {/* <div className="text-base lg:text-xl  sm:text-lg text-gray-400 mt-1 text-center">
                      Cool like Agency
                    </div> */}
                        </div>
                        <div className="  p-2 py-6 lg:p-12  rounded-lg shadow-lg text-gray-50 bg-black/30 shadow-inner"
                        //style={{ background: '#151515' }}
                        >
                            {/* <div className="text-xl lg:text-3xl mb-2 font-medium text-center">
                      Look Great
                    </div> */}
                            <div className="text-xl lg:text-3xl mb-6 font-medium text-center">
                                {/* Quality <span className='text-2xl'>+</span> Scale */}
                                Look Great
                            </div>
                            <div className="text-lg lg:text-xl text-gray-400">
                                <img src="/images/b3-rocket-2.svg" className='olive max-h-44 w-1/2  scale-80 mx-auto mt-8 mb-4 opacity-0' id="test" />
                                <div className='text-center  xl:text-xl  sm:text-lg text-zinc-400'>
                                    No special skills - copy and edit like a document
                                </div>
                                {/* <div className='text-center text-lg lg:text-xl    mt-1'>
                        Copy and edit like a document
                      </div> */}
                            </div>
                        </div>


                        <div className="  p-2 py-6 lg:p-12  rounded-lg shadow-md text-gray-50 bg-black/30 shadow-inner"
                        //style={{ background: '#151515' }}
                        >
                            <div className="text-xl lg:text-3xl mb-6 font-medium text-center">Optimize Engagement</div>
                            <img src="/images/b3-analytics.svg" className='max-h-44  scale-80  w-3/4  mx-auto mt-8 opacity-0'
                                id="test2" />
                            <div className=" xl:text-xl  sm:text-lg  text-zinc-400 mt-4 text-center">
                                Understand Behavior to Optimize Content &amp; Outcomes
                            </div>
                        </div>


                        <div className=" sm:p-8 p-4 xl:p-12 lg:col-span-2 col-span-2   rounded-lg shadow-md text-gray-100  bg-black/30 shadow-inner"
                        //style={{ background: '#151515' }}
                        >
                            <div className="text-lg lg:text-2xl lg:mb-2 mb-2 font-medium text-center">Built for Business</div>
                            <div className="lg:text-3xl text-xl lg:mb-6 mb-2 font-medium text-center">Focus on Audience Needs - Not Pixels</div>




                            <div className='flex'>
                                <div className="flex-auto w-2/3 text-center">
                                    <img src="/images/b3-story-buttons.svg" className='w-full mx-auto max-h-32   scale-80  mt-4 mb-4 opacity-0'
                                        id="test2" />
                                </div>
                                <div className="flex-auto w-1/3 text-center">
                                    <img src="/images/b3-genai.svg" className='w-full mx-auto max-h-12 lg:max-h-16  scale-80 mt-8 lg:mt-12 mb-4 opacity-0'
                                        id="test2" />
                                </div>
                            </div>

                            <div className='flex'>
                                <div className=" xl:text-xl flex-auto w-2/3 lg:text-xl  sm:text-lg  text-zinc-400 text-center text-center">
                                    Templates make story creation a breeze, increasing time to focus on stakeholder needs
                                </div>
                                <div className=" xl:text-xl lg:text-xl flex-auto w-1/3  sm:text-lg  text-zinc-400 text-center text-center">
                                    AI Media Studio offers endless possibilities
                                </div>
                            </div>




                            {/* <div>TODO - MENTION AI STUDIO!!!!</div> */}
                        </div>
                    </div>
                </div>
            </div>

            <a name="how-it-works">
                <div
                    // style={{ position: 'absolute', top: bpHowItWorks, height: '100vh' }}

                    className='w-full
        mt-20
        md:mt-36
        lg:mt-40
        px-3
        md:px-6
        lg:px-12
        xl:px-40
        2xl:px-64
        3xl:px-80 
        transition-all '>
                    <div className="xl:text-5xl lg:text-4xl md:text-3xl  text-2xl  mb-10 font-medium text-center
                  bg-gradient-to-b from-[#ffffff] to-[#ccc] bg-clip-text text-center  leading-[1] text-transparent
                  font-medium 
                  text-center text-gray-50
                  ">How it Works</div>

                    <div className='text-center leading-20 text-lg lg:text-2xl text-zinc-400 mb-12 px-2 md:px-24'>
                        We brought everything you needed onto a single platform for corporate creators - optimized for how we love to create and consume content
                    </div>
                    <PublicSiteSlider />
                </div>
            </a>

            <a name="faqs">
                <div
                    className='w-full mx-auto 
        xl:px-40 
        2xl:px-64
        3xl:px-80
        transition-all 
        lg:px-24 
        px-3
        mt-20
        md:mt-36
        lg:mt-40
        '>
                    <div className="xl:text-5xl lg:text-4xl md:text-3xl  text-2xl  mb-6 
                      bg-gradient-to-b from-[#ffffff] to-[#ccc] bg-clip-text text-center  leading-[1] text-transparent
                      font-medium 
                      text-center text-gray-50">
                        FAQs
                    </div>
                    <div className=" ">

                        <div className="divide-y divide-white/10 px-8">
                            <dl className="mt-10 space-y-6 divide-y divide-white/10">
                                {faqs.map((faq) => (
                                    <Disclosure as="div" key={faq.question} className="pt-6">
                                        {({ open }) => (
                                            <>
                                                <dt>
                                                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                                                        <span className="lg:text-xl text-lg font-base leading-7 text-gray-50">{faq.question}</span>
                                                        <span className="ml-6 flex h-7 items-center">
                                                            {open ? (
                                                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                            ) : (
                                                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </Disclosure.Button>
                                                </dt>
                                                <Disclosure.Panel as="dd" className="mt-6 pr-12">
                                                    <p className="text-lg leading-7 text-gray-400  animate__animated animate__fadeIn ">{faq.answer}</p>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </dl>
                        </div>
                    </div>


                </div>
            </a>



            {/* Plans */}
            <a name="plans">
                <div
                    // style={{ position: 'absolute', top: bpPlans, height: '100vh' }}
                    className='w-full  mx-auto '>

                    <section className="
                mt-20
                md:mt-36
                lg:mt-36
                px-3
                md:px-6
                lg:px-12
                xl:px-40 

                2xl:px-64
                3xl:px-80
                transition-all 
        ">
                        <div className="mx-auto max-w-4xl text-center">
                            <div className="xl:text-5xl lg:text-4xl md:text-3xl text-2xl   mb-6 
                      bg-gradient-to-b from-[#ffffff] to-[#ccc] bg-clip-text text-center  leading-[1] text-transparent
                      font-medium 
                      text-center text-gray-50">
                                Plans

                            </div>
                        </div>
                        <p className="mx-auto mt-6 mb-8 max-w-3xl text-center lg:text-2xl text-xl leading-8 text-gray-400">
                            Designed for companies of all sizes, from startups to enterprises.  
                        </p>
                        <div className="isolate mx-auto  grid   grid-cols-1 gap-2 lg:mx-0 lg:max-w-none lg:grid-cols-2
                    rounded-lg  
                    ">
                            {tiers.map((tier, index) => (
                                <div key={index}
                                    className='pb-12 rounded-lg shadow-md text-gray-100 p-12  bg-black/30 shadow-inner'
                                >
                                    <div>
                                        <div className="relative items-center justify-between gap-x-4">
                                            <div id={tier.id} className="text-2xl font-semibold leading-8 text-left w-full text-gray-100">
                                                {tier.name}
                                            </div>
                                            <div className='mt-8'>
                                                {/* hover:scale-105 transition */}
                                                {/* <button type="submit"
                          className="flex-none w-full mx-auto
                          text-left
                          mb-12 rounded-md bg-zinc-700 px-3.5 py-2.5 text-base 
                          text-gray-50 shadow-sm hover:bg-zinc-600 focus-visible:outline focus-visible:outline-2 
                          hover:scale-1025
                          transition-all
                          focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                          {tier.linkText}
                        </button> */}
                                                <CompContact linkText={tier.linkText} headingText={tier.headingText} />
                                            </div>
                                        </div>
                                        <ul role="list" className="space-y-3 text-lg leading-6 text-gray-300">
                                            {tier.features.map((feature) => (
                                                <li key={feature} className="flex gap-x-3 text-gray-400">
                                                    <CheckIcon className="h-6 w-5 flex-none text-sky-300" aria-hidden="true" />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                </div>
                            ))}


                        </div>
                    </section>
                </div>
            </a>

            <CompHSChat></CompHSChat>
            {/* Footer + Stay in the Loop */}



        </>
    );
}

export default PublicHomeV2;