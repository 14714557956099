import React from 'react'

const Messages = () => {
  return (
    <>
      <div className="h-screen flex flex-col">

        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
              Messages
            </h2>
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0">
            {/* Upper right button here */}
          </div>
        </div>
        <div className="overflow-y-auto admin-scroll mb-12">
          <div className="mx-auto py-4">



          </div>
        </div>
      </div>
      {/* <AdminWelcomeStats /> */}
    </>
  )
}

export default Messages