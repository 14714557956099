import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties } from "react";
import { analytics, db } from '../firebase'
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    orderBy,
    doc,
    where,
    addDoc,
    deleteDoc, serverTimestamp
} from 'firebase/firestore'
import { UserAuth } from '../context/AuthContext';
import { Dialog, Transition, Listbox, Menu } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import BarLoader from "react-spinners/BarLoader";
import toast, { Toaster } from 'react-hot-toast';
import { ArcDiagram } from '../analytics/ArcDiagram'
import { LineChart, Line, Pie, PieChart, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Share = (props) => {
    const { user, logOut } = UserAuth();

    let [loading, setLoading] = useState(true)
    let [color, setColor] = useState("#0ca5e9")

    //const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false)
    const [openAdvanced, setOpenAdvanced] = useState(false)

    // Advanced
    const cancelButtonRef = useRef(null)
    const [stories, setStories] = useState([])
    const [shares, setShares] = useState([])

    const [shareName, setShareName] = useState('')
    const [shareDescription, setShareDescription] = useState('')
    const [shareStory, setShareStory] = useState('')

    const [sharesLoaded, setSharesLoaded] = useState(false)
    const [storiesLoaded, setStoriesLoaded] = useState(false)

    const createShare = async () => {

        if (shareName === '') {
            alert('Please enter a valid share name');
            return;
        }

        if (shareDescription === '') {
            alert('Please enter a valid share description');
            return;
        }

        // Grab the user ID...
        let userOwnerKey = user?.uid

        const docRef = await addDoc(collection(db, 'shares'), {
            name: shareName,
            description: shareDescription,
            userPhotoURL: user?.photoURL,
            userEmail: user?.email,
            userDisplayName: user?.displayName,
            messageUserId: user?.uid,
            inviteTitle: '',
            inviteMessage: '',
            inviteAddresses: [
                'mike@brevit3.com', 'olive@brevit3.com'
            ],
            storyName: shareStory.name,
            storyId: shareStory.id,
            createdAt: serverTimestamp(),
            lastModified: serverTimestamp(),
            //analyticsViewTotal: 0,
            analyticsLastViewed: serverTimestamp(),
            imageStoryBackground: shareStory.imageStoryBackground, // '/media/icon/B3-Image-Placeholder.png', // TODO - add default image
            imageStoryLogo: shareStory.imageStoryLogo, //'/media/icon/B3-Image-Placeholder.png', // TODO - add default image
            role: {
                //[userOwnerKey]: 'owner',
                owner: [userOwnerKey], //t8X5CAmys8W88z5rOQWEQaIWhHn2
                commenter: [userOwnerKey],
                writer: [userOwnerKey],
                reader: [userOwnerKey],
            },
        });

        // Reset form fields
        setShareName('');
        setShareDescription('');
        //navigate('/app/stories/' + docRef.id + '/edit/0')
    };

    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {

                const q = query(collection(db, 'shares'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let sharesArr = [];
                    querySnapshot.forEach((doc) => {
                        sharesArr.push({ ...doc.data(), id: doc.id })
                    });
                    setShares(sharesArr)
                    setSharesLoaded(true)
                    console.log('Shares loaded')
                    console.log(sharesArr)
                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])



    // >> Analytics
    const [analytics, setAnalytics] = useState([])
    const [analyticsCount, setAnalyticsCount] = useState([])
    const [analyticsLoaded, setAnalyticsLoaded] = useState(false)





    // const engagementData = [
    //     {
    //         "windowHeight": 1198,
    //         "storyName": "Tutorial Experience (John Brunswick)",
    //         "sceneIdPrior": 0,
    //         "sceneName": "In Store",
    //         "name": "In Store",
    //         "sceneIndex": 2,
    //         "windowWidth": 1340,
    //         "id": "izHBShSsaTQpxvF2IM9o",
    //         "from": 0,
    //         "storyId": "YoK7ny1XaYoIWI3VPt40",
    //         "engagementTime": 51,
    //         "to": 2,
    //         "userAgent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/125.0.0.0 Safari/537.36",
    //         "engagementTags": [
    //             "analytics",
    //             "sales"
    //         ],
    //         "sceneId": "49jVQtgnGrClSwaMgnJy",
    //         "createdAt": {
    //             "seconds": 1718489457,
    //             "nanoseconds": 742000000
    //         }
    //     },
    //     // Add more engagement objects here...
    // ];

    function countEngagementTags(engagementData) {
        const tagCountMap = new Map();
        const tagTimeMap = new Map();
        engagementData.forEach((engagement) => {
            engagement.engagementTags.forEach((tag) => {
                if (tagCountMap.has(tag)) {
                    tagCountMap.set(tag, tagCountMap.get(tag) + 1);
                    tagTimeMap.set(tag, tagTimeMap.get(tag) + engagement.engagementTime);
                } else {
                    tagCountMap.set(tag, 1);
                    tagTimeMap.set(tag, engagement.engagementTime);
                }
            });
        });
        const tagCountArray = Array.from(tagCountMap.entries()).map(([name, value]) => ({ name, value, totalEngagementTime: tagTimeMap.get(name) }));
        return tagCountArray;
    }

    // const tagCounts = countEngagementTags(engagementData);
    // console.log(tagCounts);

    // function countEngagementTags(engagementData) {
    //     const tagCountMap = new Map();

    //     engagementData.forEach((engagement) => {
    //         engagement.engagementTags.forEach((tag) => {
    //             if (tagCountMap.has(tag)) {
    //                 tagCountMap.set(tag, tagCountMap.get(tag) + 1);
    //             } else {
    //                 tagCountMap.set(tag, 1);
    //             }
    //         });
    //     });

    //     const tagCountArray = Array.from(tagCountMap.entries());

    //     return tagCountArray;
    // }

    // const tagCounts = countEngagementTags(engagementData);
    // console.log(tagCounts);










    function aggregateItems(items) {
        // Create a map to store the count of each id
        const itemCountMap = new Map();

        // Iterate through the items array
        items.forEach(item => {
            if (itemCountMap.has(item.sceneIndex)) {
                // If the id is already in the map, increment the count
                const existingItem = itemCountMap.get(item.sceneIndex);
                existingItem.count += 1;
            } else {
                // If the id is not in the map, add it with a count of 1
                itemCountMap.set(item.sceneIndex, { id: item.sceneIndex, name: item.name, count: 1 });
            }
        });

        // Convert the map to an array
        const aggregatedItems = Array.from(itemCountMap.values());

        return aggregatedItems;
    }

    function countUniqueCombinations(items) {
        const combinationsMap = {};

        items.forEach(item => {
            const key = `${item.from}-${item.to}`;
            if (combinationsMap[key]) {
                combinationsMap[key].count++;
            } else {
                combinationsMap[key] = { from: item.from, to: item.to, count: 1 };
            }
        });

        return Object.values(combinationsMap);
    }

    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {

                const q = query(collection(db, 'analytics')); //, orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));

                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let analyticsArr = [];
                    querySnapshot.forEach((doc) => {
                        analyticsArr.push({ ...doc.data(), id: doc.id })
                    });
                    setAnalytics(analyticsArr)

                    const analyticsData = {
                        "nodes": aggregateItems(analyticsArr),
                        "links": countUniqueCombinations(analyticsArr),
                    }

                    const tagCounts = countEngagementTags(analyticsArr);

                    setAnalytics(analyticsData)
                    setAnalyticsCount(tagCounts)

                    console.log('///////////  ARRAY COUNT FOR ANALYTICS ///////////')

                    setTimeout(() => {
                        setAnalyticsLoaded(true)
                    }, 500)

                    // console.log('Analytics TAGS!@!!@!@!@!')
                    // console.log(countEngagementTags(analyticsArr))

                    // engagementTime
                    // tag count
                    // tag duration

                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])
    // // Analytics


    useEffect(() => {
        console.log('Analytics Count')
        console.log(analyticsCount)
    }, [analyticsCount])


    useEffect(() => {
        setTimeout(() => {
            if (user?.uid) {
                const q = query(collection(db, 'stories'), orderBy('lastModified', 'desc'), where("role.owner", "array-contains", user?.uid));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let storiesArr = [];
                    querySnapshot.forEach((doc) => {
                        storiesArr.push({ ...doc.data(), id: doc.id })
                    });
                    setStories(storiesArr);
                    setStoriesLoaded(true)
                    console.log('Stories loaded')
                    console.log(storiesArr)
                });
                return () => unsubscribe()
            }
        }, 250)
    }, [user])


    // // Delete todo
    // const deleteStory = async (id) => {
    //     const storyRef = doc(db, 'stories', id);
    //     await deleteDoc(storyRef)
    // }

    // const navigate = useNavigate()

    const override = {
        width: "50%",
        margin: "auto",
        marginTop: "20%",
    }

    // const [openStoryEdit, setOpenStoryEdit] = useState(false)
    // const [storyActiveEdit, setStoryActiveEdit] = useState()
    // const [storyActivePerm, setStoryActivePerm] = useState()

    return (
        <div className="h-screen flex flex-col">

            <div className="md:flex md:items-center md:justify-between ">
                <div className="min-w-0 flex-1">
                    <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-2xl mb-4 sm:tracking-tight">
                        Sharing
                    </h2>
                </div>
            </div>

            <Transition.Root show={openAdvanced} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenAdvanced}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto"
                    >
                        <div className="flex min-h-full items-end justify-center text-center sm:items-center px-10">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 w-1/3 m-40 sm:p-10">
                                    <div>
                                        <div className="text-left">
                                            <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                                                Create New Story
                                            </Dialog.Title>
                                            <div className="">
                                                {/* <form onSubmit={createStory} className=''> */}
                                                <div className="mt-12  pb-4 text-gray-300">
                                                    Available with enterprise or team / project functionality
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-1 sm:gap-3 pt-4">
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center 
                          rounded bg-gray-500 px-3 py-2 bg-zinc-800
                          text-base font-base text-gray-100 shadow-sm 
                          hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpenAdvanced(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto"
                    >
                        <div className="flex min-h-full items-end justify-center text-center sm:items-center px-10">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >

                                <Dialog.Panel className="relative transform overflow-hidden rounded-md bg-zinc-900 px-4 pb-2 pt-2 text-left
               shadow-xl transition-all sm:my-2 sm:w-full sm:w-none m-40 sm:p-10">
                                    <div>
                                        <div className="text-left">
                                            <Dialog.Title as="h3" className="text-xl leading-6 text-gray-100">
                                                Share
                                            </Dialog.Title>


                                            <div className=" mt-8">
                                                <Menu as="div" className="relative inline-block  w-full text-left">
                                                    <div className=" admin-dropdown rounded-md">
                                                        <Menu.Button className="inline-flex w-full  gap-x-1.5 rounded admin-bg-side admin-component pl-4 py-4 text-base font-normal text-gray-200   ">

                                                            {shareStory ? shareStory.name : 'Select Story to Share'}

                                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 absolute right-6" aria-hidden="true" />
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute left-0 z-10 w-full text-gray-200 origin-top rounded-md bg-black shadow-lg focus:outline-none">
                                                            <div className="section-scroll h-64 grid grid-cols-1 gap-1 px-1">
                                                                {stories.map((story, index) => (
                                                                    <div
                                                                        key={story.id}
                                                                        className={`w-full p-2 rounded  ${story.id == 'changeme' ? 'bg-white/20' : 'hover:bg-white/20 cursor-pointer'}`}
                                                                        //onClick={() => updateBackgroundMat(mat.value)} key={mat.value} 
                                                                        onClick={() => {
                                                                            //console.log('clicked: ' + story.id)
                                                                            setShareStory(story)
                                                                        }}
                                                                    >
                                                                        {story.name}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>

                                                <div className="mt-2  pb-4">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-8 focus:outline-none">
                                                        <input
                                                            type="text"
                                                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                            placeholder="Share Name"
                                                            value={shareName}
                                                            onChange={(e) => setShareName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-span-full">
                                                    <div className="mt-2">
                                                        <textarea
                                                            placeholder="Description"
                                                            rows={4}
                                                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                  ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                            value={shareDescription}
                                                            onChange={(e) => setShareDescription(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mt-2  pb-4">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-600 focus-within:ring-2
                focus-within:ring-inset focus-within:ring-sky-400 mt-8 focus:outline-none">
                                                        <input
                                                            type="text"
                                                            className="block w-full rounded-md border-0 p-4 bg-transparent text-gray-100 shadow-sm ring-1 ring-inset 
                              ring-zinc-700 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-400 sm:text-base sm:leading-6 focus:outline-none"
                                                            placeholder="email@yourcompany.com"
                                                        //value={shareName}
                                                        //onChange={(e) => setShareName(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-20 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 pt-4">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded bg-sky-500 px-3 py-2
                     text-base font-base text-white shadow-sm hover:bg-sky-400 focus-visible:outline
                      focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                                            onClick={() => {
                                                createShare();
                                                setOpen(false);
                                            }}
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center 
                          rounded bg-gray-500 px-3 py-2 bg-zinc-800
                          text-base font-base text-gray-100 shadow-sm 
                          hover:bg-zinc-700 sm:col-start-1 sm:mt-0"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <button onClick={() => setOpen(true)}
                type="button"
                className=" border border-sky-500   hover:bg-gray-900 w-full flex  aspect-video rounded items-center justify-center font-medium text-sky-500  w-full aspect-video rounded
                 hover:border-sky-500 hover:border
                aspect-h-4 aspect-w-2 rounded bg-story-preview duration-300 border border-gray-800 shadow  h-20
                ">
                <div className="flex items-stretch">
                    <div className="py-8">  </div>
                    <div className="">
                        <img src="/media/icon/new.svg" className="h-18 w-8 margin-auto" />
                        <div className="mt-4 md:text-sm  text-sm">New Share</div>
                    </div>
                    <div className="py-8"></div>
                </div>
            </button>

            <div className="overflow-y-auto admin-scroll mb-12 mt-12">
                <div className="mx-auto ">

                    <div className="w-full">
                        <BarLoader
                            color={color}
                            loading={!storiesLoaded}
                            cssOverride={override}
                            size={450}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>

                    <div className={"grid grid-cols-1 gap-y-4 text-gray-100"}>

                        {shares.map((share, index) => (
                            <div key={index} className="bg-black/20 rounded-md p-6">
                                <div className="flex justify-between">
                                    <div className="flex gap-4">
                                        {/* <img src={share.imageStoryLogo} className="w-24 rounded-md " /> */}

                                        <div className="bg-contain bg-center w-24 bg-no-repeat mr-2"
                                            style={{ backgroundImage: `url(${share.imageStoryLogo})` }}
                                        ></div>

                                        <div>
                                            <div className="text-lg text-gray-50  ">{share.name}</div>
                                            <div className="text-sm mt-1 text-gray-400">{share.description}</div>
                                            <div className="text-sm mt-1 text-gray-400">
                                                {share.createdAt?.toDate()?.toDateString()}
                                            </div>
                                            <div className="text-sm mt-1 text-gray-400">Last Viewed</div>
                                        </div>
                                    </div>
                                    <div className="flex gap-4">

                                        <a href={'/app/stories/' + share.storyId + '/edit/0'}
                                            target="_blank"
                                            className="  justify-center rounded bg-white/10 px-3 py-2"
                                            onClick={() => {
                                                //navigate('/app/stories/' + story.id + '/edit/0')
                                            }}
                                        >
                                            Edit Source
                                            {/* {share.storyId} */}
                                        </a>

                                        <a href={'/share/' + share.storyId}
                                            target="_blank"
                                            className="  justify-center rounded bg-white/10 px-3 py-2"
                                            onClick={() => {
                                                //navigate('/app/stories/' + story.id + '/edit/0')
                                            }}
                                        >
                                            Open
                                            {/* {share.storyId} */}
                                        </a>

                                    </div>
                                </div>
                                <div className="w-full mt-4">
                                    {analyticsLoaded ?
                                        <>
                                            <ArcDiagram
                                                data={analytics}
                                            />

                                            <div className="w-full mt-4">
                                                <div key={index} className="flex justify-between text-gray-200">
                                                    <div className="text-gray-300">Topic</div>
                                                    <div className="text-gray-300">Occurances</div>
                                                    <div className="text-gray-300">Duration</div>
                                                </div>
                                                {analyticsCount.map((tag, index) => (
                                                    <div key={index} className="flex justify-between text-gray-500">
                                                        <div className="w-1/3">{tag.name}</div>
                                                        <div className="w-1/3">{tag.value}</div>
                                                        <div className="w-1/3">{tag.totalEngagementTime}</div>
                                                    </div>
                                                ))
                                                }
                                            </div>

                                            {/* <Pie
                                                dataKey="value"
                                                isAnimationActive={false}
                                                data={analyticsCount}
                                                cx={400}
                                                cy={400}
                                                outerRadius={80}
                                                //fill="#8884d8"
                                                //label
                                            /> */}
                                        </>
                                        : null}
                                </div>
                            </div>
                        ))}
                    </div>

                    <Toaster />

                </div>
            </div>
        </div>
    )
}

export default Share