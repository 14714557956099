import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";
import { useParams, useLocation } from "react-router-dom";

import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'

const NavigationProcessChevron = (props) => {

    const [sceneData, setSceneData] = useContext(SceneContext)
    const [storyData, setStoryData] = useContext(StoryContext)
    const [sessionData, setSessionData] = useContext(SessionContext)


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const ToggleTOC = () => {
        console.log('Toggle TOC')
    }

    return (
        <div className="flex NavigationProcessChevron 2xl:mt-0 xl:mt-4 md:mt-2">
            {/* <div className="flex-none mr-8">
                <img src="/media/demo/mfg/Salesforce.com_logo.svg.png" className="  navlogo" />
            </div> */}

            {/* <div className="flex-none mr-8">
                    <img src="/media/icon/noun-org-chart-3445332-FFFFFF.svg" className="w-20" />
                </div> */}

            {sceneData.filter(step => step.navOmitMain == true).map(filteredScene => (
                <div className="flex  mr-4 md:mr-2 items-center px-2 py-1 bg-gray-700 hover:bg-gray-800 xl:h-12 2xl:h-16 transition-all rounded-md" onClick={() => {
                    props.updateSceneFunction(filteredScene.order)
                }} >

                    <img src={filteredScene.navButtonIconURL} className="2xl:w-10 xl:w-8 p-1 md:w-6 md:p-0" />
                    <div className="mr-2 text-gray-300  2xl:text-base xl:text-sm ml-2 md:text-sm">{filteredScene.name}</div>

                </div>
            ))}


            {/* {sceneData.map((step, stepIdx) => (

                <div className="flex-none mr-8">
                    <img src="/media/icon/noun-org-chart-3445332-FFFFFF.svg" className="w-20" />
                </div>

            ))} */}

            <div className="grow h-14 mr-24">
                <nav aria-label="Progress">
                    <ol role="list" className="divide-y divide-gray-300 rounded-md flex divide-y-0 bg-zinc-600 shadow
                    
                    xl:h-12 2xl:h-16 md:h-14
                    ">
                        {/* <li className="p-2">
                                <img src="/media/demo/mfg/Salesforce.com_logo.svg.png" className=" w-24" />
                            </li> */}


                        {/* {sceneData.map((step, stepIdx) => ( */}
                        {sceneData.filter(item => item.navOmitMain != true).map((step, stepIdx) => (
                            <li key={step.name} className="relative md:flex md:flex-1">
                                {step.order < sessionData.activeIndex ? (
                                    <div onClick={() => {
                                        props.updateSceneFunction(step.order)
                                    }} className="group flex w-full items-center px-2 py-1 previous">

                                        <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1">
                                            <div className="col-span-2 nav-heading">
                                                <span className="ml-2 2xl:text-base xl:text-sm">{step.name}</span>
                                            </div>
                                            {/* <div className="col-span-2 mt-1 nav-subheading">
                                                <span className="ml-2">Subheading here</span>
                                            </div> */}
                                        </div>
                                    </div>
                                ) : sessionData.activeIndex == step.order ? (
                                    <div onClick={() => {
                                        props.updateSceneFunction(step.order)
                                    }} className="flex items-center px-2 py-1 current" aria-current="step">
                                        <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1">
                                            <div className="col-span-2 nav-heading">
                                                <span className="ml-2 2xl:text-base xl:text-sm">{step.name}</span>
                                            </div>
                                            {/* <div className="col-span-2 mt-1 nav-subheading">
                                                <span className="ml-2">Subheading here</span>
                                            </div> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div onClick={() => {
                                        props.updateSceneFunction(step.order)
                                    }} className="group flex items-cente next">
                                        <span className="flex items-center px-2 py-1">
                                            <div className="grid grid-rows-2 grid-flow-col gap-1 mt-1">
                                                <div className="col-span-2 nav-heading">
                                                    <span className="ml-2 2xl:text-base xl:text-sm">{step.name}</span>
                                                </div>
                                                {/* <div className="col-span-2 mt-1 nav-subheading">
                                                <span className="ml-2">Subheading here</span>
                                            </div> */}
                                            </div>

                                        </span>
                                    </div>
                                )}
                                {stepIdx !== sceneData.filter(item => item.navOmitMain != true).length - 1 ? (
                                    <>
                                        <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                            <svg
                                                className="h-full w-full text-gray-300"
                                                viewBox="0 0 22 80"
                                                fill="none"
                                                preserveAspectRatio="none"
                                            >
                                                <path
                                                    d="M0 -2L20 40L0 82"
                                                    vectorEffect="non-scaling-stroke"
                                                    stroke="currentcolor"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                    </>
                                ) : null}
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
            {/* <div className="flex-none w-12 h-12 bg-gray-600 rounded p-2 m-2 ml-4 mr-0 hover:bg-gray-700">
              <button className="button-playmode pip" onClick={ToggleSideMode}>
                <img src="/media/icon/noun-picture-in-picture-3643905-FFFFFF.svg" />
              </button>
            </div> */}
            

            {(!location.pathname.includes('/play/')) ?
            <div className="flex-none w-12 h-12 bg-gray-600 rounded-md p-2 m-2 ml-4 hover:bg-gray-700 mt-2">
                <button className="button-playmode" key={'closePreso'} onClick={props.TogglePlayMode}>
                    <img src="/media/icon/noun-down-arrow-1035684-FFFFFF.svg" />
                </button>
            </div>
            : null}

            
        </div>

    )
}

export default NavigationProcessChevron