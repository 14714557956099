import { React, useState } from 'react'
import '/Style.css'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import FormWaitlist from './FormWaitlist'
import FormContact from './FormContact'
import CompSiteNav from './CompSiteNav'



const stats = [
    { id: 2, name: 'Unlimited for Duration', value: 'Support' },
    { id: 1, name: 'Custom 3D Models', value: '2' },
    { id: 3, name: 'Unlimited Usage', value: 'AI Media Studio' },
    { id: 4, name: 'Free Usage', value: '30 days' },
]



const PublicSalesforce = () => {

    return (
        <>

            <CompSiteNav />


            <div className="relative isolate overflow-hidden py-24 sm:py-32">
                {/* <img
        src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2850&q=80&blend=111827&blend-mode=multiply&sat=-100&exp=15"
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      /> */}
                <div className="relative mx-auto max-w-7xl px-6 lg:px-8 sm:flex">

                    <div className="w-3/4 pr-20">
                        <h2 className="text-2xl font-base leading-8 text-sky-400">Welcome Salesforce Innovators</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            Innovator Program
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Brevit3 is offering Innovator Program access to select groups within Salesforce.  The program is designed to help SEs deliver high quality and impactful experiences.  After the program concludes members will retain access to Stories that have been created on the platform.
                        </p>
                    </div>

                    <div className='w-1/4'>
                        <img src="/media/demo/mfg/Salesforce.com_logo.svg.png" className='w-40 mt-20' />
                    </div>
                </div>
                <div className='relative mx-auto max-w-7xl px-6 lg:px-8'>
                    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                        {stats.map((stat) => (
                            <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/10 pl-6">
                                <dt className="text-sm leading-6">{stat.name}</dt>
                                <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>



            <div className="relative isolate overflow-hidden b3-site-bg ">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
                        <div>
                            <p className="text-lg font-semibold leading-8 tracking-tight text-sky-400">
                                Innovator Program Signup
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-200 mb-8">
                                Please fill out the form below and a member of the Brevit3 team will reach out to you to get you started.
                            </p>
                            {/* <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl mb-14">
                Signup
              </h1> */}
                            <FormContact />
                        </div>
                        <div className="pl-0 md:pl-40 mt-14">
                            {/* <img src="/media/site/B3-Contact-Locations.png" className='mx-auto' /> */}
                        </div>
                    </div>
                </div>
            </div>




        </>

    )
}

export default PublicSalesforce