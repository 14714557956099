import { React, useState } from 'react'
import '/Style.css'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import FormWaitlist from './FormWaitlist'
import CompSiteNav from './CompSiteNav'

const navigation = {
  solutions: [
    { name: 'Home', href: '/' },
    //{ name: 'Pricing', href: '/#pricing' },
    { name: 'About', href: '/about' },
    { name: 'Contact Us', href: '/contact' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    //   { name: 'Blog', href: '#' },
    //   { name: 'Jobs', href: '#' },
    //   { name: 'Press', href: '#' },
    //   { name: 'Partners', href: '#' },
  ],
  legal: [
    //   { name: 'Claim', href: '#' },
    //   { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'



const PublicAbout = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };

  return (
    <>

 

      {/* <CompSiteNav /> */}

      <div className="mt-20   py-24 sm:py-32">
 
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-7xl lg:mx-0">
            <p className="text-lg font-semibold leading-8 tracking-tight text-sky-400">
              Our Mission
            </p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">
              Help You Deliver World-Class Technical Sales Experiences
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-400">
            Delivering an incredible presentation can transform 45 minutes of valuable customer time into excitement, meaning and alignment around your solution. Helping you to stand out from competition and open the door to deeper partnerships.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-400">
            In today’s world of cost reduction, automation, endless content and asynchronous communication, it has become more difficult than ever to gain our customers attention. Just as having the right answers is no longer enough to stand apart, it is essential to earn attention to help win business.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-400">Sales Engineers face high expectations, short runways and limited resources. Often having to pivot at a moment’s notice to produce a new demo, video or prepare for an executive meeting.
Traditional tools like PowerPoint and Google Slides are great, but were created in the spirit of academia and don’t offer glanceable, easy to digest experiences. Product tours are informative, but can struggle to engage. Juggling all of these tools is exhausting and eats into time better spent focused on deepening our understanding of the customer.

            </p>
            <p className="mt-6 text-lg leading-8 text-gray-400">
            As more selling occurs through digital channels, how can we not only keep up, but go above and beyond?
            </p>

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-200">A Better Way - Earning Customer Engagement</h2>
            <p className="mt-6 text-lg leading-8 text-gray-400">
              Though technology changes, our human nature remains.  We started Brevit3 to give technical sales teams a way to rapidly package their expertise and knowledge, focusing on visuals and communication designed to speak to this nature.  Helping you produce engaging and easy to understand content that people want to share.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-400">
              Our founder created the Salesforce Innovation Center’s #1 most requested session by sales teams to inspire and educate key customer executives during their visits.  We have combined this expertise gained through hundreds of engaging, hands-on C-Suite and board level experiences, along with decades of storytelling, design, consulting and behavioral science expertise to create a superpower for sales consultants to amplify their stories in memorable and concise ways.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-400">
              Brevit3 is designed for today and beyond and we hope that you can join us on our mission.
            </p>


          </div>
        
        </div>
      </div>




    </>

  )
}

export default PublicAbout