import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, Bars3Icon } from '@heroicons/react/24/outline'

const navigation = {
    solutions: [
      { name: 'Home', href: '/' },
      { name: 'Pricing', href: '/#pricing' },
      //{ name: 'Book', href: '/earning-engagement'},
      { name: 'About', href: '/about' },
      { name: 'Contact Us', href: '/contact' },
    ],
    support: [
      { name: 'Pricing', href: '#' },
      { name: 'Documentation', href: '#' },
      { name: 'Guides', href: '#' },
      { name: 'API Status', href: '#' },
    ],
    company: [
      { name: 'About', href: '#' },
      //   { name: 'Blog', href: '#' },
      //   { name: 'Jobs', href: '#' },
      //   { name: 'Press', href: '#' },
      //   { name: 'Partners', href: '#' },
    ],
    legal: [
      //   { name: 'Claim', href: '#' },
      //   { name: 'Privacy', href: '#' },
      { name: 'Terms', href: '#' },
    ],
    social: [
      {
        name: 'Twitter',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: 'YouTube',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  }




const CompSiteNav = (props) => {
    const [open, setOpen] = useState(false)

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


    return (
        <>

            <nav className="mx-auto flex  max-w-7xl items-center justify-between p-6 lg:px-8 pt-10 " aria-label="Global">
                <div className="flex sm:w-full lg:flex-1">
                    <a href="/" className="lg:-m-1.5 p-1.5 sm:mx-auto">
                        <span className="sr-only">Brevit3, Inc.</span>
                        <img className="h-10 w-auto" src="/media/site/B3-Logo-Header.png" alt="" />
                    </a>
                </div>
                <div className="flex lg:hidden sm:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
                        onClick={() => setMobileMenuOpen(true)}>
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    <div className="hidden lg:flex lg:gap-x-12 mr-8">
                        {navigation.solutions.map((item) => (
                            <a key={item.name} href={item.href} className="text leading-6 text-gray-100">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    {/* <div className="text-base leading-6 text-gray-100">
                        <a href="/signin" className="flex justify-between">
                            <h2 className="mr-2">Login</h2>
                            <img src="/media/site/noun-login-1926132-676767.svg" className='w-7' />
                        </a>
                    </div> */}
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto b3-site-bg px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Brevit3, Inc.</span>
                            <img
                                className="h-8 w-auto"
                                src="/media/icon/Brevit3-Logo-Home.png"
                                alt=""
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-400"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/25">
                            <div className="space-y-2 py-6">
                                {navigation.solutions.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-800"
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </div>
                            <div className="py-6">
                                <a
                                    href="/signin"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-lg font-semibold leading-7 text-gray-200 hover:bg-gray-800"
                                >
                                    Log in
                                </a>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </>
    )
}
export default CompSiteNav