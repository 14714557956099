import React, { useRef, useState, useContext, useEffect, createContext, Fragment } from "react";

import { SessionContext, SceneContext, StoryContext } from '/AppStoryPlayer'

const NavigationTimelineSolo = (props) => {

    const [sceneData, setSceneData] = useContext(SceneContext)
    const [storyData, setStoryData] = useContext(StoryContext)
    const [sessionData, setSessionData] = useContext(SessionContext)


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const ToggleTOC = () => {
        console.log('Toggle TOC')
    }

    return (
        <>

            {/* <div id="side-toc">
                <nav className="flex flex-1 flex-col" aria-label="Sidebar">
                    <ul role="list" className="-mx-2 space-y-1">
                        {sceneData.map((item, stepIdx) => (
                            <li key={item.name}>
                                <a
                                    //href={item.href}
                                    className={(
                                        sessionData.activeIndex == stepIdx ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                        'group flex gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold'
                                    )}
                                >
                                    {item.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div> */}


            <div className="navigation-segment-wrapper NavigationTimelineSolo">
                <div className="flex mode-timeline">
                    <div className="flex-none w-12 h-12 bg-gray-100 rounded p-2 m-2 hover:bg-gray-200 -mt-4">
                        <button className="button-playmode " onClick={ToggleTOC}>
                            <img src="/media/icon/noun-table-of-contents-1010520.svg" className="o5" />
                        </button>
                    </div>
                    <div className="grow h-14">
                        <div className="navigation-progress-wrapper">
                            <div className="navigation-progress-meter" id="navigation-progress-meter"></div>
                        </div>
                    </div>
                    {/* <div className="flex-none w-12 h-12 bg-gray-100 rounded p-2 m-2 ml-4 mr-0 hover:bg-gray-200 -mt-4">
                <button className="button-playmode pip" onClick={ToggleSideMode}>
                    <img src="/media/icon/noun-picture-in-picture-3643905-111111.svg" className="o5" />
                </button>
            </div>*/}
                    <div className="flex-none w-12 h-12 bg-gray-100 rounded p-2 m-2 hover:bg-gray-200 -mt-4">
                        <button className="button-playmode " onClick={props.TogglePlayMode}>
                            <img src="/media/icon/noun-down-arrow-1035684-111111.svg" className="o5" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NavigationTimelineSolo 