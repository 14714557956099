// Import the functions you need from the SDKs you need

//import * as firebase from "firebase";

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getFunctions,
  httpsCallable,
} from "firebase/functions";



const firebaseConfig = {
  apiKey: "AIzaSyBxUg3Jgr0DquxOV9xVQ4FZEp4IFcGL7Vw",
  authDomain: "brevit3-57b00.firebaseapp.com",
  projectId: "brevit3-57b00",
  storageBucket: "brevit3-57b00.appspot.com",
  messagingSenderId: "218055985243",
  appId: "1:218055985243:web:f4e9c752f8eaa94a94577e",
  measurementId: "G-PL5BNR9PVT"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
//export const storage = getStorage(app);
export const auth = getAuth(app)
export const db = getFirestore(app)


export default app

//logEvent(analytics, 'notification_received');
// console.log('FUNCTIONS....!!!')
// console.log(functions)


//app.storage();
//app.fire


// firebase.initializeApp(firebaseConfig);
// const projectStorage = firebase.storage();
// const projectFirestore = firebase.firestore();
// const timestamp = firebase.firestore.FieldValue.serverTimestamp;

// export { projectStorage, projectFirestore, timestamp };