import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "Video Setup 101 - Camera Position",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "Video Setup 101 - Lighting",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  {
    question: "Video Setup 101 - Audio",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
]

const Training = () => {
  return (
    <>
      <div className="h-screen flex flex-col">

        <div className="md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
              Training
            </h2>
            <p className='text-gray-300 mb-12 mt-8 w-3/4'>
              The following provides a step-by-step overview of how the tutorial Story was created.  Though updates have occured since, it provides the most comprehensive look at core components that are used to create stories on the platform.
            </p>
          </div>
          <div className="mt-4 flex md:ml-4 md:mt-0">
            {/* Upper right button here */}
          </div>
        </div>
        
          <iframe className="w-3/4 aspect-video mb-20 rounded-md border-black/20" src="https://www.youtube.com/embed/hcTaRMD2obc?si=Ssg3TDbu7qwceyy5" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        
      </div>
      {/* <AdminWelcomeStats /> */}
    </>
  )
}

export default Training