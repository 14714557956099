import React, { useEffect } from "react";

const FormNewsletter = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '40148401',
                    region: "na1",
                    formId: 'b1b99dc1-dbc9-423f-9ad6-75e69f1414be',
                    target: '#NewsletterForm'
                })
            }
        });
    }, []);

    return (
        <div>
            <div id="NewsletterForm"></div>
        </div>
    );

}

export default FormNewsletter;