import React, { useState } from 'react';
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore'
import { db } from '../firebase'

const ModelAddForm = () => {
  const [category, setCategory] = useState('');
  const [guid, setGuid] = useState('');
  const [model, setModel] = useState('');
  const [name, setName] = useState('');
  const [org, setOrg] = useState([]);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [posZ, setPosZ] = useState(0);
  const [scale, setScale] = useState(1);

  const handleOrgChange = (index, value) => {
    const updatedOrg = [...org];
    updatedOrg[index] = Number(value);
    setOrg(updatedOrg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPos = { x: Number(posX), y: Number(posY), z: Number(posZ) };
    //collection("models").add({
    const docRef = addDoc(collection(db, "models"), {
      category,
      guid,
      model,
      name,
      org: org.map(Number),
      pos: newPos,
      scale: Number(scale),
    });
    // Reset form fields after submission
    setCategory('');
    setGuid('');
    setModel('');
    setName('');
    setOrg([]);
    setPosX(0);
    setPosY(0);
    setPosZ(0);
    setScale(1);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4 mt-20 text-gray-300 w-full bg-black/20 p-4 rounded">
      <div className='flex gap-2 w-full '>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Name"
          className="flex-auto  p-2 text-gray-500 bg-black/20 rounded "
        />
        <input
          type="text"
          value={model}
          onChange={(e) => setModel(e.target.value)}
          placeholder="Model (with glb extension)"
          className="flex-auto   bg-black/20  p-2 text-gray-500 rounded "
        />
        <input
          type="text"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          placeholder="Category"
          className="flex-auto   bg-black/20  p-2 text-gray-500 rounded "
        />
        <input
          type="text"
          value={guid}
          onChange={(e) => setGuid(e.target.value)}
          placeholder="GUID"
          className="flex-auto   bg-black/20  p-2 text-gray-500 rounded "
        />

      </div>


      <div className='flex gap-2'>
        Org (Array of Numbers):
        {[...Array(2)].map((_, index) => (
          <input
            key={index}
            type="number"
            value={org[index] || ''}
            onChange={(e) => handleOrgChange(index, e.target.value)}
            placeholder={`Org[${index}]`}
            className=" bg-black/20  p-2 text-gray-500 rounded "
          />
        ))}
      </div>
      <div className='flex gap-2'>
        Pos (x, y, z):
        <input
          type="number"
          value={posX}
          onChange={(e) => setPosX(e.target.value)}
          placeholder="X Position"
          className=" bg-black/20   p-2 text-gray-500 rounded "
        />
        <input
          type="number"
          value={posY}
          onChange={(e) => setPosY(e.target.value)}
          placeholder="Y Position"
          className=" bg-black/20   p-2 text-gray-500 rounded "
        />
        <input
          type="number"
          value={posZ}
          onChange={(e) => setPosZ(e.target.value)}
          placeholder="Z Position"
          className=" bg-black/20   p-2 text-gray-500 rounded "
        />

      <div  className='flex gap-2'>
      Scale: 
      <input
        type="number"
        value={scale}
        onChange={(e) => setScale(e.target.value)}
        placeholder="Scale"
        className=" bg-black/20  text-gray-500 p-2 rounded "
      />
      </div>      </div>
      <button type="submit" className="bg-sky-500 hover:bg-sky-400 rounded text-white p-2">Add Model</button>
    </form>
  );
};

export default ModelAddForm;