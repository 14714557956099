import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
//import FormWaitlist from './FormWaitlist'
import HubspotForm from 'react-hubspot-form'

const CompRegister = () => {
    const [open, setOpen] = useState(false)

    // useEffect(() => {
    //     // const script = document.createElement('script');
    //     // script.src = 'https://js.hsforms.net/forms/v2.js';
    //     // document.body.appendChild(script);

    //     // script.addEventListener('load', () => {
    //     //     // @TS-ignore
    //     //     if (window.hbspt) {
    //     //         // @TS-ignore
    //     //         window.hbspt.forms.create({
    //     //             portalId: '40148401',
    //     //             formId: 'e8fefc4e-87da-45a9-b9e9-54e2bc49890c',
    //     //             target: '#WaitlistForm'
    //     //         })
    //     //     }
    //     // });
    //     //console.log('FLIP.... '+ open)
    // }, [open]);

    return (
        <>
            {/* <a
                href="#"
                className="rounded-md bg-sky-500 px-8 py-4 text-base font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline hover:scale-105 ease-in-out duration-300 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-400"
              >
                Register for Early Access
              </a> */}

            {/* <div className=" mx-auto 
            w-fit border-2px
                          text-center mt-8
                          mb-6 rounded-md bg-zinc-100/10 px-5 py-3 text-base text-xl
                          text-white shadow-sm hover:bg-zinc-700 focus-visible:outline focus-visible:outline-2 
                          block font-medium cursor-pointer
                          focus-visible:outline-offset-2 focus-visible:outline-white
                          hover:scale-1025 border border-sky-900
                          transition-all " onClick={() => { 
                setOpen(true) 
                }}>
                <div className="relative  h-px w-full mx-auto  -top-3  shadow-2xl  bg-gradient-to-r from-transparent via-white/10 to-transparent" />

                Request Demonstration
                <span className='ml-2' aria-hidden="true">→</span>
                <div className="relative  h-px w-full mx-auto -bottom-3 shadow-2xl  bg-gradient-to-r from-transparent via-white/40 to-transparent" />
                
            </div> */}

            <div className=" mx-auto mt-8">
 
                <div className="m-8 mb-8 
                  xs:mt-0 sm:mt-0 md:mt-0  
                  xs:mb-4 sm:mb-4 md:mb-4 lg:mb-8
                  relative mt-6 p-0.5 inline-flex items-center justify-center cursor-pointer overflow-hidden group rounded-md"
                  onClick={() => {
                    setOpen(true)
                }}>
                    <span className="w-full h-full bg-gradient-to-br from-[#ff8a05] via-[#ff5478] to-[#ff00c6] group-hover:from-[#ff00c6] group-hover:via-[#ff5478] group-hover:to-[#ff8a05] absolute"></span>
                    <span className="w-full text-center relative px-6 py-3 transition-all ease-out bg-zinc-900 rounded-md group-hover:bg-opacity-0 duration-400">
                      <span className="relative text-white text-xl">Request Demonstration</span>
                    </span>
                  </div>




            </div>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-30 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 -mt-20 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden  rounded-lg modal-theme px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6">
                                    <div className="absolute right-4 -top-4 hidden  h-fit pr-4 pt-4 sm:block w-10">
                                        <button
                                            type="button"
                                            className="rounded-md   focus:outline-none  "
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close</span>
                                            <img src={'/media/icon/noun-close-fff.svg'} className=' opacity-50 hover:opacity-80 absolute hover:scale-105 ' />
                                        </button>
                                    </div>
                                    <div className="sm:flex sm:items-start">

                                        {/* <img className="h-14 w-auto" src="/media/icon/Brevit3-Logo-Home.png" alt="" /> */}
                                        <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            {/* <Dialog.Title as="h3" className="text-2xl font-semibold 
                                            leading-6 text-gray-100 mt-4 ml-4">
                                                Early Access Registration
                                            </Dialog.Title> */}
                                            <div className="md:m-12 m-0  h-[32rem]">

                                                <div className="grid grid-cols-4 sm:grid-cols-4 gap-4 mt-20">
                                                    <div className='text-zinc-300 md:col-span-2 col-span-4 text-lg md:pr-12 pr-0'>

                                                        <div className='text-2xl md:mb-14 mb-2 text-gray-50 -mt-12 md:mt-0 font-semibold'>
                                                            Demonstration Request
                                                        </div>

                                                        {/* <p className='mt-8'>Customer success is the very heart of our efforts to propel you and your message to the </p> */}
                                                        <div className='hidden md:block'>
                                                            <p className='mt-8'>We look forward to connecting and discussing how we can help you to wow your most important audiences. </p>
                                                            <p className='mt-8'>Sincerely,</p>
                                                            <p>The Brevit3 Team</p>
                                                        </div>
                                                    </div>

                                                    <div className='md:col-span-2 col-span-4'>
                                                        <HubspotForm
                                                            portalId='40148401'
                                                            formId='e8fefc4e-87da-45a9-b9e9-54e2bc49890c'
                                                            onSubmit={() => console.log('Submit!')}
                                                            onReady={(form) => console.log('Form ready!')}
                                                            loading={<div>Loading...</div>}
                                                        />
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default CompRegister