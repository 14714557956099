import React, { useRef, useState, useContext, Fragment, useEffect, Suspense, CSSProperties } from "react";
import { db } from '../firebase'
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  orderBy,
  doc,
  where,
  addDoc,
  deleteDoc, serverTimestamp
} from 'firebase/firestore'
//import SceneCard from "./SceneCard";
import { UserAuth } from '../context/AuthContext';
import { Dialog, Transition } from '@headlessui/react'
import { Link, Outlet, Navigate, useNavigate } from 'react-router-dom'
import StoryDetailEdit from "../components/StoryDetailEdit";

import { RadioGroup } from '@headlessui/react'
import BarLoader from "react-spinners/BarLoader";
import { LineChart, Line, Pie, PieChart, XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Insights = () => {
  //return (
  //    const { user, logOut } = UserAuth();
  const { user, logOut } = UserAuth();

  let [loading, setLoading] = useState(true)
  let [color, setColor] = useState("#0ca5e9")

  //const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)


  const [stories, setStories] = useState([])
  const [storyName, setStoryName] = useState('')
  const [storyDescription, setStoryDescription] = useState('')
  const [storiesLoaded, setStoriesLoaded] = useState(false)


  // const storyDataUpdate = async (storyData, storyId) => {

  //   let cloneStoryData = storyData
  //   //cloneStoryData = [...storyData]

  //   const sceneRef = doc(db, 'stories', storyId);
  //   await updateDoc(sceneRef, {
  //     //cloneStoryData,
  //     imageStoryBackground: '/media/icon/B3-Image-Placeholder.png',
  //     imageStoryLogo: '/media/icon/B3-Image-Placeholder.png',
  //     uiStyle: {
  //       'nav-button-bg-off': '#000000',
  //       'nav-button-bg-on': '#000000',
  //       'nav-button-text-off': '#000000',
  //       'nav-button-text-on': '#000000',
  //       'nav-progress-meter': '#000000',
  //       'nav-progress-meter-bg': '#000000',
  //       'nav-region-bg': '#000000',
  //       'super-highlight-color': '#000000',
  //       'super-list-icon-color': '#000000',
  //       'super-list-text-color': '#000000',
  //       'super-main-bg-color': '#000000',
  //       'super-main-title-color': '#000000',
  //       'super-secondary-bg-color': '#000000',
  //       'super-subtitle-color': '#000000',
  //     },
  //   })

  //   alert('Update competed')
  // }


  // const storyClone = async (storyData) => {

  //   let cloneStoryData = storyData

  //   storyData['parentId'] = storyData.id
  //   storyData['name'] = storyData.name + " (copy)"

  //   // Reset analytics
  //   storyData['analyticsViewTotal'] = 0

  //   const docRef = await addDoc(collection(db, "stories"), storyData)
  //   console.log(docRef.id)

  //   const q = query(collection(db, 'stories', cloneStoryData.id, 'scenes'), orderBy("order"))

  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     let sceneArr = []
  //     querySnapshot.forEach((doc) => {
  //       sceneArr.push({ ...doc.data() })
  //       const docRefScenes = addDoc(collection(db, 'stories', docRef.id, 'scenes'), {
  //         ...doc.data()
  //       })
  //       sceneArr = []
  //     })

  //     console.log('--- STORY ID & SCENE ARR ---')
  //     console.log(docRef.id)
  //     console.log(sceneArr)
  //   })


  //   // alert('Clone completed' + docRef.id)

  // }


  useEffect(() => {
    setTimeout(() => {
      if (user?.uid) {
        // console.log('user information')
        // console.log(user?.uid)

        const q = query(collection(db, 'stories'), orderBy('createdAt', 'desc'), where("role.owner", "array-contains", user?.uid));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let storiesArr = [];
          querySnapshot.forEach((doc) => {
            if (doc.data().analyticsViewTotal > 0) {
              storiesArr.push({ ...doc.data(), id: doc.id });
            }
          });
          setStories(storiesArr);
          setStoriesLoaded(true)
          //console.log(storiesArr);
        });
        return () => unsubscribe();
      }
    }, 250);

  }, [user]);

  // // Delete todo
  // const deleteStory = async (id) => {
  //   const storyRef = doc(db, 'stories', id);
  //   await deleteDoc(storyRef)
  // }

  //  const navigate = useNavigate()

  // const NavOpenStory = (storyId) => {
  //   if (storyId) {
  //     navigate(`/app/stories/${storyId}/edit/0`)
  //   }
  // }

  const override = {
    width: "50%",
    margin: "auto",
    marginTop: "20%",
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-50 rounded-md p-4">
          <p className="label font-semibold">{`${label}`}</p>
          <p className="desc text-gray-800 font-sm pt-1">{`${payload[0].value} total views`}</p>
        </div>
      );
    }

    return null;
  };

  const RenderData = () => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={stories}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
          barSize={20}
        >
          <CartesianGrid strokeDasharray="1 4" stroke="#666" />
          <XAxis dataKey="name" stroke="#ccc" padding={{
            left: 30,
            right: 30,
          }} />
          <YAxis stroke="#ccc" />
          <Tooltip fill="#111" content={<CustomTooltip />} />
          {/* <Legend /> */}
          <Bar dataKey="analyticsViewTotal" fill="#0ca5e9" background={{
            fill: '#18181b'
          }} />
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
    );
  }



  return (
    <div className="h-screen flex flex-col">

      <div className="md:flex md:items-center md:justify-between mb-8">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-normal leading-7 text-gray-200 sm:truncate sm:text-3xl sm:tracking-tight">
            Insights
          </h2>
        </div>
      </div>




      {/* {stories.length < 1 ? null : ( */}
      {/* <p className='pb-8 text-gray-400'>{`Viewing ${stories.length} stories`}</p> */}
      {/* )} */}

      <div className="overflow-y-auto admin-scroll mb-12">
        <div className="mx-auto py-4 pr-14">


          <div className="w-full">
            <BarLoader
              color={color}
              loading={!storiesLoaded}
              cssOverride={override}
              size={450}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>




          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 xl:grid-cols-2 2xl:grid-cols-3 xl:gap-x-8 xl:gap-y-12">

            {storiesLoaded ?
              <>

                {storiesLoaded ?
                  <>
                    <div className="col-span-2 h-96">
                      <RenderData />
                    </div>
                    <div className="col-span-1 h-96">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart  >
                          {/* <Pie data={stories} dataKey="analyticsViewTotal" cx="50%" cy="50%" outerRadius={60} fill="#8884d8" /> */}
                          <Pie data={stories} dataKey="analyticsViewTotal" cx="50%" cy="50%" innerRadius={50} outerRadius={100} fill="#0ca5e9" label={false} />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </>
                  : null}

                {stories.map((story, index) => (

                  <div key={index} className="relative pointer-events-auto flex flex-col rounded overflow-hidden">


                    <div className="grid grid-cols-2 gap-1 p-0 bg-zinc-900 rounded">
                      <div className="bg-story-preview ">
                        <img src={story.previewThumb //.replace('/upload', '/upload/c_crop,g_south,h_742,w_1320,y_20/')
                        }
                          className="w-full aspect-video rounded-tr rounded-tl" />
                      </div>


                      {/* <div className="text-base font-medium text-gray-300">{story.id}</div> */}
                      {/* <div className="text-4xl font-base text-gray-100">{story.analyticsViewTotal}</div> */}

                      <div className="grid grid-cols-3 gap-1 pt-2 ml-4">
                        <div className="text-lg col-span-3 font-medium text-gray-300 mt-4">{story.name}</div>

                        <div className="grid grid-cols-3 gap-1  -mt-4 ">
                          <div className="mt-2 col-span-2 items-baseline text-left w-96 text-white   text-2xl">
                            {story.analyticsViewTotal || 0}
                          </div>
                          <div className="ml-8 mt-2 text-sm font-medium leading-16 text-gray-400">
                            Total  Views
                          </div>
                        </div>


                      </div>

                    </div>
                  </div>

                ))}
              </>
              : null}


          </div>
        </div>
      </div>


    </div >

  )
}

export default Insights