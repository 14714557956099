import { schemeCategory10 } from "d3";

function generateConnections() {
  const connections = [];
  schemeCategory10.forEach((start) => {
    schemeCategory10.forEach((end) => {
      if (start !== end) {
        connections.push({
          start,
          end,
          id: `${start}-${end}`,
        });
      }
    });
  });
  return connections;
}

export const Gradients = () => {
  const colors = generateConnections();
  return (
    <defs>
      {colors.map(({ start, end, id }) => (
        <linearGradient id={id} key={id}>
          <stop offset="0%" stopColor={start} />
          <stop offset="100%" stopColor={end} />
        </linearGradient>
      ))}
    </defs>
  );
};
