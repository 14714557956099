import { React, useState } from 'react'
import '/Style.css'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import FormWaitlist from './FormWaitlist'
import CompRegister from './CompRegister'
import CompSiteNav from './CompSiteNav'
import ReactPlayer from 'react-player'

const navigation = {
  solutions: [
    { name: 'Home', href: '/' },
    //{ name: 'Pricing', href: '/#pricing' },
    { name: 'About', href: '/about' },
    { name: 'Contact Us', href: '/contact' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    //   { name: 'Blog', href: '#' },
    //   { name: 'Jobs', href: '#' },
    //   { name: 'Press', href: '#' },
    //   { name: 'Partners', href: '#' },
  ],
  legal: [
    //   { name: 'Claim', href: '#' },
    //   { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}

import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'



const PublicPromo = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [checked, setChecked] = useState(false);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
  

  return (
    <>

<CompSiteNav />



      <div className="relative isolate overflow-hidden b3-site-bg py-14 sm:py-32">
        <div
          className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
          aria-hidden="true"
        >
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto w-full">
            {/* <p className="text-lg font-semibold leading-8 tracking-tight text-sky-400 text-center md:text-left">
              Great Meeting You!
            </p> */}
            <h1 className="mt-2 text-2xl font-bold tracking-tight text-gray-200 sm:text-4xl">
              <img src="/media/demo/mfg/mfg-logo-salesforce-icon.png" className='w-24 inline mr-6'/> Dreamforce 2023
            </h1>
            <h1 className="mt-2 text-xl text-center font-base tracking-tight text-sky-400 sm:text-2xl mt-12">
              It was Great Meeting You - Let's Connect!
            </h1>            
            {/* <div><a href="mailto:john@brevit3.com">john@brevit3.com</a></div> */}

            <div className='mt-8'>
            <ReactPlayer url="/media/site/Brevit3-LinkedIn-Video.mp4"
            autoPlay={true}
                                                                    width='100%'
                                                                    muted={true}
                                                                    controls={true}
                                                                    //height='80%'
                                                                />

</div>

            <div className=' w-full my-6  mt-4'>
              <div  className='bg-black p-6 text-gray-300  mx-auto border border-gray-700 rounded-md'>
                <a  href="https://www.linkedin.com/in/johnbrunswick/"><img src="/media/site/icon-LinkedIn.png" className='inline w-8 mr-4 '/>  Connect on LinkedIn
                </a></div>
            </div>                

            <div className=' w-full'>
              <div  className='bg-black p-6 text-gray-300  mx-auto border border-gray-700 rounded-md'>
                <a  href="mailto:john@brevit3.com"><img src="/media/site/noun-email-6073188-FFFFFF.svg" className='inline w-8 mr-4 '/>  Email john@brevit3.com
                </a></div>
            </div>            




{/* 
            <div className='w-1/2 md:w-1/2 mt-8'>
            <CompRegister />
            </div> */}
      


          </div>
          
        </div>
      </div>




    </>

  )
}

export default PublicPromo